const LOCALE_TO_DEFAULT_TIMEZONE: Record<string, string | undefined> = {
  'en-us': 'America/Los_Angeles',
  'ja-jp': 'Asia/Tokyo',
};

const getDefaultTimeZoneByLocale = (locale: string): string => {
  const timeZone = LOCALE_TO_DEFAULT_TIMEZONE[locale.toLowerCase()];

  if (typeof timeZone !== 'string') {
    throw new Error('Provided a locale but no default timeZone for locale known.');
  }

  return timeZone;
};

interface UserObjectWithLocalSettings {
  localeSettings?: {
    timeZone?: {
      id?: string;
    };
  };
}

export const negotiateTimezone = (locale: string, user?: UserObjectWithLocalSettings): string => {
  // If the user is logged in and has a timeZone, use the timeZone they specified.
  if (user?.localeSettings?.timeZone?.id) {
    return user?.localeSettings?.timeZone?.id;
  }

  // TODO(sebastienne): Once we have GetServerSideProps in layouts, and once we store the user's actual time zone
  // in Sycamore, pass the user's set time zone to the user context initializer so we don't default the time zone when
  // we don't have a user on the first client-side render, since this could cause a server/client mismatch when the
  // client side rehydrates. For now, defaulting to the default time zone for a locale should be fine.
  return getDefaultTimeZoneByLocale(locale);
};
