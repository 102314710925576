import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const FacebookIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M10.188 0.25C7.77391 0.25 5.75 2.1216 5.75 4.50693V5.75446V6.25H4.5C4.08579 6.25 3.75 6.58579 3.75 7C3.75 7.41421 4.08579 7.75 4.5 7.75H5.75V15C5.75 15.4142 6.08579 15.75 6.5 15.75C6.91421 15.75 7.25 15.4142 7.25 15V7.75H11.5C11.9142 7.75 12.25 7.41421 12.25 7C12.25 6.58579 11.9142 6.25 11.5 6.25H7.25V5.75446V4.50693C7.25 3.02031 8.53027 1.75 10.188 1.75H11.5C11.9142 1.75 12.25 1.41421 12.25 1C12.25 0.585786 11.9142 0.25 11.5 0.25H10.188Z"
    />
  </IconBase>
);

export default FacebookIcon;
