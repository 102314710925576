import styled, { css } from 'styled-components';
import type { MouseEventHandler } from 'react';

import { pxToRem } from '@yoweb/ui/styles/utils';
import { getColor, getFontWeight, getRadii, getSpace } from '@yoweb/ui/styles/utils/theme';
import { ImageWithFallback } from '@yoweb/ui/components/ImageWithFallback';
import { Flex } from '@yoweb/ui/components/Flex';
import { Box } from '@yoweb/ui/components/Box';
import { Interactive, type InteractiveProps } from '@yoweb/ui/components/typography';
import { CaretRightIcon } from '@yoweb/ui/components/Icon/CaretRightIcon';
import { isDefined } from '@yoweb/utils/isDefined';

export type AvatarSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

const avatarSizeMap: Record<
  AvatarSize,
  {
    size: number;
    indicatorSize: number;
    indicatorPosition: number;
    indicatorDiff: number;
    labelSize?: InteractiveProps['size'];
    labelPadding: number;
    initialsSize: number;
  }
> = {
  xl: {
    size: 140,
    indicatorSize: 24,
    indicatorPosition: 8,
    indicatorDiff: 8,
    labelSize: 'lg',
    labelPadding: 16,
    initialsSize: 48,
  },
  lg: {
    size: 72,
    indicatorSize: 16,
    indicatorPosition: 4,
    indicatorDiff: 4,
    labelSize: 'md',
    labelPadding: 8,
    initialsSize: 32,
  },
  md: {
    size: 40,
    indicatorSize: 12,
    indicatorPosition: 0,
    indicatorDiff: 0,
    labelSize: 'sm',
    labelPadding: 6,
    initialsSize: 18,
  },
  sm: {
    size: 32,
    indicatorSize: 10,
    indicatorPosition: 0,
    indicatorDiff: 0,
    labelPadding: 0,
    initialsSize: 14,
  },
  xs: {
    size: 24,
    indicatorSize: 0,
    indicatorPosition: 0,
    indicatorDiff: 0,
    labelPadding: 0,
    initialsSize: 12,
  },
};

const largeAvatars = ['xl', 'lg', 'md'];

type AvatarProps = {
  image?: string;
  fallbackImage?: string;
  alt?: string;
  initials?: string;
  size: AvatarSize;
  label?: string;
  isOnline?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  href?: string;
  disabled?: boolean;
  priority?: boolean;
} & DataTestId;

export const AvatarContent = ({
  size = 'sm',
  initials,
  image,
  fallbackImage,
  alt,
  label,
  isOnline,
  disabled = true,
  href,
  priority = false,
  onClick,
}: AvatarProps) => {
  const iconConfig = avatarSizeMap[size];

  const isLargeLabel = largeAvatars.includes(size);
  const hasIndicator = isDefined(isOnline);
  const hasLabel = isLargeLabel && label;
  const isInteractive = hasLabel && (href || onClick);

  const getLabel = () => (
    <AvatarLabel mt={iconConfig.labelPadding} id="avatar-label">
      <Flex columnGap="small2" alignItems="center" justifyContent="center">
        {hasIndicator && (
          <Indicator
            size={iconConfig.indicatorSize}
            isOnline={isOnline}
            withLabel
            indicatorPosition={iconConfig.indicatorPosition - iconConfig.indicatorDiff}
          />
        )}
        <Interactive size={iconConfig.labelSize} variant={disabled ? 'muted2' : 'fg'}>
          {label}
        </Interactive>
        {isInteractive && (
          <Box pl="small2" display="flex" alignItems="center">
            <CaretRightIcon size={16} variant="muted2" />
          </Box>
        )}
      </Flex>
    </AvatarLabel>
  );

  return (
    <>
      <AvatarWrap size={iconConfig.size} id="avatar-wrap">
        {fallbackImage ? (
          <ImageWithFallback
            src={image}
            fallbackSrc={fallbackImage}
            objectFit="cover"
            width={iconConfig.size}
            height={iconConfig.size}
            unoptimized
            alt={alt}
            priority={priority}
          />
        ) : (
          <AvatarInitials size={iconConfig.initialsSize}>{initials}</AvatarInitials>
        )}
      </AvatarWrap>
      {!hasLabel && hasIndicator && (
        <Indicator
          size={iconConfig.indicatorSize}
          isOnline={isOnline}
          withLabel={false}
          indicatorPosition={iconConfig.indicatorPosition}
        />
      )}
      {hasLabel && getLabel()}
    </>
  );
};

export const Avatar = ({
  size = 'sm',
  initials,
  fallbackImage,
  label,
  'data-testid': dataTestId,
  ...rest
}: AvatarProps) => {
  // Needs one of image, fallback image or initials
  if (!(fallbackImage || initials)) {
    return null;
  }

  const hasLabel = largeAvatars.includes(size) && label;
  const isInteractive = hasLabel && (rest.href || rest.onClick);

  return isInteractive ? (
    <AvatarButton as={rest.href ? 'a' : 'button'} {...rest} data-testid={dataTestId}>
      <AvatarContent
        initials={initials}
        size={size}
        label={label}
        fallbackImage={fallbackImage}
        {...rest}
      />
    </AvatarButton>
  ) : (
    <AvatarContainer data-testid={dataTestId}>
      <AvatarContent
        initials={initials}
        size={size}
        label={label}
        fallbackImage={fallbackImage}
        {...rest}
      />
    </AvatarContainer>
  );
};

const AvatarButton = styled.a`
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &:focus #avatar-label {
    border: 3px solid ${getColor('base900')};
  }

  &:hover #avatar-label {
    background: ${getColor('base050')};
  }

  &:disabled #avatar-wrap {
    opacity: 0.5;
  }
`;

const AvatarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const AvatarWrap = styled.div<{ size: number }>`
  align-items: center;
  border-radius: ${getRadii('full')};
  background: ${getColor('base900')};
  color: ${getColor('base000')};
  display: flex;
  height: ${({ size }) => pxToRem(size)};
  justify-content: center;
  overflow: hidden;
  width: ${({ size }) => pxToRem(size)};
`;

const Indicator = styled.div<{
  size: number;
  isOnline?: boolean;
  withLabel: boolean;
  indicatorPosition: number;
}>`
  align-items: center;
  background: ${getColor('base000')};
  border-radius: ${getRadii('full')};
  display: flex;
  height: ${({ size }) => pxToRem(size)};
  justify-content: center;
  width: ${({ size }) => pxToRem(size)};
  position: absolute;
  bottom: ${({ indicatorPosition }) => pxToRem(indicatorPosition)};
  right: ${({ indicatorPosition }) => pxToRem(indicatorPosition)};

  &::before,
  &::after {
    border-radius: ${getRadii('full')};
    content: '';
    position: absolute;
    z-index: 1;
  }

  &::after {
    background: ${({ isOnline }) => getColor(isOnline ? 'success' : 'base000')};
    height: 35%;
    width: 35%;
  }

  &::before {
    background: ${({ isOnline }) => getColor(isOnline ? 'success' : 'base400')};
    height: 65%;
    width: 65%;
  }

  ${({ withLabel }) =>
    withLabel &&
    css`
      position: relative;
    `}
`;

const AvatarLabel = styled.span<{ mt: number }>`
  border: 3px solid transparent;
  border-radius: ${getRadii('large')};
  display: block;
  margin-top: ${({ mt }) => pxToRem(mt)};
  padding: 0 ${getSpace('small2')};
`;

const AvatarInitials = styled.p<{ size: number }>`
  font-size: ${({ size }) => pxToRem(size)};
  text-transform: uppercase;
  font-weight: ${getFontWeight('bold')};
`;
