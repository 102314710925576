export const QUERY_PARAM_REDIRECT_TO = 'redirect-to';
export const QUERY_PARAM_EMAIL = 'email';
export const QUERY_PARAM_ALLOW_SIGN_UP = 'allow-sign-up';
export const QUERY_PARAM_INCLUDE_SUBSCRIPTION_LINE_DATA = 'include-subscription-line-data';
export const QUERY_PARAM_INCLUDE_CHARGES = 'include-charges';
export const QUERY_PARAM_CUSTOMER_ID = 'customer-id';
export const QUERY_PARAM_LOCALE = 'locale';
export const QUERY_PARAM_TIMEZONE = 'timezone';
export const QUERY_PARAM_INCLUDE_PRODUCT_DATA = 'include-product-data';
export const QUERY_PARAM_FILTER_SUBSCRIPTION_STATUS = 'filter-subscription-status';
export const QUERY_PARAM_INCLUDE_INVOICE_NEXT_TOTAL = 'include-invoice-next-total';
export const QUERY_PARAM_PROMPT = 'prompt';
export const QUERY_DEBUG_MIXPANEL = 'debugMixpanel';
