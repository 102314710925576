import styled, { css } from 'styled-components';
import type { FontSizes } from 'styled-components';

import { th } from '@yoweb/ui/styles/utils/theme';
import { textBase } from './textBase';
import type { ColorType, TextBaseProps } from './textBase';
import { createSizeMixin } from '@yoweb/ui/styles/utils/mixins';
import type { WithSize, WithVariant } from '@yoweb/ui/styles/utils/mixins';

export type TitleSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type TitleProps = TextBaseProps &
  WithSize<TitleSize> &
  WithVariant<ColorType> & {
    as?: string;
  };

/** Map title size to theme key */
const sizeMapping: Record<TitleSize, keyof FontSizes> = {
  xs: 'titleXs',
  sm: 'titleSm',
  md: 'titleMd',
  lg: 'titleLg',
  xl: 'titleXl',
  xxl: 'titleXxl',
};

/**
 * font-size + line-height + letter-spacing mixin.
 * Control all with a single prop.
 */
const titleSizeMixin = createSizeMixin<TitleSize, TitleProps>(
  ({ size = 'lg' as TitleSize }, tagFn) =>
    tagFn`
      font-size: ${th.getFontSize(sizeMapping[size])};
      line-height: ${th.getLineHeight(sizeMapping[size])};
      letter-spacing: ${th.getLetterSpacing(sizeMapping[size])};
    `,
);

/**
 * Title component with multiple props to modify.
 */
const titleStyles = css<TitleProps>`
  font-weight: ${th.getFontWeight('bold')};
  ${titleSizeMixin};
  ${textBase};
`;

export const Title = styled.h3<TitleProps>`
  ${titleStyles};
`;

Title.defaultProps = {
  as: 'h2',
};

export { titleSizeMixin, titleStyles };
