import { Children, useEffect, useMemo } from 'react';
import {
  motion,
  animate,
  useMotionValue,
  type AnimationPlaybackControls,
  type Easing,
} from 'framer-motion';
import {
  useInView as useIntersectionObserver,
  type IntersectionOptions,
  type InViewHookResponse,
} from 'react-intersection-observer';
import type { ReactNode } from 'react';

const STAGGER_DELAY = 80;

const EASINGS: { [key: string]: Easing } = {
  ease33: [0.33, 0, 0.66, 1],
  easeOut: 'easeOut',
};

const DURATIONS = {
  d25: 0.25,
  d33: 0.33,
  d67: 0.67,
};

/** Configuration object to override default animations */
type AnimationConfig = {
  y?: {
    animate?: boolean;
    offset?: number;
    duration?: number;
    ease?: Easing;
  };
  opacity?: {
    animate?: boolean;
    duration?: number;
    ease?: Easing;
  };
  scale?: {
    animate?: boolean;
    scale?: number;
    duration?: number;
    ease?: Easing;
  };
};

type Nodes = ReactNode | ReactNode[];
type AnimateInProps = {
  isVisible: boolean;
  delay?: number;
  children: Nodes;
  config?: AnimationConfig;
};

const defaultAnimationConfig = {
  y: {
    animate: true,
    offset: 50,
    duration: DURATIONS.d67,
    ease: 'easeOut' as Easing,
  },
  opacity: {
    animate: true,
    duration: DURATIONS.d25,
    ease: 'easeOut' as Easing,
  },
  scale: {
    animate: true,
    scale: 1.2,
    duration: DURATIONS.d25,
    ease: 'easeOut' as Easing,
  },
};

const AnimateIn = ({
  children,
  isVisible,
  delay = 0,
  config = defaultAnimationConfig,
}: AnimateInProps) => {
  // Override the default config with provided config so we always have configurations for everything
  const animationConfig = useMemo(
    () => ({
      ...defaultAnimationConfig,
      ...config,
      y: {
        ...defaultAnimationConfig.y,
        ...config.y,
      },
      opacity: {
        ...defaultAnimationConfig.opacity,
        ...config.opacity,
      },
      scale: {
        ...defaultAnimationConfig.scale,
        ...config.scale,
      },
    }),
    [config],
  );

  const opacity = useMotionValue(0);
  const y = useMotionValue(animationConfig.y.offset);

  useEffect(() => {
    let opacityAnimation: null | AnimationPlaybackControls = null;
    let yAnimation: null | AnimationPlaybackControls = null;

    if (animationConfig.opacity.animate) {
      opacityAnimation = animate(opacity, isVisible ? 1 : 0, {
        delay: delay / 1000,
        ease: animationConfig.opacity.ease,
        duration: animationConfig.opacity.duration,
      });
    }

    if (animationConfig.y.animate) {
      yAnimation = animate(y, isVisible ? 0 : animationConfig.y.offset, {
        delay: delay / 1000,
        ease: animationConfig.y.ease,
        duration: animationConfig.y.duration,
      });
    }

    return () => {
      opacityAnimation?.stop();
      yAnimation?.stop();
    };
  }, [delay, opacity, y, isVisible, animationConfig]);

  return (
    <motion.div
      style={{
        opacity: config.opacity ? opacity : 1,
        y: config.y ? y : 0,
      }}
    >
      {children}
    </motion.div>
  );
};

const ScaleIn = ({
  children,
  isVisible,
  delay = 0,
  config = defaultAnimationConfig,
}: AnimateInProps) => {
  const animationConfig = useMemo(
    () => ({
      ...defaultAnimationConfig,
      ...config,
      y: {
        ...defaultAnimationConfig.y,
        ...config.y,
      },
      opacity: {
        ...defaultAnimationConfig.opacity,
        ...config.opacity,
      },
      scale: {
        ...defaultAnimationConfig.scale,
        ...config.scale,
      },
    }),
    [config],
  );

  const opacity = useMotionValue(0);
  const scale = useMotionValue(animationConfig.scale.scale);

  useEffect(() => {
    let opacityAnimation: null | AnimationPlaybackControls = null;
    let scaleAnimation: null | AnimationPlaybackControls = null;

    if (animationConfig.opacity.animate) {
      opacityAnimation = animate(opacity, isVisible ? 1 : 0, {
        delay: delay / 1000,
        ease: animationConfig.opacity.ease,
        duration: animationConfig.opacity.duration,
      });
    }

    if (animationConfig.scale.animate) {
      scaleAnimation = animate(scale, isVisible ? 1 : animationConfig.scale.scale, {
        delay: delay / 1000,
        ease: animationConfig.scale.ease,
        duration: animationConfig.scale.duration,
      });
    }

    return () => {
      opacityAnimation?.stop();
      scaleAnimation?.stop();
    };
  }, [delay, opacity, isVisible, animationConfig, scale]);

  return (
    <motion.div
      style={{
        opacity: config.opacity ? opacity : 1,
        scale: config.scale ? scale : 1,
      }}
    >
      {children}
    </motion.div>
  );
};

const defaultInViewOptions: IntersectionOptions = {
  triggerOnce: true,
  rootMargin: '-200px 0px',
};

const useInView = (options: IntersectionOptions = {}): InViewHookResponse => {
  const combinedOptions = { ...defaultInViewOptions, ...options };
  return useIntersectionObserver({
    triggerOnce: combinedOptions.triggerOnce,
    rootMargin: combinedOptions.rootMargin,
  });
};

const InView = ({
  options,
  children,
}: {
  options?: IntersectionOptions;
  children(isInView: boolean): Nodes;
}) => {
  const [ref, isInView] = useInView(options);

  return <div ref={ref}>{children(isInView)}</div>;
};

type StaggerElementsProps = {
  children: Nodes;
  isInView: boolean;
  initialDelay?: number;
  options?: IntersectionOptions;
  yOffset?: number;
  config?: AnimationConfig;
};

const StaggerElements = ({
  children,
  isInView,
  initialDelay = 0,
  config = defaultAnimationConfig,
}: StaggerElementsProps) => (
  <>
    {Children.map(children, (child, index) =>
      child ? (
        <AnimateIn
          isVisible={isInView}
          delay={initialDelay + index * STAGGER_DELAY}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          config={config}
        >
          {child}
        </AnimateIn>
      ) : null,
    )}
  </>
);

const AnimateWhenInView = ({
  children,
  initialDelay,
  options,
  config = defaultAnimationConfig,
}: Omit<StaggerElementsProps, 'isInView'>) => (
  <InView options={options}>
    {(isInView) => (
      <StaggerElements isInView={isInView} initialDelay={initialDelay} config={config}>
        {children}
      </StaggerElements>
    )}
  </InView>
);

export default AnimateIn;

export {
  AnimateWhenInView,
  InView,
  useInView,
  StaggerElements,
  STAGGER_DELAY,
  ScaleIn,
  DURATIONS,
  EASINGS,
};
