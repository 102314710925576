import type { ReactNode, RefObject } from 'react';
import styled, { useTheme } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import { media, pxToRem } from '@yoweb/ui/styles/utils';
import {
  getColor,
  getSpace,
  getRadii,
  getSize,
  getZIndex,
  getShadow,
} from '@yoweb/ui/styles/utils/theme';
import { useScrollReveal } from '@yoweb/ui/hooks/useScrollReveal';
import { DURATIONS, EASINGS } from '@yoweb/ui/components/AnimateIn';

export const EMPTY_SPACE_HEIGHT_DESKTOP = 120;
export const EMPTY_SPACE_HEIGHT_MOBILE = 96;

const headerVariants = {
  initial: {
    opacity: 0,
    y: -50,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const headerTransitions = {
  opacity: {
    duration: DURATIONS.d25,
    ease: EASINGS.ease33,
  },
  y: {
    duration: DURATIONS.d67,
    ease: EASINGS.easeOut,
  },
};

type HeaderProps = {
  isBannerVisible?: boolean;
} & StaticHeaderProps;

export const Header = ({ isBannerVisible, children, ...rest }: HeaderProps) => {
  const theme = useTheme();
  const headerRef = useScrollReveal(theme.transitionDuration.interaction, isBannerVisible);

  return (
    <StaticHeader innerRef={headerRef} {...rest}>
      {children}
    </StaticHeader>
  );
};

type StaticHeaderProps = {
  id: string;
  showEmptySpace?: boolean;
  animateIn?: boolean;
  children?: ReactNode;
  innerRef?: RefObject<HTMLDivElement> | null;
} & DataTestId;

export const StaticHeader = ({
  id,
  innerRef,
  showEmptySpace,
  animateIn,
  children,
  ...rest
}: StaticHeaderProps) => (
  <>
    {showEmptySpace && <EmptySpace />}
    <AnimatePresence initial={animateIn}>
      <Wrapper
        ref={innerRef}
        id={id}
        variants={headerVariants}
        transition={headerTransitions}
        initial="initial"
        animate="visible"
        exit="initial"
        {...rest}
      >
        <Content>{children}</Content>
      </Wrapper>
    </AnimatePresence>
  </>
);

const EmptySpace = styled.div`
  box-sizing: content-box;
  height: ${pxToRem(EMPTY_SPACE_HEIGHT_MOBILE)};

  ${media.lg`
    height: ${pxToRem(EMPTY_SPACE_HEIGHT_DESKTOP)}
  `}
`;

const Wrapper = styled(motion.div)`
  position: absolute;
  left: ${getSpace('small3')};
  top: 0;
  right: ${getSpace('small3')};
  padding-top: ${getSpace('normal1')};
  z-index: ${getZIndex('header')};
  ${media.lg`
    padding-top: ${getSpace('normal2')};
  `}
`;

const Content = styled.header`
  background-color: ${getColor('base000')};
  border-radius: ${getRadii('large')};
  max-width: calc(${getSize('containerWidth')} + 2 * ${getSpace('normal2')});
  margin: 0 auto;
  padding: ${getSpace('normal1')} ${getSpace('normal1')};
  box-shadow: ${getShadow('secondary')};

  ${media.lg`
    padding: ${getSpace('normal1')} ${getSpace('normal2')};
  `}
`;

export default Header;
