import i18next from 'i18next';
import type { InitOptions } from 'i18next';

import type { I18nextInitOptions, CreateClient } from '../types';

const BrowserClient = (i18nextConfig: I18nextInitOptions, localePath?: string): CreateClient => {
  let options = { ...i18nextConfig };

  if (localePath) {
    options = {
      ...options,
      backend: {
        loadPath: `${localePath}{{lng}}/{{ns}}.json`,
        addPath: `${localePath}{{lng}}/{{ns}}.missing.json`,
      },
    };
  }

  const i18n = i18next.createInstance(options as InitOptions);

  if (options.use) {
    options.use.forEach((plugin) => i18n.use(plugin));
  }

  const initializationPromise = i18n.init(options as InitOptions);

  return {
    i18n,
    initializationPromise,
  };
};

export default BrowserClient;
