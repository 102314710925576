import { type RefObject, useEffect } from 'react';

type ListenerEvent = MouseEvent & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any;
};

/**
 * Hook that fires a callback function when clicked outside provided ref (element).
 * This hook is similar to useOnClickAway hook with one exception. It does not fire a callback if event target is inside current ref (element).
 * @param ref
 * @param callback
 */
export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: (event: MouseEvent) => void,
): void => {
  /**
   * Add and remove event listeners
   */
  useEffect(() => {
    const listener = (event: ListenerEvent) => {
      if (
        (event.target.shadowRoot && !event.target.shadowRoot.contains(ref.current)) ||
        !ref.current?.contains(event.target)
      ) {
        callback(event);
      }
    };

    // See: https://github.com/facebook/react/issues/24657#issuecomment-1150119055
    // Without the deferred scheduling of the listeners the handlers ends up firing too early as of React 18
    const registerGlobalHandlersTimer = setTimeout(() => {
      document.addEventListener('mousedown', listener);
      document.addEventListener('click', listener);
    }, 0);

    return () => {
      clearTimeout(registerGlobalHandlersTimer);
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('click', listener);
    };
  }, [ref, callback]);
};

export default useClickOutside;
