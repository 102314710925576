import styled from 'styled-components';
import type { SVGAttributes, PropsWithChildren } from 'react';

import { colorMapping, type ColorType, type TextColorProps } from '@yoweb/ui/components/typography';
import { createVariantMixin, type WithVariant } from '@yoweb/ui/styles/utils/mixins';
import { th } from '@yoweb/ui/styles/utils/theme';

type IconProps = Omit<Partial<SVGAttributes<SVGElement>>, 'color'> &
  WithVariant<ColorType> & {
    /**  Custom size of the icon, by default is set to current font-size. */
    size?: number | string;
  };

type IconBaseProps = IconProps & {
  /** Custom viewbox size. */
  viewBox?: string;
};

/**
 * Base icon component. When using this you just need to define the path to create a new icon.
 */
const IconBase = ({
  size = '1em',
  viewBox = '0 0 24 24',
  children,
  ...props
}: PropsWithChildren<IconBaseProps>) => (
  <SVGVariant width={size} height={size} viewBox={viewBox} {...props}>
    {children}
  </SVGVariant>
);

/**
 * Icon variants color mixin
 */
const variantsColorMixin = createVariantMixin<ColorType, IconProps>(
  ({ variant = 'inherit' }, tagFn) =>
    tagFn`
      color: ${th.getColor(colorMapping(variant))};
    `,
);

const SVGVariant = styled.svg<TextColorProps>`
  ${variantsColorMixin};

  display: inline-flex;
  align-self: center;
  position: relative;
  overflow: visible;
`;

export type { IconProps };

export default IconBase;
