import { createContext, type ReactNode, useEffect, useState } from 'react';
import styled, { type StyledComponent, type DefaultTheme, css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import type { Transition, Variants } from 'framer-motion';

import { TopMiddleToastContainer, BottomRightToastContainer } from './ToastContainers';
import { Text } from '@yoweb/ui/components/typography';
import { useImmutableArray } from '@yoweb/utils/hooks/useImmutableArray';
import { useTranslation } from '@yoweb/next-i18next';
import { theme } from '@yoweb/ui/styles';
import {
  getColor,
  getLetterSpacing,
  getLineHeight,
  getRadii,
  getShadow,
  getSpace,
} from '@yoweb/ui/styles/utils/theme';
import { Box } from '@yoweb/ui/components/Box';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { LineClamp } from '@yoweb/ui/components/LineClamp';
import { Button } from '@yoweb/ui/components/buttons';
import { CloseIconToast } from '@yoweb/ui/components/Icon';
import { Flex } from '@yoweb/ui/components/Flex';

type ToastPosition = 'top-middle' | 'bottom-right';

type ToastMessageType = string | ReactNode;

type ToastProps = {
  created: number;
  id?: string;
  position: ToastPosition;
  timeout?: number;
  delay?: number;
  icon?: ReactNode;
  onClick?(): void;
  onClose?(): void;
  unique?: boolean;
  ctaText?: string;
};

type Toast = ToastProps &
  (
    | { type: 'message' | 'success'; message: ToastMessageType }
    | { type: 'error'; message?: ToastMessageType }
    | { type: 'action'; message?: ToastMessageType; onClick?: () => void }
  );

type ToastContextProps = {
  defaultPosition?: ToastPosition;
} & WithChildren;

type ToastMessageProps = Toast & {
  onTimeout(): void;
  onClose?(): void;
};

type VariantMap = {
  [variantType in ToastPosition]: {
    Wrapper: StyledComponent<any, DefaultTheme>;
    variants: Variants;
    transition: Transition;
  };
};

const TOAST_TIMEOUT_MS = 4500;
const ACTION_TOAST_TIMEOUT_MS = 10000;
const TOAST_EXIT_DISTANCE = -500;

const variantMap: VariantMap = {
  'top-middle': {
    Wrapper: TopMiddleToastContainer,
    variants: {
      enter: { y: 0 },
      exit: { y: TOAST_EXIT_DISTANCE },
    },
    transition: {
      duration: theme.transitionDuration.shorter / 1000,
      ease: theme.transitionPoints.easeInOut,
    },
  },
  'bottom-right': {
    Wrapper: BottomRightToastContainer,
    variants: {
      initial: { y: 0, x: -TOAST_EXIT_DISTANCE },
      enter: { y: 0, x: 0 },
      exit: { y: 0, x: -TOAST_EXIT_DISTANCE },
    },
    transition: {
      duration: theme.transitionDuration.shorter / 1000,
      ease: theme.transitionPoints.easeInOut,
    },
  },
};

export type ToastState = {
  /* shows a toast message that disappears after the timeout (in milliseconds). */
  showToastMessage(toast: {
    id?: string;
    message: string;
    timeout?: number;
    position?: ToastPosition;
    unique?: boolean;
  }): void;
  /* shows a generic error message in the toast that doesn't timeout. */
  showToastError(toast?: {
    id?: string;
    timeout?: number;
    position?: ToastPosition;
    unique?: boolean;
  }): void;
  /* shows a generic action message in the toast. */
  showToastAction(toast: {
    id?: string;
    message: ToastMessageType;
    onClick?: () => void;
    onClose?: () => void;
    timeout?: number;
    position?: ToastPosition;
    unique?: boolean;
    ctaText: string;
  }): void;
};

export const ToastContext = createContext<ToastState>({
  showToastMessage: () => {},
  showToastError: () => {},
  showToastAction: () => {},
});

/**
 * Context provider for showing a center aligned toast message at
 * the top of the viewport.
 */
export const ToastContextProvider = ({
  defaultPosition = 'top-middle',
  children,
}: ToastContextProps) => {
  const { list: toasts, addItem, removeItem } = useImmutableArray<Toast>([], 'created');

  const addToast = (toast: Toast) => {
    // Check if toast is unique
    if (toast.unique && toast.id) {
      const isDuplicate = toasts.find((curr) => curr.id === toast.id);

      if (!isDuplicate) {
        addItem(toast);
      }
    } else {
      addItem(toast);
    }
  };

  const showToastMessage: ToastState['showToastMessage'] = ({
    id,
    message,
    timeout = TOAST_TIMEOUT_MS,
    position = defaultPosition,
    unique,
  }) => {
    addToast({ id, created: Date.now(), message, type: 'message', position, timeout, unique });
  };

  const showToastError: ToastState['showToastError'] = ({
    timeout = TOAST_TIMEOUT_MS,
    position = defaultPosition,
  } = {}) => {
    addToast({ created: Date.now(), type: 'error', position, timeout });
  };

  const showToastAction: ToastState['showToastAction'] = ({
    id,
    message,
    onClick = () => {},
    onClose = () => {},
    timeout = ACTION_TOAST_TIMEOUT_MS,
    position = defaultPosition,
    unique,
    ctaText,
  }) => {
    addToast({
      id,
      created: Date.now(),
      message,
      position,
      type: 'action',
      timeout,
      onClick,
      onClose,
      unique,
      ctaText,
    });
  };

  return (
    <ToastContext.Provider value={{ showToastMessage, showToastError, showToastAction }}>
      {Object.entries(variantMap).map(([variantType, { Wrapper }]) => (
        <Wrapper key={variantType}>
          <AnimatePresence>
            {toasts.map(
              (toast) =>
                toast.position === variantType && (
                  <ToastMessage
                    key={toast.created}
                    {...toast}
                    onTimeout={() => removeItem(toast)}
                    onClose={() => removeItem(toast)}
                  />
                ),
            )}
          </AnimatePresence>
        </Wrapper>
      ))}
      {children}
    </ToastContext.Provider>
  );
};

const ToastMessage = ({
  type,
  message,
  timeout,
  onTimeout,
  created,
  position,
  onClick,
  onClose,
  ctaText,
}: ToastMessageProps) => {
  const { t } = useTranslation<'common'>();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (timeout) {
      timer = setTimeout(() => {
        if (isHovered) {
          return;
        }
        onTimeout();
        onClose?.();
      }, timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [onTimeout, onClose, timeout, isHovered]);

  const isError = type === 'error';

  const handleCtaClick = () => {
    onClick?.();
    onTimeout();
  };

  return (
    <Wrap
      key={created}
      initial="initial"
      exit="exit"
      animate="enter"
      variants={variantMap[position].variants}
      transition={variantMap[position].transition}
      position={position}
      layout
      data-testid="toast"
      role="presentation"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex alignItems="flex-start" gap="normal1">
        <Box display="flex" gap="small4" alignItems={'center'}>
          {isError ? (
            <div data-testid="toast-error">
              <Text>{t('error-retry-title')}</Text>
              <Text>{t('error-retry-body')}</Text>
            </div>
          ) : (
            <Message data-testid="toast-message">
              <LineClamp lines={3}>{message}</LineClamp>
            </Message>
          )}
        </Box>
        <CloseButtonWrapper onClick={() => onClose?.()}>
          <CloseIconToast data-testid="toast-close-button" size={16} />
        </CloseButtonWrapper>
      </Flex>
      {type === 'action' && (
        <Box mt="normal1">
          <WhiteBgButton
            isFullWidth
            variant="secondary"
            onClick={handleCtaClick}
            data-testid="toast-cta"
          >
            {ctaText}
          </WhiteBgButton>
        </Box>
      )}
    </Wrap>
  );
};

const Wrap = styled(motion.div)<{ position: ToastPosition }>`
  background: ${getColor('charcoal')};
  border-radius: ${getRadii('small')};
  box-shadow: ${getShadow('secondary')};
  display: inline-block;
  margin: ${getSpace('small3')} auto;
  padding: ${getSpace('normal2')};
  pointer-events: auto;
  white-space: pre-wrap;
  color: ${getColor('base000')};
  max-width: ${pxToRem(309)};

  ${({ position }) =>
    position === 'bottom-right' &&
    css`
      margin: 0 ${getSpace('normal2')} ${getSpace('normal1')} ${getSpace('normal2')};

      ${media.md`
        margin: 0 ${getSpace('normal2')} ${getSpace('normal4')} 0;
      `}
    `}
`;

const CloseButtonWrapper = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
`;

const WhiteBgButton = styled(Button)`
  && {
    background: ${getColor('base000')};
  }
`;

const Message = styled.div<{ alignText?: boolean }>`
  flex: 1;
  line-height: ${getLineHeight('bodyLg')};
  letter-spacing: ${getLetterSpacing('bodyLg')};

  ${({ alignText }) =>
    alignText &&
    css`
      padding-top: ${getSpace('small1')};
    `}
`;

export default ToastContext;
