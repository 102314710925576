import styled, { type Space } from 'styled-components';

import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { pxToRem } from '@yoweb/ui/styles/utils';
import { isNumber } from '@yoweb/utils/isNumber';
import { createMixin, type CreateMixinType } from '@yoweb/ui/styles/utils/mixins';

type SizeValue = keyof Space | number;
type SizeKeysProps = CreateMixinType<'size', SizeValue>;

const sizeMixin = createMixin('size')<SizeValue, SizeKeysProps>(({ size = 'normal2' }, css) =>
  size
    ? css`
        height: ${isNumber(size) ? pxToRem(size) : getSpace(size)};
      `
    : css``,
);

/**
 * A general purposes vertical space component.
 */
export const Spacer = styled.div<SizeKeysProps>`
  ${sizeMixin}
`;

export default Spacer;
