import type { DefaultTheme } from 'styled-components';

import { pxToRem } from '@yoweb/ui/styles/utils';

/**
 * Theme definition.
 * Aim to follow the system-ui spec for consistency.
 * https://system-ui.com/theme/
 */
const theme: DefaultTheme = {
  colors: {
    // Base.
    inherit: 'inherit',
    transparent: 'transparent',
    base000: '#ffffff',
    base050: '#F5F5F5',
    base100: '#EBEBEB',
    base200: '#C4C4C4',
    base400: '#949494',
    base500: '#767676',
    base700: '#595959',
    base800: '#5e5e5e',
    base900: '#1a1a1a',
    base1000: '#000000',
    baseA700: 'rgba(0,0,0, .75)',
    baseA400: 'rgba(0,0,0, .42)',
    baseA300: 'rgba(0,0,0, .25)',
    baseA200: 'rgba(0,0,0, .15)',
    baseA050: 'rgba(0,0,0, 0.04)',

    // Contextual.
    critical: '#AD3737',
    criticalLight: '#FFE5E2',
    warning: '#BF5728',
    warningLight: '#FFECC8',
    success: '#5B7839',
    successLight: '#E2EFD0',
    info: '#265576',
    infoLight: '#D1E8E6',

    // Brand colors.
    morning: '#FAC1BD',
    coral: '#F69469',
    sprout: '#CDE3AA',
    olive: '#6E7839',
    sky: '#85C6C0',
    skyLight: '#D1E8E6',
    navy: '#265576',
    evening: '#B6BFE6',
    eveningLight: '#D8DDF0',
    berry: '#715485',
    beach: '#FFD991',
    gingko: '#F8D472',
    gingkoLight: '#FDEBB9',
    citrus: '#EBB317',

    // Poplar
    charcoal: '#2A2A2A',
    frost: '#AFCBC9',
    frostLight: '#D4E2E1',
    lilac: '#BCB3C1',
    lilacLight: '#D8D2DC',
    sage: '#B2B9A6',
    sageLight: '#D4DACA',
    stone: '#5F5E66',
    stoneLight: '#919097',
    sand: '#F2F0EB',
    sandLight: '#F8F6F0',
    sandMedium: '#E7E5E0',
    sandDark: '#C6C4C3',
    wheat: '#F3E1B3',
  },
  space: {
    none: pxToRem(0),
    small1: pxToRem(2),
    small2: pxToRem(4),
    small3: pxToRem(8),
    small4: pxToRem(12),
    normal1: pxToRem(16),
    normal2: pxToRem(24),
    normal3: pxToRem(32),
    normal4: pxToRem(40),
    medium1: pxToRem(48),
    medium2: pxToRem(56),
    medium3: pxToRem(64),
    large1: pxToRem(72),
    large2: pxToRem(80),
    large3: pxToRem(96),
    xl1: pxToRem(112),
    xl2: pxToRem(128),
    xl3: pxToRem(160),
    xl4: pxToRem(192),
  },
  zIndices: {
    shadow: -2,
    behind: -1,
    layer: 1,
    above: 2,
    chat: 5,
    banner: 7,
    header: 8,
    popover: 20,
    modal: 2147483646,
    toast: 2147483647,
  },
  radii: {
    none: pxToRem(0),
    tiny: pxToRem(4),
    small: pxToRem(8),
    medium: pxToRem(16),
    large: pxToRem(24),
    full: pxToRem(9999),
  },
  sizes: {
    containerWidth: pxToRem(1224),
    containerWidthTablet: pxToRem(648),
    containerWidthNarrow: pxToRem(696),
    cardWidth: pxToRem(377),
    gridGap: pxToRem(24),
    flyoutWidth: pxToRem(563),
    sideNavWidth: pxToRem(268),
    sideNavFlyoutWidth: pxToRem(300),
  },
  fontSizes: {
    labelXs: pxToRem(10),
    labelSm: pxToRem(12),
    labelMd: pxToRem(14),
    bodyXs: pxToRem(10),
    bodySm: pxToRem(12),
    bodyMd: pxToRem(14),
    bodyLg: pxToRem(16),
    bodyXl: pxToRem(18),
    titleXs: pxToRem(18),
    titleSm: pxToRem(24),
    titleMd: pxToRem(32),
    titleLg: pxToRem(40),
    titleXl: pxToRem(48),
    titleXxl: pxToRem(64),
    interactiveSm: pxToRem(12),
    interactiveMd: pxToRem(14),
    interactiveLg: pxToRem(16),
    interactiveXl: pxToRem(18),
    marketingInteractiveSm: pxToRem(12),
    marketingInteractiveMd: pxToRem(14),
    marketingInteractiveLg: pxToRem(16),
    marketingInteractiveXl: pxToRem(18),
    marketingQuoteLg: pxToRem(18),
    marketingQuoteXl: pxToRem(24),
  },
  lineHeights: {
    normal: 'normal',
    labelXs: 1,
    labelSm: 1,
    labelMd: 1,
    bodyXs: 1.5,
    bodySm: 1.5,
    bodyMd: 1.5,
    bodyLg: 1.5,
    bodyXl: 1.5,
    interactiveSm: 1.5,
    interactiveMd: 1.5,
    interactiveLg: 1.5,
    interactiveXl: 1.5,
    titleXs: 1.2,
    titleSm: 1.2,
    titleMd: 1.2,
    titleLg: 1.2,
    titleXl: 1.2,
    titleXxl: 1.2,
    marketingInteractiveXl: 24 / 18,
    marketingInteractiveLg: 22 / 16,
    marketingInteractiveMd: 18 / 14,
    marketingInteractiveSm: 16 / 12,
    marketingQuoteLg: 24 / 18,
    marketingQuoteXl: 28 / 24,
  },
  letterSpacing: {
    labelXs: '0.1em',
    labelSm: '0.1em',
    labelMd: '0.2em',
    bodyXs: '0.02em',
    bodySm: '0.02em',
    bodyMd: '0.02em',
    bodyLg: '0.02em',
    bodyXl: '0.02em',
    titleXs: 'default',
    titleSm: 'default',
    titleMd: 'default',
    titleLg: 'default',
    titleXl: 'default',
    titleXxl: 'default',
    interactiveSm: 'default',
    interactiveMd: 'default',
    interactiveLg: 'default',
    interactiveXl: 'default',
    marketingInteractiveSm: 'default',
    marketingInteractiveMd: 'default',
    marketingInteractiveLg: 'default',
    marketingInteractiveXl: 'default',
    marketingQuoteLg: '0.01em',
    marketingQuoteXl: '0.01em',
  },
  fontWeights: {
    regular: 400,
    semibold: 600,
    bold: 700,
  },
  fontFamily: {
    primary: `'GT Walsheim', sans-serif`,
  },
  shadows: {
    secondary: `1px 1px 20px 1px rgba(0, 0, 0, 0.1)`,
    tertiary: '1px 1px 6px 1px rgba(0,0,0,0.25)',
  },
  transitionDuration: {
    instant: 0,
    fast: 100,
    shorter: 250,
    interaction: 300,
    short: 450,
    visual: 500,
  },
  transitionPoints: {
    easeInOut: [0.42, 0, 0.58, 1],
  },
  iconSizes: {
    display: pxToRem(48),
    compact: pxToRem(16),
    default: pxToRem(24),
  },
};

const themeLocaleOverrides: Record<'en-us' | 'ja-jp', Partial<DefaultTheme>> = {
  ['en-us']: {}, // Default, no overrides
  ['ja-jp']: {
    lineHeights: {
      normal: 'normal',
      labelXs: 14 / 10,
      labelSm: 16 / 12,
      labelMd: 16 / 14,
      bodyXs: 16 / 10,
      bodySm: 18 / 12,
      bodyMd: 24 / 14,
      bodyLg: 26 / 16,
      bodyXl: 30 / 18,
      titleXs: 24 / 18,
      titleSm: 32 / 24,
      titleMd: 40 / 30,
      titleLg: 48 / 40,
      titleXl: 56 / 48,
      titleXxl: 64 / 56,
      interactiveSm: 18 / 12,
      interactiveMd: 20 / 14,
      interactiveLg: 24 / 16,
      interactiveXl: 26 / 18,
      marketingInteractiveXl: 24 / 18,
      marketingInteractiveLg: 22 / 16,
      marketingInteractiveMd: 18 / 14,
      marketingInteractiveSm: 16 / 12,
      marketingQuoteLg: 24 / 18,
      marketingQuoteXl: 28 / 24,
    },
    fontFamily: {
      primary: `'GT Walsheim', 'Noto Sans JP', sans-serif`,
    },
    fontWeights: {
      regular: 400,
      semibold: 500,
      bold: 700,
    },
  },
};

export { themeLocaleOverrides };

export default theme;
