import { css } from 'styled-components';
import BaseReset from 'styled-reset';

export default css`
  ${BaseReset}

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;
