import {
  css,
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemedCssFunction,
  type ThemeProps,
} from 'styled-components';

import { isString } from '@yoweb/utils/isString';
import { media, type ScreenSize } from '.';
import { isDefined } from '@yoweb/utils/isDefined';
import { isNumber } from '@yoweb/utils/isNumber';
import { isObjectLike } from '@yoweb/utils/isObjectLike';

type Theme = {
  theme: DefaultTheme;
};

type ComponentProps<PropertyName extends string, PropertyValue, Props> = Props &
  Theme & {
    [Property in PropertyName]?: PropertyValue;
  };

type FlattenedCss = FlattenInterpolation<ThemeProps<DefaultTheme>>;

type ResponsiveGetter<PropertyName extends string, PropertyValue, Props> = (
  props: ComponentProps<PropertyName, PropertyValue, Props>,
  tagFn: ThemedCssFunction<DefaultTheme>,
) => FlattenedCss;

/**
 * Extend object with any property name key
 * @example CreateMixinType<'size', SizeType>
 */
export type CreateMixinType<PropertyName extends string, PropertyValue> = {
  [Property in PropertyName]?: PropertyValue | Partial<Record<ScreenSize, PropertyValue>>;
};

export type WithSize<Size extends string> = CreateMixinType<'size', Size>;
export type WithVariant<Variant extends string> = CreateMixinType<'variant', Variant>;

type CreateMixin<PropertyName extends string, PropertyValue, Props> = (
  props: Props & CreateMixinType<PropertyName, PropertyValue>,
) => FlattenedCss;

type CreateMixinValueElement = {
  [size in ScreenSize]: string | Record<string, unknown>;
};

/**
 * Create a responsive css sizing mixin.
 * @param propName Any property name, i.e. size, variant, etc.
 */
export const createMixin =
  <PropertyName extends string>(propName: PropertyName) =>
  <PropertyValue extends string | number | boolean | Record<string, unknown>, Props>(
    getter: ResponsiveGetter<PropertyName, PropertyValue, Omit<Props & Theme, PropertyName>>,
  ): CreateMixin<PropertyName, PropertyValue, Props & Theme> =>
  (props) => {
    if (!isDefined(props[propName])) {
      return getter({ ...props, [propName]: undefined }, css);
    }

    if (isString(props[propName]) || isNumber(props[propName])) {
      return getter({ ...props, [propName]: props[propName] as PropertyValue }, css);
    }

    if (typeof props[propName] === 'boolean') {
      return getter({ ...props, [propName]: props[propName] as PropertyValue }, css);
    }

    return Object.keys(props[propName] as CreateMixinValueElement).map((key) => {
      if (!isObjectLike(props[propName])) {
        return undefined;
      }

      const breakpoint = key as ScreenSize;
      const valueElement = (props[propName] as CreateMixinValueElement)[breakpoint];

      if (!isDefined(valueElement)) {
        return;
      }

      return getter({ ...props, [propName]: valueElement as PropertyValue }, media[breakpoint]);
    });
  };

// Create a responsive css sizing mixin with size property.
export const createSizeMixin = createMixin('size');

// Create a responsive css sizing mixin with variant property.
export const createVariantMixin = createMixin('variant');
