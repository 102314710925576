type RumConfig = {
  appName: string;
  appVersion?: string;
  env: string;
  datadog: DatadogConfig;
};

type DatadogConfig = {
  applicationId: string;
  site: string;
  clientToken: string;
  disabled: boolean;
};

/**
 * Initialize Datadog Real User Monitoring
 * Info: https://docs.datadoghq.com/real_user_monitoring/browser/
 */
export async function initRealUserMonitoring(config: RumConfig): Promise<void> {
  const { appName, appVersion, env, datadog } = config;
  const { applicationId, site, clientToken, disabled } = datadog;

  if (disabled) {
    return;
  }

  const { datadogRum } = await import('@datadog/browser-rum-slim');

  datadogRum.init({
    clientToken,
    applicationId,
    site,
    version: appVersion,
    service: appName,
    env,
    sampleRate: 100,
    trackInteractions: true,
  });
}
