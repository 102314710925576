import type { ParsedUrlQuery } from 'querystring';

import { isServerRendered } from './isServerRendered';

/**
 * Return a query param value from the location URL based on name.
 * This exists as useRouter doesn't expose query params on client side.
 *
 * @param name - the name of the query param to retrieve.
 */
export function getQueryParam(name: string, query?: ParsedUrlQuery): string | undefined {
  // return early for SSG as location won't be defined on the server
  if (isServerRendered) {
    const value = query?.[name];

    return typeof value === 'string' ? value : undefined;
  }

  return new URLSearchParams(location.search).get(name) || undefined;
}
