import { PAGE_HOW_IT_WORKS, PAGE_INDEX, PAGE_FAQ, PAGE_FAQ_EMBED, PAGE_CAREERS, PAGE_GIFT, PAGE_EMPLOYERS, PAGE_THINGS_WE_DO, PAGE_WELCOME, PAGE_WHAT_YOU_GET, PAGE_ABOUT_US, PAGE_PRICING } from '@store/constants/routes';
import { PrismicPageTypeRoutes } from '@yoweb/prismic-slicemachine/constants/routes'; // Simple route resolver

export var prismicLinkResolver = function prismicLinkResolver(doc) {
  switch (doc.type) {
    case PrismicPageTypeRoutes.pageAboutUs.type:
      return PAGE_ABOUT_US;

    case PrismicPageTypeRoutes.pageHome.type:
      return PAGE_INDEX;

    case PrismicPageTypeRoutes.pageHowItWorks.type:
      return PAGE_HOW_IT_WORKS;

    case PrismicPageTypeRoutes.embedFaq.type:
      return PAGE_FAQ_EMBED;

    case PrismicPageTypeRoutes.pageWhatYouGet.type:
      return PAGE_WHAT_YOU_GET;

    case PrismicPageTypeRoutes.pageCareers.type:
      return PAGE_CAREERS;

    case PrismicPageTypeRoutes.pageFaq.type:
      return PAGE_FAQ;

    case PrismicPageTypeRoutes.pageGift.type:
      return PAGE_GIFT;

    case PrismicPageTypeRoutes.pageEmployers.type:
      return PAGE_EMPLOYERS;

    case PrismicPageTypeRoutes.pageThingsWeDo.type:
      return PAGE_THINGS_WE_DO;

    case PrismicPageTypeRoutes.pageWelcome.type:
      return PAGE_WELCOME;

    case PrismicPageTypeRoutes.pageWaitlist.type:
      return PAGE_PRICING;

    default:
      return PAGE_INDEX;
  }
};