import { isDefined } from './isDefined';
import { fromEntries } from './shims/fromEntries';

/**
 * Removes empty (null | undefined) properties from object
 * @example
 * const obj = {
 *   foo: 'foo',
 *   bar: undefined,
 *   baz: null,
 *   laz: ''
 * };
 *
 * objRemoveEmpty(obj) // { foo: 'foo', laz: '' }
 * objRemoveEmpty(obj, true) // { foo: 'foo' }
 */
export const objRemoveEmpty = <T extends Record<string, unknown>>(
  obj: T,
  removeEmptyString = false,
) =>
  fromEntries(
    Object.entries(obj).filter(
      ([, value]) => isDefined(value) && (removeEmptyString ? value !== '' : true),
    ),
  );
