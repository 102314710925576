import type { AnalyticsPayload } from 'yo-services/yolabs/analytics/yoanalytics/analytics_events_pb';

// Note: when a new event is added to `AnalyticsPayload.ExternalEvent` this map
// will need to be updated to include the "Event Name" which is defined in the proto as the "message"
// For example, `cta` maps to `CTAEvent` ("event_name" -> message name)
// https://github.com/yolabs-io/protos/blob/c3b9786c630e5ae1409ec8e3cdc7c98970213ee3/src/yolabs/analytics/yoanalytics/analytics_events.proto#L162-L164
export const ExternalEventToEventName: Record<
  keyof AnalyticsPayload.ExternalEvent.AsObject,
  string
> = {
  accountCreation: 'AccountCreation',
  cta: 'CTAEvent',
  featuredTodoView: 'FeaturedTodoView',
  login: 'LoginEvent',
  logout: 'LogoutEvent',
  pageView: 'PageView',
  recurringTaskCreation: 'RecurringTodoCreation',
  sendToLoginOrSignup: 'SendToLoginOrSignup',
  signupV2StepSkip: 'SignupV2StepSkipEvent',
  taskLandingDwell: 'TaskLandingDwellEvent',
  taskLandingMessagesDwell: 'TaskLandingMessagesDwellEvent',
  taskLandingOptionsDwell: 'TaskLandingOptionsDwellEvent',
  taskLandingDetailsDwell: 'TaskLandingDetailsDwellEvent',
  todoCategoryView: 'TodoCategoryView',
  todoConversationView: 'TodoConversationView',
  todoCsat: 'ToDoCSAT',
  todoStart: 'TodoStart',
  todoStepVisit: 'TodoStepVisit',
  todoView: 'TodoView',
  todoDiscoveryFiltersUpdated: 'TodoDiscoveryFiltersUpdated',
  todoCategorySearchResultsView: 'TodoCategorySearchResultsView',
};

// We maintain a list of user agents that we know are known crawler / bots.
// This allows us to ignore bot activity in mixpanel-browser and mixpanel-node.
// We maintain this list since it's only available to mixpanel-browser and we also want this
// behavior on our server analytic calls.
// See: https://github.com/mixpanel/mixpanel-js/blob/7d155e6fce7e59f3e13eb2e4d69b9905f10dbb36/src/utils.js#L900C1-L926C3
const BLOCKED_USER_AGENTS = [
  'ahrefsbot',
  'baiduspider',
  'bingbot',
  'bingpreview',
  'chrome-lighthouse',
  'facebookexternal',
  'petalbot',
  'pinterest',
  'screaming frog',
  'yahoo! slurp',
  'yandexbot',
  'slack-imgproxy',
  'slackbot',
  'semrushbot',
  'wpbot',
  'mj12bot',
  '$',
  'twitterbot',
  'bitsightbot',
  'yahoomailproxy',
  'blexbot',
  'seznambot',
  'ev-crawler',
  'zoominfobot',
  'linkedinbot',
  'coccocbot',
  'wellknownbot',
  'dotbot',
  'yandexrenderresourcesbot',
  'python-urllib',
  'cincrawdata',
  'pinterestbot',
  'dataforseobot',
  'netestate ne crawler',
  'help.mail.ru/webmaster/indexing/robots',
  'petalbot',
  'bytespider',
  'barkrowler',
  'workona-favicon-service',
  'archive.org_bot',

  // a whole bunch of goog-specific crawlers
  // https://developers.google.com/search/docs/advanced/crawling/overview-google-crawlers
  'adsbot-google',
  'apis-google',
  'duplexweb-google',
  'feedfetcher-google',
  'google favicon',
  'google web preview',
  'google-read-aloud',
  'googlebot',
  'googleweblight',
  'mediapartners-google',
  'storebot-google',

  // specific to yohana test agents
  'python-request',
  'curl',
];

export const isBot = (ua = '') => {
  const lcaseUa = ua.toLowerCase();

  for (let i = 0; i < BLOCKED_USER_AGENTS.length; i++) {
    if (lcaseUa.includes(BLOCKED_USER_AGENTS[i])) {
      return true;
    }
  }

  return false;
};

export const calculateCookieDomain = (
  preferredDomain: string | undefined,
  currentDomain: string | undefined,
) =>
  // yolabs.io is still in use and we only want to set the global domain unless on yohana.[com|jp] (process.env.MIXPANEL_UUID_COOKIE_DOMAIN)
  // When unset, the domain will resolve to the current domain to avoid the browser rejecting on mismatch domain
  preferredDomain && currentDomain?.includes(preferredDomain) ? preferredDomain : undefined;
