import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const DoneFilledIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.499 3.94a.75.75 0 0 1 .062 1.058l-12 13.5a.75.75 0 0 1-1.09.032l-6-6a.75.75 0 1 1 1.06-1.06l5.437 5.437L20.44 4.002a.75.75 0 0 1 1.059-.063Z"
      fill="currentColor"
    />
  </IconBase>
);

export default DoneFilledIcon;
