import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const TwitterIcon = (props: IconProps) => (
  <IconBase width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M16.6909 6.21185L14.2231 9.10505L13.6061 9.82835L13.2977 10.19L13.0086 10.5291L12.864 10.6987M6.81961 17.7847L9.28744 14.8915L10.5214 13.4449L10.8298 13.0832"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3987 17.7764L6.50225 6.26591L9.58997 6.26591L17.47 17.7764L14.3987 17.7764Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </IconBase>
);

export default TwitterIcon;
