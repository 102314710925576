import styled, { type Colors } from 'styled-components';
import { useState, useRef, useEffect } from 'react';

import { Box } from '@yoweb/ui/components/Box';
import { Flex } from '@yoweb/ui/components/Flex';
import { Text } from '@yoweb/ui/components/typography';
import { DoneFilledIcon } from '@yoweb/ui/components/Icon/DoneFilledIcon';
import { CaretUpIcon } from '@yoweb/ui/components/Icon/CaretUpIcon';
import { media } from '@yoweb/ui/styles/utils';
import { getColor, getRadii, getSpace } from '@yoweb/ui/styles/utils/theme';
import { useTranslation, type ParseKeys } from '@yoweb/next-i18next';
import { useClickOutside } from '@yoweb/ui/hooks/useClickOutside';
import {
  REGION_US_FLAG,
  REGION_US_CODE,
  REGION_JP_FLAG,
  REGION_JP_CODE,
} from '@yoweb/ui/constants/regions';

type Region = {
  flag: string;
  i18nKey: ParseKeys<'common'>;
  isoCode: string;
};

type StateType = {
  isDropdownVisible: boolean;
  region: Region;
  regions: Region[];
};

const regions: Region[] = [
  {
    flag: REGION_US_FLAG,
    i18nKey: 'regions.US',
    isoCode: REGION_US_CODE,
  },
  {
    flag: REGION_JP_FLAG,
    i18nKey: 'regions.JP',
    isoCode: REGION_JP_CODE,
  },
];

const initialState: StateType = {
  isDropdownVisible: false,
  region: regions[0],
  regions,
};

export type RegionSelectorProps = {
  baseJpUrl: string;
  baseUsUrl: string;
  linkColor?: keyof Colors;
  regionCode?: string;
};

/**
 * Redirects to different locale top level domains keeping path and query params
 */
const RegionSelector = ({
  baseUsUrl,
  baseJpUrl,
  linkColor = 'base000',
  regionCode,
}: RegionSelectorProps): JSX.Element | null => {
  const { t } = useTranslation<'common'>();
  const [state, setState] = useState(initialState);
  const ref = useRef(null);
  const ariaLabelledBy = 'region-select';

  const baseUrl2RegionMap: { [key: string]: string } = {
    [REGION_US_CODE]: baseUsUrl,
    [REGION_JP_CODE]: baseJpUrl,
  };

  const showDropdown = () => {
    setState({ ...state, isDropdownVisible: true });
  };

  const hideDropdown = () => {
    setState({ ...state, isDropdownVisible: false });
  };

  const onRegionClick = (isoCode: string) => () => {
    if (isoCode !== state.region.isoCode && baseUrl2RegionMap[isoCode]) {
      const newBaseUrl = baseUrl2RegionMap[isoCode];
      const { pathname, search } = window.location;
      const encodedDestination = encodeURI(`${newBaseUrl}${pathname}${search}`);

      window.location.replace(encodedDestination);
    }
  };

  useEffect(() => {
    let newRegion = regionCode && regions.find(({ isoCode }) => isoCode === regionCode);

    if (!newRegion) {
      newRegion = window.location.origin === baseUsUrl ? state.regions[0] : state.regions[1];
    }

    setState({ ...state, region: newRegion });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useClickOutside(ref, hideDropdown);

  return (
    <Wrapper data-testid="region-selector" ref={ref}>
      {state.isDropdownVisible && (
        <RegionSelectorPopover data-testid="region-selector-popover" py="small1">
          {state.regions.map(({ isoCode, i18nKey, flag }) => (
            <RegionLine
              data-testid="region-selector-popover-line"
              key={`region-${isoCode}`}
              justifyContent="left"
              alignItems="baseline"
              onClick={onRegionClick(isoCode)}
            >
              <Flag>{flag}</Flag>
              <Name as="div" size="lg" weight="semibold">
                {t(i18nKey)}
              </Name>
              {state.region.isoCode === isoCode && <Icon>{<DoneFilledIcon />}</Icon>}
            </RegionLine>
          ))}
        </RegionSelectorPopover>
      )}

      <ActiveRegion
        alignItems="baseline"
        id={ariaLabelledBy}
        justifyContent="left"
        linkColor={linkColor}
        onClick={showDropdown}
      >
        <Flag>{state.region.flag}</Flag>
        <Name as="span" size="sm">
          {t(state.region.i18nKey)}
        </Name>
        <CaretIconContainer isDropdownVisible={state.isDropdownVisible}>
          <CaretUpIcon />
        </CaretIconContainer>
      </ActiveRegion>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const RegionSelectorPopover = styled(Box)`
  background-color: ${getColor('base000')};
  border-radius: ${getRadii('small')};
  min-width: 220px;
  position: absolute;
  top: -${getSpace('small4')};
  transform: translate(0, -100%);

  ${media.md`
    left: 50%;
    top: -${getSpace('normal1')};
    transform: translate(-50%, -100%);
  `}
`;

const Flag = styled.span`
  margin-right: ${getSpace('small3')};
`;

const Name = styled(Text)`
  white-space: nowrap;
`;

const Icon = styled.span`
  margin-left: auto;
`;

const RegionLine = styled(Flex)`
  border-bottom: 1px solid ${getColor('base100')};
  cursor: pointer;
  padding: ${getSpace('small4')} ${getSpace('normal1')};

  &:last-child {
    border: none;
  }
`;

const ActiveRegion = styled(Flex)<{ linkColor: keyof Colors }>`
  color: ${({ linkColor }) => getColor(linkColor)};
  cursor: pointer;
`;

const CaretIconContainer = styled.div<{ isDropdownVisible: boolean }>`
  display: block;
  transform-origin: center;
  transform: ${({ isDropdownVisible }) =>
    isDropdownVisible ? `translate(0, 3px) rotate(0)` : `translate(0, 0) rotate(180deg)`};
  margin-left: ${getSpace('small3')};
`;

export default RegionSelector;
