/**
 * Track the user's visit to a web page.
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 *
 * @param url - the page url to track.
 */
export const trackPageView = (url: string): void => {
  window.dataLayer?.push({
    event: 'pageview',
    page_path: url,
  });
};

type TrackEventProps = {
  action: string;
  category: string;
  label?: string;
  value?: number | Record<string, string | number>;
  nonInteraction?: boolean;
};

/**
 * Track a custom event.
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 *
 * @param action - event action as seen in Google Analytics Event reports.
 * @param category -  the event category.
 * @param label - the event label.
 * @param value - the event value.
 * @param nonInteraction - whether the event was triggered through a non-interactive action.
 */
export const trackEvent = ({
  action,
  category,
  label,
  value,
  nonInteraction,
}: TrackEventProps): void => {
  window.dataLayer?.push({
    event: 'customEvent',
    eventData: {
      category,
      action,
      label,
      value,
      nonInteraction,
    },
  });

  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value,
    non_interaction: nonInteraction,
  });
};

type ConversionProps = {
  id: string;
  label: string;
};

/**
 * Tracks clicks as conversions
 * https://support.google.com/google-ads/answer/6331304#zippy=%2Cset-up-conversion-tracking-using-javascript%2Cset-up-conversion-tracking-using-the-global-site-tag
 *
 * @param id - the conversion id.
 * @param label -  the conversion label.
 */
export const trackConversion = ({ id, label }: ConversionProps): void => {
  window.dataLayer?.push('event', 'conversion', {
    send_to: `${id}/${label}`,
  });
};
