import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const ProfileIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 12a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0ZM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm0 5a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5ZM7.75 10a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0Zm-.795 6.246a3.769 3.769 0 0 1 2.543-.996h5.004a3.75 3.75 0 0 1 2.569 1.02l.06.06a.75.75 0 1 1-1.062 1.06l-.049-.05a2.25 2.25 0 0 0-1.518-.59H9.498c-.56 0-1.096.211-1.52.593l-.047.047a.75.75 0 1 1-1.062-1.06l.06-.06a.656.656 0 0 1 .026-.024Z"
      fill="currentColor"
    />
  </IconBase>
);

export default ProfileIcon;
