/* eslint-disable react/jsx-no-literals */
import { useTheme } from 'styled-components';
import type { SVGProps } from 'react';

import { Flex } from './Flex';

type SpinnerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type SpinnerProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height'> & {
  size?: SpinnerSize;
};

type SpinnerSvgProps = {
  size: number;
  strokeWidth: number;
};

const sizeMap: { [key in SpinnerSize]: SpinnerSvgProps } = {
  xs: { size: 8, strokeWidth: 1 },
  sm: { size: 16, strokeWidth: 2 },
  md: { size: 24, strokeWidth: 4 },
  lg: { size: 48, strokeWidth: 6 },
  xl: { size: 96, strokeWidth: 8 },
};

/**
 * A styled spinner used to represented loading.
 * Generated using loading.io.
 *
 * @param props - SVG properties applies to the top-most container.
 */
export function Spinner(props: SpinnerProps) {
  const { stroke } = props;
  const theme = useTheme();
  const strokeColor = stroke || theme?.colors?.base700;
  const { size, strokeWidth } = sizeMap[props.size ?? 'md'];

  return (
    <Flex alignContent="center" justifyItems="center" alignSelf="center">
      <svg
        width={size}
        height={size}
        viewBox="0 0 96 96"
        preserveAspectRatio="xMidYMid"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        display="block"
        role="img"
        {...props}
      >
        {/* eslint-disable-next-line react/no-unknown-property */}
        <style jsx>
          {`
            .ecaJe9N0T842_tr {
              animation: ecaJe9N0T842_tr__tr 1200ms linear infinite normal forwards;
            }
            @keyframes ecaJe9N0T842_tr__tr {
              0% {
                transform: translate(48.000006px, 47.46183px) rotate(180deg);
              }
              100% {
                transform: translate(48.000006px, 47.46183px) rotate(540deg);
              }
            }
            .ecaJe9N0T842 {
              animation: ecaJe9N0T842_s_do 1200ms linear infinite normal forwards;
            }
            @keyframes ecaJe9N0T842_s_do {
              0% {
                stroke-dashoffset: 0;
              }
              50% {
                stroke-dashoffset: 69.38;
                animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
              }
              100% {
                stroke-dashoffset: 0;
              }
            }
          `}
        </style>
        <g className="ecaJe9N0T842_tr" transform="translate(48.000006,47.46183) rotate(180)">
          <path
            className="ecaJe9N0T842"
            d="M47.9966,4.53843c5.7778.069826,11.4989,1.263318,16.8368,3.51232s10.1881,5.509503,14.2736,9.59523s7.3262,8.916837,9.5372,14.217405c2.2111,5.300568,3.3491,10.966764,3.3491,16.675045"
            transform="translate(-47.9966,-49.0766)"
            fill="none"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeWidth={strokeWidth}
            strokeDasharray={69.38}
          />
        </g>
      </svg>
    </Flex>
  );
}

export default Spinner;
