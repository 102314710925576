import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const MembershipIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2.75a.25.25 0 0 0-.25.25v3c0 .138.112.25.25.25h2a.25.25 0 0 0 .25-.25V3a.25.25 0 0 0-.25-.25h-2ZM9.25 3v1.25H4.041A2.791 2.791 0 0 0 1.25 7.041V19A2.75 2.75 0 0 0 4 21.75h16A2.75 2.75 0 0 0 22.75 19V7A2.75 2.75 0 0 0 20 4.25h-5.25V3A1.75 1.75 0 0 0 13 1.25h-2A1.75 1.75 0 0 0 9.25 3ZM20 5.75h-5.25V6A1.75 1.75 0 0 1 13 7.75h-2A1.75 1.75 0 0 1 9.25 6v-.25H4.041c-.713 0-1.291.578-1.291 1.291V19c0 .69.56 1.25 1.25 1.25h16c.69 0 1.25-.56 1.25-1.25V7c0-.69-.56-1.25-1.25-1.25ZM9.384 10.71a1.25 1.25 0 1 0-1.768 1.767 1.25 1.25 0 0 0 1.768-1.768Zm1.06-1.062a2.75 2.75 0 1 0-3.89 3.889 2.75 2.75 0 0 0 3.89-3.889ZM14.25 11a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Zm.75 2.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Zm-7.13 1.5a3.836 3.836 0 0 0-3.567 2.421.75.75 0 0 0 1.394.554c.129-.324.328-.615.583-.85l.001-.001c.433-.401 1-.624 1.59-.624H9.13c.59 0 1.158.223 1.59.624l.001.001c.255.235.454.526.583.85a.75.75 0 0 0 1.394-.554 3.836 3.836 0 0 0-3.565-2.421H7.87Z"
      fill="currentColor"
    />
  </IconBase>
);

export default MembershipIcon;
