import styled from 'styled-components';
import type { Space } from 'styled-components';
import type * as CSS from 'csstype';

import { createMixin, type CreateMixinType } from '@yoweb/ui/styles/utils/mixins';
import { getSpaceValue } from '@yoweb/ui/styles/utils/mixins.utils';

type SpaceKey = number | keyof Space;

export type GridProps = CreateMixinType<'align', CSS.Property.AlignItems> &
  CreateMixinType<'justify', CSS.Property.JustifyContent> &
  CreateMixinType<'justifyItems', CSS.Property.JustifyItems> &
  CreateMixinType<'gap', SpaceKey | number> &
  CreateMixinType<'rowGap', SpaceKey | number> &
  CreateMixinType<'columnGap', SpaceKey | number> &
  CreateMixinType<'flow', CSS.Property.GridAutoFlow> &
  CreateMixinType<'columns', CSS.Property.GridTemplateColumns> &
  CreateMixinType<'rows', CSS.Property.GridTemplateRows> &
  CreateMixinType<'autoColumns', CSS.Property.GridAutoColumns> &
  CreateMixinType<'as', string> &
  CreateMixinType<'height', CSS.Property.Height> &
  CreateMixinType<'width', CSS.Property.Width>;

const DEFAULT_GAP = 'normal1';

export const align = createMixin('align')<CSS.Property.AlignItems, GridProps>(({ align }, css) =>
  align
    ? css`
        align-items: ${align};
      `
    : css``,
);

export const justify = createMixin('justify')<CSS.Property.JustifyContent, GridProps>(
  ({ justify = 'stretch' }, css) =>
    justify
      ? css`
          justify-content: ${justify};
        `
      : css``,
);

export const justifyItems = createMixin('justify')<CSS.Property.JustifyItems, GridProps>(
  ({ justifyItems }, css) =>
    justifyItems
      ? css`
          justify-items: ${justifyItems};
        `
      : css``,
);

export const gap = createMixin('gap')<SpaceKey, GridProps>(({ gap = DEFAULT_GAP }, css) =>
  gap
    ? css`
        grid-gap: ${getSpaceValue(gap)};
      `
    : css``,
);

export const rowGap = createMixin('rowGap')<SpaceKey, GridProps>(({ rowGap }, css) =>
  rowGap
    ? css`
        row-gap: ${getSpaceValue(rowGap)};
      `
    : css``,
);

export const columnGap = createMixin('columnGap')<SpaceKey, GridProps>(({ columnGap }, css) =>
  columnGap
    ? css`
        column-gap: ${getSpaceValue(columnGap)};
      `
    : css``,
);

export const flow = createMixin('flow')<CSS.Property.GridAutoFlow, GridProps>(({ flow }, css) =>
  flow
    ? css`
        grid-auto-flow: ${flow};
      `
    : css``,
);

export const columns = createMixin('columns')<CSS.Property.GridTemplateColumns, GridProps>(
  ({ columns }, css) =>
    columns
      ? css`
          grid-template-columns: ${columns};
        `
      : css``,
);

export const rows = createMixin('rows')<CSS.Property.GridTemplateRows, GridProps>(
  ({ rows }, css) =>
    rows
      ? css`
          grid-template-rows: ${rows};
        `
      : css``,
);

export const autoColumns = createMixin('autoColumns')<CSS.Property.GridAutoColumns, GridProps>(
  ({ autoColumns }, css) =>
    autoColumns
      ? css`
          grid-auto-columns: ${autoColumns};
        `
      : css``,
);

export const height = createMixin('height')<CSS.Property.Height, GridProps>(({ height }, css) =>
  height
    ? css`
        height: ${height};
      `
    : css``,
);

export const width = createMixin('width')<CSS.Property.Width, GridProps>(({ width }, css) =>
  width
    ? css`
        width: ${width};
      `
    : css``,
);

/**
 * A general purposes Grid component.
 */
export const Grid = styled.div<GridProps>`
  display: grid;
  ${gap};
  ${rowGap};
  ${columnGap};
  ${flow};
  ${align};
  ${justify};
  ${justifyItems};
  ${columns};
  ${rows};
  ${autoColumns};
  ${height};
  ${width};
`;
