import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const CaretDownIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.47 6.97a.75.75 0 0 1 1.06 0L12 15.44l8.47-8.47a.75.75 0 1 1 1.06 1.06l-9 9a.75.75 0 0 1-1.06 0l-9-9a.75.75 0 0 1 0-1.06Z"
      fill="currentColor"
    />
  </IconBase>
);

export default CaretDownIcon;
