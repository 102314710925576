import type { NextApiRequest } from 'next';

import { QUERY_PARAM_LOCALE, QUERY_PARAM_TIMEZONE } from '@yoweb/utils/constants/queryParams';

/**
 * Get the locale from the request's "locale" query param.
 *
 * @param req - Next API route request.
 */
export const getLocaleForAPI = (req: NextApiRequest) => {
  const locale = req?.query?.[QUERY_PARAM_LOCALE];

  if (typeof locale !== 'string') {
    throw new Error(`${QUERY_PARAM_LOCALE} query parameter required but not set`);
  }

  // No fallbacks since that would mask bugs
  // no need to read from cookie since it'll always be set
  return locale;
};

/**
 * Get the timezone from the request's "timezone" query param.
 *
 * @param req - Next API route request.
 */
export const getTimezoneForAPI = (req: NextApiRequest) => {
  const timeZone = req?.query?.[QUERY_PARAM_TIMEZONE];

  if (typeof timeZone !== 'string') {
    throw new Error(`${QUERY_PARAM_TIMEZONE} query parameter required but not set`);
  }

  // No fallbacks since that would mask bugs
  // no need to read from cookie since it'll always be set
  return timeZone;
};

export const generateConfig = () => ({
  fallbackLng: 'en-US',
  defaultNS: 'common',
  ns: ['common'],
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
});
