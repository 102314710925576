import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import type { MouseEvent, ReactNode } from 'react';
import styled, { type Space } from 'styled-components';

import { useClickOutside } from '@yoweb/ui/hooks/useClickOutside';
import { Box } from './Box';
import { getShadow, getZIndex } from '@yoweb/ui/styles/utils/theme';
import { type BoxValue, setBoxValue } from '@yoweb/ui/styles/utils/mixins.utils';

export type DropdownProps = {
  /** Match with button id for example */
  ariaLabelledby: string;
  open: boolean;
  onClick?(e: MouseEvent<HTMLAnchorElement>, id: string): void;
  children: ReactNode;
  /** This is the element/elements that dropdown is wrapped around */
  wrappedElement: ReactNode;
  onClose(): void;
  minWidth?: number | keyof Space;
  menuOffset?: BoxValue;
};

export const Dropdown = (props: DropdownProps) => {
  const ref = useRef(null);

  useClickOutside(ref, props.onClose);

  return (
    <Box position="relative" ref={ref}>
      {props.wrappedElement}
      <AnimatePresence initial={false}>
        {props.open && <DropdownInner {...props} />}
      </AnimatePresence>
    </Box>
  );
};

const DropdownInner = ({ ariaLabelledby, menuOffset, children, minWidth }: DropdownProps) => {
  const dropdownVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { duration: 0.4, type: 'spring' },
    },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.2 } },
  };

  return (
    <Container
      role="menu"
      aria-orientation="vertical"
      aria-labelledby={ariaLabelledby}
      animate="animate"
      initial="initial"
      exit="exit"
      variants={dropdownVariants}
      $menuOffset={menuOffset}
    >
      <Menu mt="small1" radii="small" backgroundColor="base000" minWidth={minWidth || 'xl4'}>
        {children}
      </Menu>
    </Container>
  );
};

const Container = styled(motion.div)<{ $menuOffset?: BoxValue }>`
  transform-origin: top right;
  position: absolute;
  right: -10px;
  top: ${({ $menuOffset = 76 }) => setBoxValue($menuOffset)};
  z-index: ${getZIndex('popover')};
`;

const Menu = styled(Box)`
  && {
    box-shadow: ${getShadow('secondary')};
  }
`;
