/**
 * Checks if arr is of type Array.
 * @param arr any arr
 * @param allowEmpty If set to false then array must have values
 */
export const isArray = (arr: unknown, allowEmpty = true): arr is unknown[] => {
  if (allowEmpty) {
    return Array.isArray(arr);
  }

  return Array.isArray(arr) && arr.length > 0;
};
