export var LEAD_FORM_COMPLETED = {
  action: 'Lead Form Completed',
  category: 'Waitlist form',
  label: 'Waitlist form completed'
};
export var LEAD_FORM_STEP_COMPLETED = {
  action: 'Lead Form Step Completed',
  category: 'Waitlist form'
};
export var PURCHASE_FORM_COMPLETED = {
  action: 'Purchase Form Completed',
  category: 'Purchase form',
  label: 'Purchase form completed'
};
export var PURCHASE_FORM_STEP_COMPLETED = {
  action: 'Purchase Form Step Completed',
  category: 'Purchase form'
};
export var ONBOARDING_CALL_SCHEDULED = {
  action: 'Onboarding call scheduled',
  category: 'Purchase',
  label: 'Call scheduled'
};
export var GIFT_STEP_COMPLETED = {
  action: 'Gift Purchase Form Step Completed',
  category: 'Gifting'
};
export var GIFT_PURCHASE_FORM_COMPLETED = {
  action: 'Gift Purchase Form Completed',
  category: 'Gifting',
  label: 'Gift Purchase Form Completed'
}; // Conversions

export var LEAD_FORM_COMPLETED_CONVERSION = {
  id: '348039986Conversion',
  label: '6KRtCIiM59ICELLW-qUB'
}; // Facebook events

export var LEAD_FORM_COMPLETED_FACEBOOK = 'Lead Form Completed';
export var LEAD_FORM_STEP_COMPLETED_FACEBOOK = 'Lead Form Step Completed';
export var GIFT_PURCHASE_FORM_COMPLETED_FACEBOOK = 'Gift Purchase Form Completed';
export var GIFT_LANDING_PAGE_VIEW_FACEBOOK = 'Gift Landing Page view';
export var PURCHASE = 'Purchase';
export var ACCOUNT_CREATED = 'Account Created';
export var ADDRESS_UPDATED = 'Address Updated'; // Cookie for Casual Precision pixel

export var CASUAL_PRECISION_COOKIE = 'cp_analytics';