// taken from: https://stackoverflow.com/a/47961748
const shiftCharCode = (Δ: number) => (c: string) => String.fromCharCode(c.charCodeAt(0) + Δ);

/**
 * Whether this locale's users typically use both full and half width character sets.
 */
export const localeHasFullWidthChars = (lcLocale: string) => lcLocale === 'ja-jp';

/**
 * Convert a string from half to full characters.
 * Note that this will only work with latin characters and numbers.
 */
export const toFullWidth = (str: string) => str.replace(/[!-~]/g, shiftCharCode(0xfee0));

/**
 * Convert a string from full to half characters.
 * Note that this will only work with latin characters and numbers.
 */
export const toHalfWidth = (str: string) =>
  str.replace(/[！-～]/g, shiftCharCode(-0xfee0)).replace(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '-');
