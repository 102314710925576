import styled, { keyframes } from 'styled-components';

import { getSpace } from '@yoweb/ui/styles/utils/theme';

/**
 * Loading indicator dots.
 * The doc color is determined by the current text color.
 */
export function LoadingDots() {
  return (
    <Wrapper role="status">
      <Dot delay="2s" />
      <Dot delay="1s" />
      <Dot delay="0s" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${getSpace('small3')};
  justify-content: center;
`;

const LoadingAnimation = keyframes`
  0% { opacity: .15; }
  25% { opacity: 1; }
  50% { opacity: .25; }
  100% { opacity: .15; }
`;
const Dot = styled.div<{ delay: string }>`
  height: ${getSpace('small3')};
  width: ${getSpace('small3')};
  border-radius: 100%;
  background-color: currentcolor;

  /* animation */
  animation: ${LoadingAnimation} 3s linear infinite;
  animation-delay: ${({ delay }) => `-${delay}`};
`;

export default LoadingDots;
