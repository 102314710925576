import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const YohanaLogo = (props: IconProps) => (
  <IconBase viewBox="0 0 96 32" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M11.5912 15.3815C11.5912 16.052 11.5717 16.7225 11.537 16.9799C11.4591 17.5977 11.2664 18.1955 10.969 18.7428C10.8433 18.9495 10.6981 19.1436 10.5353 19.3225C10.1341 19.7651 9.64426 20.1189 9.09753 20.361C8.55079 20.6031 7.95925 20.7282 7.36106 20.7282C6.76288 20.7282 6.17134 20.6031 5.6246 20.361C5.07786 20.1189 4.58807 19.7651 4.18681 19.3225C4.02399 19.1436 3.87878 18.9495 3.75317 18.7428C3.45569 18.1955 3.26305 17.5977 3.1851 16.9799C3.14824 16.7225 3.13089 16.052 3.13089 15.3815V7.22681H0V15.3815C0 16.0563 0.0130088 16.7334 0.0368591 16.9799C0.0698841 17.3226 0.120543 17.6634 0.188635 18.0009C0.35197 18.8315 0.654715 19.6286 1.0841 20.3586C1.29816 20.7158 1.54572 21.0518 1.82346 21.3623C2.52787 22.1286 3.38426 22.7404 4.3383 23.159C5.29234 23.5776 6.32318 23.7937 7.3654 23.7937C8.40762 23.7937 9.43847 23.5776 10.3925 23.159C11.3465 22.7404 12.2029 22.1286 12.9073 21.3623C13.1837 21.0507 13.4312 20.7148 13.6467 20.3586C14.0763 19.6289 14.3783 18.8316 14.54 18.0009C14.6099 17.6636 14.6613 17.3228 14.6939 16.9799C14.7178 16.7334 14.7308 16.0563 14.7308 15.3815V7.22681H11.5912V15.3815Z" />
      <path d="M11.5369 25.1822C11.4589 25.7999 11.2663 26.3977 10.9688 26.945C10.8432 27.1517 10.698 27.3458 10.5352 27.5247C10.1339 27.9673 9.64411 28.3211 9.09738 28.5632C8.55064 28.8053 7.9591 28.9304 7.36091 28.9304C6.76273 28.9304 6.17119 28.8053 5.62445 28.5632C5.07772 28.3211 4.58792 27.9673 4.18666 27.5247C4.02384 27.3458 3.87863 27.1517 3.75302 26.945C3.66449 26.7924 3.58766 26.6334 3.52319 26.4692L0.444336 27.2068C0.608969 27.6767 0.823389 28.1277 1.08396 28.5522C1.29801 28.9093 1.54558 29.2454 1.82332 29.5558C2.52772 30.3221 3.38411 30.934 4.33815 31.3526C5.29219 31.7712 6.32303 31.9873 7.36525 31.9873C8.40747 31.9873 9.43832 31.7712 10.3924 31.3526C11.3464 30.934 12.2028 30.3221 12.9072 29.5558C13.1881 29.2448 13.4399 28.9089 13.6596 28.5522C14.0891 27.8225 14.3912 27.0252 14.5529 26.1945C14.6228 25.8572 14.6741 25.5164 14.7068 25.1735C14.7285 24.9572 14.7415 24.384 14.7437 23.7805L11.5802 24.5376C11.5672 24.8253 11.5542 25.0545 11.5369 25.1822Z" />
      <path d="M59.6755 8.46184C58.4921 7.47345 56.9996 6.92869 55.4562 6.92175C54.4248 6.93434 53.4068 7.15619 52.4641 7.57379C51.5214 7.99139 50.6738 8.59601 49.9728 9.35085C49.6992 9.6618 49.4546 9.99702 49.2421 10.3523C48.6492 11.3885 48.2952 12.5434 48.2057 13.7332C48.158 14.2263 48.158 16.437 48.2057 16.9301C48.2945 18.1193 48.6486 19.2737 49.2421 20.3088C49.4541 20.6652 49.6987 21.0011 49.9728 21.3124C50.6738 22.0673 51.5214 22.6719 52.4641 23.0895C53.4068 23.5071 54.4248 23.729 55.4562 23.7415C57.0001 23.7334 58.4927 23.1871 59.6755 22.1971V23.4214H62.7479V7.19862H59.6755V8.46184ZM59.6755 15.4874C59.6755 16.1039 59.6538 16.6944 59.6213 16.9323C59.5433 17.5497 59.3515 18.1474 59.0554 18.6952C58.9297 18.9024 58.7845 19.0973 58.6218 19.277C58.2211 19.7202 57.7316 20.0744 57.185 20.3169C56.6384 20.5594 56.0468 20.6847 55.4486 20.6847C54.8504 20.6847 54.2588 20.5594 53.7122 20.3169C53.1656 20.0744 52.6761 19.7202 52.2754 19.277C52.1164 19.0969 51.9748 18.902 51.8526 18.6952C51.5548 18.148 51.3622 17.5501 51.2846 16.9323C51.213 15.8678 51.213 14.7998 51.2846 13.7353C51.3617 13.1174 51.5543 12.5194 51.8526 11.9724C51.9748 11.7656 52.1164 11.5708 52.2754 11.3906C52.6761 10.9475 53.1656 10.5932 53.7122 10.3507C54.2588 10.1082 54.8504 9.98295 55.4486 9.98295C56.0468 9.98295 56.6384 10.1082 57.185 10.3507C57.7316 10.5932 58.2211 10.9475 58.6218 11.3906C58.7845 11.5703 58.9297 11.7652 59.0554 11.9724C59.3544 12.5193 59.5479 13.1173 59.6256 13.7353C59.668 14.2071 59.6861 14.6807 59.6798 15.1543L59.6755 15.4874Z" />
      <path d="M91.932 7.20727V8.46184C90.7476 7.47219 89.2533 6.92732 87.7083 6.92175C86.6769 6.93434 85.6589 7.15619 84.7162 7.57379C83.7735 7.99139 82.9259 8.59601 82.2249 9.35085C81.9506 9.66125 81.706 9.99653 81.4942 10.3523C80.9013 11.3885 80.5473 12.5434 80.4578 13.7332C80.4079 14.2263 80.4079 16.437 80.4578 16.9301C80.5467 18.1193 80.9007 19.2737 81.4942 20.3088C81.7054 20.6656 81.9501 21.0017 82.2249 21.3124C82.9259 22.0673 83.7735 22.6719 84.7162 23.0895C85.6589 23.5071 86.6769 23.729 87.7083 23.7415C89.2527 23.7372 90.747 23.1939 91.932 22.2058V23.4301H95.0043V7.20727H91.932ZM91.932 15.4874C91.932 16.1039 91.9125 16.6944 91.8799 16.9323C91.8023 17.5501 91.6097 18.148 91.3119 18.6952C91.1897 18.902 91.0481 19.0969 90.8891 19.277C90.4882 19.7202 89.9985 20.0745 89.4517 20.317C88.9049 20.5595 88.3132 20.6848 87.7148 20.6848C87.1164 20.6848 86.5247 20.5595 85.9779 20.317C85.4311 20.0745 84.9414 19.7202 84.5406 19.277C84.3822 19.0963 84.2406 18.9016 84.1177 18.6952C83.8199 18.148 83.6273 17.5501 83.5497 16.9323C83.477 15.8679 83.477 14.7997 83.5497 13.7353C83.6253 13.1174 83.8173 12.5193 84.1156 11.9724C84.2385 11.7661 84.38 11.5713 84.5384 11.3906C84.9393 10.9474 85.429 10.5931 85.9757 10.3506C86.5225 10.1081 87.1143 9.98282 87.7126 9.98282C88.311 9.98282 88.9028 10.1081 89.4495 10.3506C89.9963 10.5931 90.486 10.9474 90.8869 11.3906C91.046 11.5708 91.1875 11.7656 91.3097 11.9724C91.608 12.5194 91.8007 13.1174 91.8778 13.7353C91.9103 13.9689 91.9276 14.5465 91.9298 15.1543L91.932 15.4874Z" />
      <path d="M30.0902 10.3545C29.8777 9.99914 29.6331 9.66392 29.3595 9.35297C28.6665 8.58813 27.8205 7.97676 26.876 7.55833C25.9315 7.1399 24.9095 6.92371 23.8761 6.92371C22.8427 6.92371 21.8207 7.1399 20.8762 7.55833C19.9317 7.97676 19.0857 8.58813 18.3927 9.35297C18.1184 9.66337 17.8738 9.99866 17.662 10.3545C17.0691 11.3906 16.7151 12.5455 16.6256 13.7353C16.5779 14.2285 16.5779 16.4391 16.6256 16.9323C16.7145 18.1214 17.0686 19.2758 17.662 20.3109C17.8732 20.6678 18.1179 21.0038 18.3927 21.3146C19.0857 22.0794 19.9317 22.6908 20.8762 23.1092C21.8207 23.5276 22.8427 23.7438 23.8761 23.7438C24.9095 23.7438 25.9315 23.5276 26.876 23.1092C27.8205 22.6908 28.6665 22.0794 29.3595 21.3146C29.6336 21.0033 29.8782 20.6673 30.0902 20.3109C30.6824 19.2753 31.0365 18.1212 31.1266 16.9323C31.1743 16.4391 31.1743 14.2285 31.1266 13.7353C31.0371 12.5455 30.6831 11.3906 30.0902 10.3545ZM28.0369 16.9323C27.9593 17.5501 27.7666 18.148 27.4688 18.6951C27.3467 18.902 27.2051 19.0968 27.046 19.277C26.6453 19.7201 26.1558 20.0744 25.6092 20.3169C25.0626 20.5593 24.4711 20.6846 23.8729 20.6846C23.2746 20.6846 22.6831 20.5593 22.1365 20.3169C21.5899 20.0744 21.1004 19.7201 20.6997 19.277C20.5413 19.0963 20.3998 18.9015 20.2769 18.6951C19.9791 18.148 19.7864 17.5501 19.7088 16.9323C19.6373 15.8678 19.6373 14.7997 19.7088 13.7353C19.7852 13.1175 19.9772 12.5195 20.2747 11.9724C20.3976 11.766 20.5391 11.5713 20.6975 11.3906C21.0982 10.9474 21.5877 10.5932 22.1343 10.3507C22.6809 10.1082 23.2725 9.98291 23.8707 9.98291C24.4689 9.98291 25.0604 10.1082 25.6071 10.3507C26.1537 10.5932 26.6432 10.9474 27.0439 11.3906C27.2029 11.5707 27.3445 11.7656 27.4667 11.9724C27.7649 12.5194 27.9576 13.1174 28.0347 13.7353C28.107 14.7997 28.1077 15.8677 28.0369 16.9323Z" />
      <path d="M46.2695 12.245C46.1229 11.4827 45.8464 10.7511 45.4521 10.0819C45.2558 9.75705 45.0301 9.45078 44.7778 9.16696C44.1268 8.47516 43.3424 7.92177 42.4716 7.54002C41.6009 7.15828 40.6617 6.95606 39.7107 6.94552C38.3792 6.94616 37.0873 7.39762 36.0464 8.22604V0H32.9697V23.43H36.0421V14.4772C36.0368 14.0549 36.0527 13.6326 36.0898 13.2118C36.157 12.6753 36.3239 12.1559 36.582 11.6804C36.688 11.5013 36.8107 11.3326 36.9484 11.1764C37.2964 10.7917 37.7215 10.4841 38.1963 10.2735C38.671 10.063 39.1847 9.95421 39.7042 9.95421C40.2237 9.95421 40.7374 10.063 41.2121 10.2735C41.6868 10.4841 42.1119 10.7917 42.46 11.1764C42.599 11.332 42.7225 11.5008 42.8286 11.6804C43.0866 12.1559 43.2535 12.6753 43.3207 13.2118C43.3489 13.4281 43.3641 13.9365 43.3663 14.4772V23.43H46.443V14.6394C46.443 14.023 46.4321 13.4044 46.4104 13.1794C46.3798 12.8657 46.3328 12.5538 46.2695 12.245Z" />
      <path d="M78.5239 12.2449C78.3763 11.4829 78.0999 10.7514 77.7065 10.0819C77.5094 9.75696 77.283 9.45069 77.03 9.16691C76.3793 8.47537 75.5952 7.92213 74.7248 7.54039C73.8545 7.15866 72.9158 6.9563 71.9651 6.94547C70.6337 6.94463 69.3414 7.39371 68.2986 8.2195V7.2007H65.2241V23.4235H68.2986V14.4772C68.2986 13.9364 68.316 13.4194 68.3442 13.2118C68.4114 12.6752 68.5783 12.1559 68.8364 11.6804C68.9424 11.5008 69.0659 11.332 69.2049 11.1764C69.5528 10.7917 69.9777 10.4841 70.4523 10.2736C70.9268 10.0631 71.4403 9.95429 71.9597 9.95429C72.479 9.95429 72.9925 10.0631 73.4671 10.2736C73.9416 10.4841 74.3665 10.7917 74.7144 11.1764C74.8536 11.3324 74.9777 11.5011 75.0851 11.6804C75.3441 12.1555 75.5111 12.675 75.5773 13.2118C75.6144 13.6325 75.6303 14.0548 75.625 14.4772V23.43H78.706V14.6394C78.706 14.0229 78.706 13.4043 78.6713 13.1793C78.6301 12.8506 78.5824 12.5456 78.5239 12.2449Z" />
    </g>
  </IconBase>
);

export default YohanaLogo;
