import type { i18n, Resource } from 'i18next';

import createClient from './clients';
import type { I18nextPageResponse, I18nextInitOptions, WithI18nOptions } from './types';

const createResources = (lng: string, i18n: i18n, config: I18nextInitOptions): Resource => {
  const { fallbackLng } = config;
  const resources: Resource = {
    [lng]: {},
    [fallbackLng]: {},
  };

  // Grabs the translations, by namespace, out of the i18n client so that we can
  // serialize the client instance with them (typically passed as a pageProp)
  config.ns?.forEach((ns) => {
    resources[lng][ns] = i18n.services.resourceStore.data[lng]?.[ns] || {};

    if (lng !== fallbackLng) {
      resources[fallbackLng][ns] = i18n.services.resourceStore.data[fallbackLng]?.[ns] || {};
    }
  });

  return resources;
};

type CreatePageResponseProps = {
  options: WithI18nOptions;
  i18nextConfig: I18nextInitOptions & {
    lng: string;
    supportedLngs: string[];
  };
};

export async function createPageResponse({
  options,
  i18nextConfig,
}: CreatePageResponseProps): Promise<I18nextPageResponse> {
  const { localePath } = options;

  // Note: cloned because i18next actually mutates this object and we do not want that state sent
  // down to the client.  This creates an unsafe object to serialize - containing fns and refs to internal file system
  const config = structuredClone(i18nextConfig);
  const { lng } = config;

  if (config?.defaultNS) {
    config?.ns?.push(config.defaultNS as string);
  }

  if (!config.supportedLngs.includes(lng)) {
    config.supportedLngs.push(lng);
  }

  const { i18n, initializationPromise } = createClient(config, localePath);

  await initializationPromise;

  config.resources = createResources(lng, i18n, config);

  return {
    i18nextPageResponse: {
      config,
    },
  };
}

export type IWithI18nContext = {
  locale?: string;
};

type WithI18nResponse = {
  props: I18nextPageResponse;
};

export const parseContextI18n = (ctx: IWithI18nContext) => {
  if (typeof ctx.locale !== 'string') {
    throw new Error('Locale is missing from context object, unexpected.');
  }

  const { locale } = ctx;
  const lcaseLocale = locale.toLowerCase();
  const country = lcaseLocale.split('-')[1];

  return { locale, lcaseLocale, country };
};

export const withI18n = async (
  context: IWithI18nContext,
  options: WithI18nOptions,
  i18nextConfig: I18nextInitOptions,
): Promise<WithI18nResponse> => {
  const { resourceBundles, ns = [] } = options;
  const { locale: lng } = parseContextI18n(context);
  const { fallbackLng } = i18nextConfig;
  const supportedLngs = [lng];

  if (lng !== fallbackLng) {
    supportedLngs.push(fallbackLng);
  }

  // filters out translations that are not of the requested `locale`
  // and flattens the resources into a single Resource object
  const resources = {
    [lng]: resourceBundles?.reduce((accum, resource) => {
      const resourceLng = resource[lng];

      if (typeof resourceLng === 'object' && resourceLng) {
        return { ...accum, ...resourceLng };
      }

      return accum;
    }, {}),
  } as Resource;

  const props = await createPageResponse({
    options,
    i18nextConfig: {
      ...i18nextConfig,
      lng,
      ns: [...(i18nextConfig.ns ?? []), ...ns],
      resources,
      supportedLngs,
    },
  });

  return {
    props,
  };
};
