import { API_PATH } from '@yoweb/utils/constants/routes';
export var API_AUTH_STATUS = "".concat(API_PATH, "/auth-status");
export var API_CHECKOUT_PAYMENT = "".concat(API_PATH, "/checkout/payment");
export var API_COUPONS = "".concat(API_PATH, "/coupons");
export var API_CITIES = "".concat(API_PATH, "/cities");
export var API_EXIT_PREVIEW = "".concat(API_PATH, "/exit-preview");
export var API_GEO = "".concat(API_PATH, "/geo");
export var API_GIFT_CHECKOUT = "".concat(API_PATH, "/gift/checkout");
export var API_HEALTHZ = "".concat(API_PATH, "/healthz");
export var API_IMAGES = "".concat(API_PATH, "/images");
export var API_INVOICES = "".concat(API_PATH, "/invoices");
export var API_SIGNUP = "".concat(API_PATH, "/signup");
export var API_LOGOUT = "".concat(API_PATH, "/logout");
export var API_LOGIN = "".concat(API_PATH, "/login");
export var API_PRIVACY_POLICY = "".concat(API_PATH, "/privacy-policy");
export var API_PRISMIC = "".concat(API_PATH, "/prismic");
export var API_SUBSCRIPTIONS = "".concat(API_PATH, "/subscriptions");
export var API_SUBSCRIPTIONS_CANCEL = "".concat(API_SUBSCRIPTIONS, "/cancel");
export var API_SUBSCRIPTIONS_REACTIVATE = "".concat(API_SUBSCRIPTIONS, "/reactivate");
export var API_SUBSCRIPTIONS_CREATE = "".concat(API_SUBSCRIPTIONS, "/create");
export var API_TOS = "".concat(API_PATH, "/tos");
export var API_USER = "".concat(API_PATH, "/user");
export var API_USER_CREATE_SESSION = "".concat(API_USER, "/create-session");
export var API_USER_EXISTS = "".concat(API_USER, "/exists");
export var API_USER_FINISH_ONBOARDING = "".concat(API_USER, "/finish-onboarding");
export var API_USER_IMAGE = "".concat(API_USER, "/image");
export var API_USER_UPDATE_PASSWORD = "".concat(API_USER, "/update-password");
export var API_USER_VERIFY_CHECK = "".concat(API_USER, "/verify/check");
export var API_USER_VERIFY_RESEND = "".concat(API_USER, "/verify/resend");
export var API_PASSWORD_CHANGED_CALLBACK = "".concat(API_USER, "/password-changed-callback");
export var API_WAITLIST_CUSTOMER = "".concat(API_PATH, "/waitlist/customer");
export var API_WAITLIST_DIRECT_PURCHASE = "".concat(API_PATH, "/waitlist/direct-purchase");
export var API_WAITLIST_INVITE_CODE = "".concat(API_PATH, "/waitlist/user");
export var API_FORMAT_PHONE = "".concat(API_PATH, "/validate-phone-number");
export var API_CONTACT_FORM_SUBMISSION = "".concat(API_PATH, "/contact/form-submit");
export var API_FAQ_QUESTION_FORM_SUBMISSION = "".concat(API_PATH, "/faq/form-submit");
export var API_EMPLOYERS_FORM_SUBMISSION = "".concat(API_PATH, "/employers/form-submit");
export var API_EMPLOYERS_WHITE_PAPER_SUBMISSION = "".concat(API_PATH, "/employers/white-paper-submit");
export var API_EMPLOYERS_WHITE_PAPER_DOWNLOAD = "".concat(API_PATH, "/employers/white-paper-download");
export var API_USER_ENTITLEMENT = "".concat(API_SUBSCRIPTIONS, "/get-entitlement");
export var API_FEEDBACK_CANCEL = "".concat(API_PATH, "/feedback-cancel");
export var API_NEWSLETTER_SIGN_UP = "".concat(API_PATH, "/newsletter/sign-up");
export var API_VALIDATE_ZIP_CODE = "".concat(API_PATH, "/validate-zip-code");
export var API_YFB = "".concat(API_PATH, "/yfb");
export var API_YFB_TOKEN_VERIFY = "".concat(API_YFB, "/token/verify");
export var API_GET_DISCOUNT = "".concat(API_PATH, "/discount");
export var PAGE_ABOUT_US = '/about-us';
export var PAGE_WHAT_YOU_GET = '/what-you-get';
export var PAGE_CAREERS = '/careers';
export var PAGE_CHECKOUT = '/checkout';
export var PAGE_CHECKOUT_PLAN = "".concat(PAGE_CHECKOUT, "/plan");
export var PAGE_CHECKOUT_REDIRECT = "".concat(PAGE_CHECKOUT, "/redirect");
export var PAGE_CHECKOUT_ONBOARDING = "".concat(PAGE_CHECKOUT, "/onboarding");
export var PAGE_CHECKOUT_CONFIRMATION = "".concat(PAGE_CHECKOUT, "/confirmation");
export var PAGE_CHECKOUT_CONFIRMATION_ONBOARDING = "".concat(PAGE_CHECKOUT_CONFIRMATION, "/onboarding");
export var PAGE_CONTACT_US = '/contact-us';
export var PAGE_EMPLOYERS = '/employers';
export var PAGE_THINGS_WE_DO = '/things-we-do';
export var PAGE_PRICING = '/pricing';
export var PAGE_PRIME_PRO = '/lp/primepro';
export var PAGE_FAQ = '/faq';
export var PAGE_FAQ_CANCEL_QUESTION = '/faq?question=cancel';
export var PAGE_FAQ_MEMBERSHIP_SECTION = '/faq?section=yohana-membership';
export var PAGE_FAQ_UNLIMITED_QUESTION = '/faq?question=unlimited-to-dos';
export var PAGE_FAQ_WHEN_ARE_CHARGED_QUESTION = '/faq?question=when-are-charged';
export var PAGE_GIFT = '/gift';
export var PAGE_GIFT_CONFIRMATION = '/gift/confirmation';
export var PAGE_HOW_IT_WORKS = '/how-it-works';
export var PAGE_INDEX = '/';
export var PAGE_INDEX_LONG = '/index';
export var PAGE_INSPIRATION = '/inspiration';
export var PAGE_INVALID_INVITATION = '/invalid-invitation';
export var PAGE_PRESS = '/press';
export var PAGE_LANDING_BASE = '/lp';
export var PAGE_WELCOME_ID = 'welcome';
export var PAGE_WELCOME = "/".concat(PAGE_WELCOME_ID);
export var PAGE_ACCOUNT = function PAGE_ACCOUNT(basePoplarUrl) {
  return "".concat(basePoplarUrl, "/account");
};
export var PAGE_HOME = function PAGE_HOME(basePoplarUrl) {
  return "".concat(basePoplarUrl, "/");
};
export var PAGE_WALLET = function PAGE_WALLET(basePoplarUrl) {
  return "".concat(PAGE_ACCOUNT(basePoplarUrl), "/wallet");
};
export var PAGE_MEMBERSHIP = function PAGE_MEMBERSHIP(basePoplarUrl) {
  return "".concat(PAGE_ACCOUNT(basePoplarUrl), "/membership");
};
export var PAGE_PAYMENTS = function PAGE_PAYMENTS(basePoplarUrl) {
  return "".concat(PAGE_ACCOUNT(basePoplarUrl), "/payments");
};
export var PAGE_MEMBER_INTRODUCTION = function PAGE_MEMBER_INTRODUCTION(basePoplarUrl) {
  var step = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return "".concat(basePoplarUrl, "/member/introduction/step/").concat(step);
};
export var PAGE_LOGIN_ERROR = '/login-error';
export var PAGE_EMAIL_VERIFIED = '/email-verified';
export var PAGE_INTRODUCTION_PANASONIC_YOHANA = '/introduction';
export var PAGE_YFB_ERROR = '/yfb/error';
export var PAGE_YFB_INTRODUCTION = '/yfb/introduction';
export var PAGE_YFB = function PAGE_YFB(companySlug) {
  return "/yfb/".concat(companySlug);
};
export var PAGE_YFB_CONFIRM_VERIFICATION = function PAGE_YFB_CONFIRM_VERIFICATION(companySlug) {
  return "".concat(PAGE_YFB(companySlug), "/confirm_verification");
};
export var PAGE_PRIVACY_POLICY = '/privacy-policy';
export var PAGE_COOKIE_POLICY = '/cookie-policy';
export var PAGE_TERMS_OF_SERVICE = '/terms-of-service';
export var PAGE_COPYRIGHT_POLICY = '/copyright-policy';
export var PAGE_PRIVACY_NOTICE_CA = '/ca-privacy-notice';
export var PAGE_SPECIFIED_COMMERCIAL_TRANSACTIONS = '/specified-commercial-transactions';
export var PAGE_TRAVEL_TERMS_OF_SERVICE = '/travel-terms-of-service';
export var PAGE_TRAVEL_REGISTRATION = '/travel-registration';
export var PAGE_TRAVEL_CONDITIONS = '/travel-conditions';
export var PAGE_PRIMEPRO_TERMS_OF_SERVICE = '/primepro-terms-of-service';
export var PAGE_PHOTO_USAGE_RIGHTS = '/photo-usage-rights';
export var PAGE_APPLICANT_PRIVACY_POLICY = '/applicant-privacy-policy';
export var PAGE_EXTERNAL_ACCEPTANCE_POLICY = '/external-acceptance-policy';
export var EMBED_PATH = '/embed';
export var PAGE_PRIVACY_POLICY_EMBED = "".concat(EMBED_PATH, "/privacy-policy");
export var PAGE_COOKIE_POLICY_EMBED = "".concat(EMBED_PATH, "/cookie-policy");
export var PAGE_TERMS_OF_SERVICE_EMBED = "".concat(EMBED_PATH, "/terms-of-service");
export var PAGE_COPYRIGHT_POLICY_EMBED = "".concat(EMBED_PATH, "/copyright-policy");
export var PAGE_PRIVACY_NOTICE_CA_EMBED = "".concat(EMBED_PATH, "/ca-privacy-notice");
export var PAGE_OPEN_SOURCE_ATTRIBUTION_EMBED = "".concat(EMBED_PATH, "/open-source-attribution");
export var PAGE_TRAVEL_TERMS_OF_SERVICE_EMBED = "".concat(EMBED_PATH, "/travel-terms-of-service");
export var PAGE_TRAVEL_REGISTRATION_EMBED = "".concat(EMBED_PATH, "/travel-registration");
export var PAGE_TRAVEL_CONDITIONS_EMBED = "".concat(EMBED_PATH, "/travel-conditions");
export var PAGE_PRIMEPRO_TERMS_OF_SERVICE_EMBED = "".concat(EMBED_PATH, "/primepro-terms-of-service");
export var PAGE_SPECIFIED_COMMERCIAL_TRANSACTIONS_EMBED = "".concat(EMBED_PATH, "/specified-commercial-transactions");
export var PAGE_FAQ_EMBED = "".concat(EMBED_PATH, "/faq");