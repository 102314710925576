import { css } from 'styled-components';
import type {
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
  FontWeights,
  FontSizes,
  LineHeights,
  Colors,
  FontFamily,
  CSSProperties,
} from 'styled-components';

import { media } from '@yoweb/ui/styles/utils';
import { th } from '@yoweb/ui/styles/utils/theme';
import {
  type CreateMixinType,
  createVariantMixin,
  type WithVariant,
  createMixin,
} from '@yoweb/ui/styles/utils/mixins';
import {
  maxWidthMixin,
  textAlignMixin,
  overflowWrapMixin,
} from '@yoweb/ui/styles/utils/mixins.utils';
import type {
  TextAlignProps,
  OverflowWrapProps,
  MaxWidth,
} from '@yoweb/ui/styles/utils/mixins.utils';
import type { Breakpoints } from '@yoweb/ui/styles/utils';

type TextTransform = 'uppercase' | 'capitalize' | null;
type TextDecoration = 'underline' | 'line-through' | null;
type FontFamilyProps = CreateMixinType<'fontFamily', keyof FontFamily>;
type TextWeightProps = CreateMixinType<'weight', keyof FontWeights>;
type TextWhiteSpaceProps = CreateMixinType<'whiteSpace', CSSProperties['whiteSpace']>;
type TextWordBreakProps = CreateMixinType<'wordBreak', CSSProperties['wordBreak']>;

type TextTransformProps = {
  /** whitelist of text decorators to use */
  textDecoration?: TextDecoration;
  /** selection of common text transformations */
  $textTransform?: TextTransform;
};

/** Common transformations for text. */
const textTransformMixin = css<TextTransformProps>`
  ${({ $textTransform }) =>
    $textTransform &&
    css`
      text-transform: ${$textTransform};
    `}

  ${({ textDecoration }) =>
    textDecoration &&
    css`
      text-decoration: ${textDecoration};
    `}
`;

const textWeightMixin = createMixin('weight')<keyof FontWeights, TextBaseProps>(
  ({ weight }, css) =>
    weight
      ? css`
          font-weight: ${th.getFontWeight(weight)};
        `
      : css``,
);

const whiteSpaceMixin = createMixin('whiteSpace')<string, TextBaseProps>(({ whiteSpace }, css) =>
  whiteSpace
    ? css`
        white-space: ${whiteSpace};
      `
    : css``,
);

const wordBreakMixin = createMixin('wordBreak')<string, TextBaseProps>(({ wordBreak }, css) =>
  wordBreak
    ? css`
        word-break: ${wordBreak};
      `
    : css``,
);

type ColorType =
  | 'danger'
  | 'fg'
  | 'charcoal'
  | 'muted'
  | 'muted2'
  | 'muted3'
  | 'inherit'
  | 'inverse'
  | 'inverseMuted'
  | 'link'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'trueBlack'
  | 'attention'
  | 'gingko'
  | 'gingkoLight'
  | 'frost'
  | 'frostLight'
  | 'lilac'
  | 'lilacLight'
  | 'sage'
  | 'sageLight'
  | 'stone'
  | 'stoneLight'
  | 'sandDark'
  | 'sandMedium'
  | 'sandLight'
  | 'info'
  | 'infoLight'
  | 'critical'
  | 'criticalLight'
  | 'warning'
  | 'warningLight'
  | 'successLight';

type TextColorProps = WithVariant<ColorType>;

/**
 * Mapping a variant to a theme color for text.
 *
 * @param variant - variant to find color for.
 */
const colorMapping = (variant: ColorType = 'inherit'): keyof Colors => {
  const mapping = {
    inherit: 'inherit',
    fg: 'base900',
    primary: 'charcoal',
    charcoal: 'charcoal',
    inverse: 'base000',
    inverseMuted: 'base100',
    muted: 'base700',
    muted2: 'base400',
    muted3: 'base800',
    danger: 'critical',
    link: 'info',
    secondary: 'warning',
    success: 'success',
    successLight: 'successLight',
    trueBlack: 'base1000',
    attention: 'coral',
    gingko: 'gingko',
    gingkoLight: 'gingkoLight',
    frost: 'frost',
    frostLight: 'frostLight',
    lilac: 'lilac',
    lilacLight: 'lilacLight',
    sage: 'sage',
    sageLight: 'sageLight',
    stone: 'stone',
    stoneLight: 'stoneLight',
    sandDark: 'sandDark',
    sandMedium: 'sandMedium',
    sandLight: 'sandLight',
    info: 'info',
    infoLight: 'infoLight',
    critical: 'critical',
    criticalLight: 'criticalLight',
    warning: 'warning',
    warningLight: 'warningLight',
  } as Record<ColorType, keyof Colors>;

  return variant ? mapping[variant] : mapping['fg'];
};

/**
 * Title variants size mixin
 */
const textColorMixin = createVariantMixin<ColorType, TextColorProps>(
  ({ variant = 'inherit' }, tagFn) =>
    tagFn`
      color: ${th.getColor(colorMapping(variant))};
    `,
);

type FontSizeMixinProps = {
  fontSize?: keyof FontSizes | { [key in keyof Breakpoints]?: keyof FontSizes };
};

const fontSizeMixin = ({
  fontSize,
}: FontSizeMixinProps): FlattenInterpolation<ThemeProps<DefaultTheme>> | undefined => {
  if (!fontSize) return undefined;
  if (typeof fontSize === 'object') {
    return css`
      ${(fontSize._ || fontSize.xs) &&
      css`
        font-size: ${th.getFontSize((fontSize._ || fontSize.xs) as keyof FontSizes)};
      `}
      ${fontSize.sm &&
      css`
        ${media.sm`
          font-size: ${th.getFontSize(fontSize.sm)};
        `}
      `}
      ${fontSize.md &&
      css`
        ${media.md`
          font-size: ${th.getFontSize(fontSize.md)};
        `}
      `}
      ${fontSize.lg &&
      css`
        ${media.lg`
          font-size: ${th.getFontSize(fontSize.lg)};
        `}
      `}
      ${fontSize.xl &&
      css`
        ${media.xl`
          font-size: ${th.getFontSize(fontSize.xl)};
        `}
      `}
    `;
  }
  return css`
    font-size: ${th.getFontSize(fontSize)};
  `;
};

type LineHeightMixinProps = {
  lineHeight?: keyof LineHeights | Record<keyof Breakpoints, keyof LineHeights>;
};

const lineHeightMixin = ({
  lineHeight,
}: LineHeightMixinProps): FlattenInterpolation<ThemeProps<DefaultTheme>> | undefined => {
  if (!lineHeight) return undefined;
  if (typeof lineHeight === 'object') {
    return css`
      ${(lineHeight._ || lineHeight.xs) &&
      css`
        line-height: ${th.getLineHeight(lineHeight._ || lineHeight.xs)};
      `}
      ${lineHeight.sm &&
      css`
        ${media.sm`
          line-height: ${th.getLineHeight(lineHeight.sm)};
        `}
      `}
      ${lineHeight.md &&
      css`
        ${media.md`
        line-height: ${th.getLineHeight(lineHeight.md)};
        `}
      `}
      ${lineHeight.lg &&
      css`
        ${media.lg`
          line-height: ${th.getLineHeight(lineHeight.lg)};
        `}
      `}
      ${lineHeight.xl &&
      css`
        ${media.xl`
        line-height: ${th.getLineHeight(lineHeight.xl)};
        `}
      `}
    `;
  }
  return css`
    line-height: ${th.getLineHeight(lineHeight)};
  `;
};

type TextBaseProps = TextColorProps &
  TextTransformProps &
  TextWeightProps &
  FontSizeMixinProps &
  LineHeightMixinProps &
  MaxWidth &
  TextAlignProps &
  FontFamilyProps &
  TextWhiteSpaceProps &
  TextWordBreakProps &
  OverflowWrapProps;

export const fontFamilyMixin = createMixin('fontFamily')<keyof FontFamily, TextBaseProps>(
  ({ fontFamily = 'primary' as keyof FontFamily }, css) =>
    fontFamily
      ? css`
          font-family: ${th.getFontFamily(fontFamily)};
        `
      : css``,
);

/**
 * Base mixins most text will include.
 */
const textBase = css<TextBaseProps>`
  ${textColorMixin};
  ${textTransformMixin};
  ${textWeightMixin};
  ${fontSizeMixin};
  ${lineHeightMixin};
  ${maxWidthMixin};
  ${textAlignMixin};
  ${fontFamilyMixin};
  ${whiteSpaceMixin};
  ${wordBreakMixin};
  ${overflowWrapMixin};

  margin-block: 0 0;
`;

export type {
  ColorType,
  FontSizeMixinProps,
  LineHeightMixinProps,
  TextBaseProps,
  TextColorProps,
  TextTransformProps,
  TextWeightProps,
  TextWhiteSpaceProps,
};

export {
  textBase,
  textColorMixin,
  colorMapping,
  textWeightMixin,
  textTransformMixin,
  fontSizeMixin,
  lineHeightMixin,
};
