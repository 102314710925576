import {
  type Breadcrumb,
  captureException as sentryCaptureException,
  flush as sentryFlush,
} from '@sentry/browser';

import { getErrorMessage, isError } from './error';
import { isServerRendered } from './isServerRendered';

const NOISY_BREADCRUMB_URLS = [
  'sdk.split.io',
  'events.split.io',
  'telemetry.split.io',
  // very noisy datadog agent
  ':8126/',
  '/v0.4/traces',
  '/telemetry/proxy/api',
  '/api/healthz',
  '/api/auth-status',
  '/api/user',
];

/**
 * Filter out noisy breadcrumbs which clutter breadcrumbs logs.
 *
 * @param breadcrumb - Sentry breadcrumb
 */
export const beforeBreadcrumb = (breadcrumb: Breadcrumb) => {
  if (
    breadcrumb?.data?.url &&
    NOISY_BREADCRUMB_URLS.some((url) => breadcrumb?.data?.url.includes(url))
  ) {
    return null;
  }

  return breadcrumb;
};

const copyError = (ex: unknown) => {
  if (ex instanceof Error) {
    return ex;
  }

  if (isError(ex)) {
    const error = new Error(ex.message);
    error.stack = ex.stack;
    error.name = ex.name;

    return error;
  }

  return new Error(getErrorMessage(ex));
};

/**
 * Capture and log an exception.
 *
 * @param error - the error to log.
 * @param ignoreLogging
 */
export function captureException(error: unknown): void {
  if (!error) {
    return;
  }

  sentryCaptureException(copyError(error));

  // Ensures error is logged to Datadog
  const formattedError =
    typeof error === 'object'
      ? JSON.stringify(error, ['name', 'message', 'stack'])
      : JSON.stringify({ error: `${error}` });

  if (isServerRendered) {
    // agent reads stdout/stderr
    // eslint-disable-next-line
    console.error(formattedError);
  } else {
    void import('@datadog/browser-rum-slim').then(({ datadogRum }) => {
      // browser requires use of datadog rum to capture and send to datadog
      datadogRum.addError(error);
    });
  }
}

/**
 * A promise that resolves when all current events have been sent. If you provide a timeout and the queue takes longer to drain the promise returns false.
 *
 * @param timeout — Maximum time in ms the client should wait.
 */
export const flush = (timeout?: number) => sentryFlush(timeout);
