import { useEffect } from 'react'; // Only show the Osano consent management dialog on homepage (index)

export function useOsano(showOsanoDialog) {
  useEffect(function () {
    if (typeof window.Osano === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Osano('onInitialized', function () {
        var osanoDialog = document.querySelector('.osano-cm-dialog');

        if (osanoDialog) {
          osanoDialog.style.display = showOsanoDialog ? 'flex' : 'none';
        }
      });
    }
  }, [showOsanoDialog]);
}