import { css as scCss } from 'styled-components';
import type { Space, ZIndices } from 'styled-components';
import type * as CSS from 'csstype';

import { pxToRem } from '.';
import { isNumber } from '@yoweb/utils/isNumber';
import { isUnitValue } from '@yoweb/utils/isUnitValue';
import { getSpace, getZIndex } from './theme';
import { createMixin } from './mixins';
import type { CreateMixinType } from './mixins';
import type { BoxProps } from '@yoweb/ui/components/Box';

// Get style value
export const getSpaceValue = (value: number | keyof Space) =>
  typeof value === 'number' ? pxToRem(value) : getSpace(value) || value;

export const getZIndexValue = (value: number | keyof ZIndices) =>
  typeof value === 'number' ? value : getZIndex(value) || value;

/** Used to fake padding */
export type Offset = CreateMixinType<'offset', number | keyof Space>;
export const offsetMixin = createMixin('offset')<number | keyof Space, Offset>(({ offset }, css) =>
  offset
    ? css`
        margin-left: -${getSpaceValue(offset)};
        margin-right: -${getSpaceValue(offset)};
        padding-right: ${getSpaceValue(offset)};
        padding-left: ${getSpaceValue(offset)};
      `
    : css``,
);

export type Units =
  | `${number}vw` // vw	Relative to 1% of the width of the viewport*
  | `${number}vh` // vh	Relative to 1% of the height of the viewport*
  | `${number}vmin` // vmin	Relative to 1% of viewport's* smaller dimension
  | `${number}vmax` // vmax	Relative to 1% of viewport's* larger dimension
  | `${number}%`; // Relative to the parent element

export type BoxValue = keyof Space | number | 'auto' | Units;

export type GridProps = BoxProps &
  CreateMixinType<'gridColumn', string> &
  CreateMixinType<'gridRow', string>;

export const setBoxValue = (value: BoxValue): string | undefined => {
  if (value === 'auto' || isUnitValue(value as string)) {
    return value as string;
  }

  return isNumber(value) ? pxToRem(value) : (getSpace(value as keyof Space) as unknown as string);
};

export type FlexProps = CreateMixinType<'display', CSS.Property.Display> &
  CreateMixinType<'alignItems', CSS.Property.AlignItems> &
  CreateMixinType<'alignContent', CSS.Property.AlignContent> &
  CreateMixinType<'alignSelf', CSS.Property.AlignSelf> &
  CreateMixinType<'justifyContent', CSS.Property.JustifyContent> &
  CreateMixinType<'justifyItems', CSS.Property.JustifyItems> &
  CreateMixinType<'justifySelf', CSS.Property.JustifySelf> &
  CreateMixinType<'flexDirection', CSS.Property.FlexDirection> &
  CreateMixinType<'flexWrap', CSS.Property.FlexWrap> &
  CreateMixinType<'flexGrow', CSS.Property.FlexGrow> &
  CreateMixinType<'order', CSS.Property.Order> &
  CreateMixinType<'gap', BoxValue> &
  CreateMixinType<'rowGap', BoxValue> &
  CreateMixinType<'columnGap', BoxValue> &
  CreateMixinType<'flex', CSS.Property.Flex>;

export const displayMixin = createMixin('display')<CSS.Property.Display, FlexProps>(
  ({ display = 'flex' }, css) => css`
    display: ${display};
  `,
);

export const justifyMixin = createMixin('justifyContent')<CSS.Property.JustifyContent, FlexProps>(
  ({ justifyContent = 'flex-start' }, css) => css`
    justify-content: ${justifyContent};
  `,
);

export const justifyItemsMixin = createMixin('justifyItems')<CSS.Property.JustifyItems, FlexProps>(
  ({ justifyItems }, css) =>
    justifyItems
      ? css`
          justify-items: ${justifyItems};
        `
      : css``,
);

export const justifySelfMixin = createMixin('justifySelf')<CSS.Property.JustifySelf, FlexProps>(
  ({ justifySelf }, css) =>
    justifySelf
      ? css`
          justify-self: ${justifySelf};
        `
      : css``,
);

export const alignItemsMixin = createMixin('alignItems')<CSS.Property.AlignItems, FlexProps>(
  ({ alignItems }, css) =>
    alignItems
      ? css`
          align-items: ${alignItems};
        `
      : css``,
);

export const alignSelfMixin = createMixin('alignSelf')<CSS.Property.AlignSelf, FlexProps>(
  ({ alignSelf }, css) =>
    alignSelf
      ? css`
          align-self: ${alignSelf};
        `
      : css``,
);

export const alignContentMixin = createMixin('alignContent')<CSS.Property.AlignContent, FlexProps>(
  ({ alignContent }, css) =>
    alignContent
      ? css`
          align-content: ${alignContent};
        `
      : css``,
);

export const directionMixin = createMixin('flexDirection')<CSS.Property.FlexDirection, FlexProps>(
  ({ flexDirection = 'row' }, css) => css`
    flex-direction: ${flexDirection};
  `,
);

export const wrapMixin = createMixin('flexWrap')<CSS.Property.FlexWrap, FlexProps>(
  ({ flexWrap }, css) =>
    flexWrap
      ? css`
          flex-wrap: ${flexWrap};
        `
      : css``,
);

export const growMixin = createMixin('flexGrow')<CSS.Property.FlexGrow, FlexProps>(
  ({ flexGrow }, css) =>
    flexGrow
      ? css`
          flex-grow: ${flexGrow};
        `
      : css``,
);

export const orderMixin = createMixin('order')<CSS.Property.Order, FlexProps>(({ order }, css) =>
  order
    ? css`
        order: ${order};
      `
    : css``,
);

export const flexMixin = createMixin('flex')<CSS.Property.Flex, FlexProps>(({ flex }, css) =>
  flex
    ? css`
        flex: ${flex};
      `
    : css``,
);

export const gapMixin = createMixin('gap')<BoxValue, FlexProps>(({ gap }, css) =>
  gap
    ? css`
        gap: ${setBoxValue(gap)};
      `
    : css``,
);

export const rowGapMixin = createMixin('rowGap')<BoxValue, FlexProps>(({ rowGap }, css) =>
  rowGap
    ? css`
        row-gap: ${setBoxValue(rowGap)};
      `
    : css``,
);

export const columnGapMixin = createMixin('columnGap')<BoxValue, FlexProps>(({ columnGap }, css) =>
  columnGap
    ? css`
        column-gap: ${setBoxValue(columnGap)};
      `
    : css``,
);

export const gridColumnMixin = createMixin('gridColumn')<string, GridProps>(
  ({ gridColumn }, css) =>
    gridColumn
      ? css`
          grid-column: ${gridColumn};
        `
      : css``,
);

export const gridRowMixin = createMixin('gridRow')<string, GridProps>(({ gridRow }, css) =>
  gridRow
    ? css`
        grid-row: ${gridRow};
      `
    : css``,
);

export type Width = CreateMixinType<'width', number | keyof Space>;
export const widthMixin = createMixin('width')<number | keyof Space, Width>(({ width }, css) =>
  width
    ? css`
        width: ${getSpaceValue(width)};
      `
    : css``,
);

export type MaxWidth = CreateMixinType<'maxWidth', number | keyof Space>;
export const maxWidthMixin = createMixin('maxWidth')<number | keyof Space, MaxWidth>(
  ({ maxWidth }, css) =>
    maxWidth
      ? css`
          max-width: ${getSpaceValue(maxWidth)};
        `
      : css``,
);

export type MaxHeight = CreateMixinType<'maxHeight', number | keyof Space>;
export const maxHeightMixin = createMixin('maxHeight')<number | keyof Space, MaxHeight>(
  ({ maxHeight }, css) =>
    maxHeight
      ? css`
          max-height: ${getSpaceValue(maxHeight)};
        `
      : css``,
);

export type MinWidth = CreateMixinType<'minWidth', number | keyof Space>;
export const minWidthMixin = createMixin('minWidth')<number | keyof Space, MinWidth>(
  ({ minWidth }, css) =>
    minWidth
      ? css`
          min-width: ${getSpaceValue(minWidth)};
        `
      : css``,
);

export type MinHeight = CreateMixinType<'minHeight', number | keyof Space>;
export const minHeightMixin = createMixin('minHeight')<number | keyof Space, MinHeight>(
  ({ minHeight }, css) =>
    minHeight
      ? css`
          min-height: ${getSpaceValue(minHeight)};
        `
      : css``,
);

export type TextAlignProps = CreateMixinType<'textAlign', CSS.Property.TextAlign>;
export const textAlignMixin = createMixin('textAlign')<CSS.Property.TextAlign, TextAlignProps>(
  ({ textAlign }, css) =>
    textAlign
      ? css`
          text-align: ${textAlign};
        `
      : css``,
);

export type ZIndexProps = CreateMixinType<'zIndex', number | keyof ZIndices>;
export const zIndexMixin = createMixin('zIndex')<number | keyof ZIndices, ZIndexProps>(
  ({ zIndex }, css) =>
    zIndex
      ? css`
          z-index: ${getZIndexValue(zIndex)};
        `
      : css``,
);

export type VisibilityProps = CreateMixinType<'visibility', CSS.Property.Visibility>;
export const visibilityMixin = createMixin('visibility')<CSS.Property.Visibility, VisibilityProps>(
  ({ visibility }, css) =>
    visibility
      ? css`
          visibility: ${visibility};
        `
      : css``,
);

export type OverflowProps = CreateMixinType<'overflow', CSS.Property.Overflow>;
export const overflowMixin = createMixin('overflow')<CSS.Property.Overflow, OverflowProps>(
  ({ overflow }, css) =>
    overflow
      ? css`
          overflow: ${overflow};
        `
      : css``,
);

export type InsetProps = CreateMixinType<'inset', CSS.Property.Inset>;
export const insetMixin = createMixin('inset')<CSS.Property.Inset, InsetProps>(({ inset }, css) =>
  inset || inset === 0
    ? css`
        inset: ${inset};
      `
    : css``,
);

export type OverflowWrapProps = CreateMixinType<'overflowWrap', CSS.Property.OverflowWrap>;
export const overflowWrapMixin = createMixin('overflowWrap')<
  CSS.Property.OverflowWrap,
  OverflowWrapProps
>(({ overflowWrap }, css) =>
  overflowWrap
    ? css`
        overflow-wrap: ${overflowWrap};
      `
    : css``,
);

/** A mixin to hide elements visually but still be read by screen readers */
export const visuallyHiddenMixin = scCss`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
