import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const PaymentListIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 24 24" {...props}>
    <path
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
      d="M5 3.75C4.86221 3.75 4.75 3.86221 4.75 4V18.7874L6.33145 17.9962C6.54266 17.8906 6.7913 17.8906 7.00252 17.9962L9.3335 19.1622L11.6645 17.9962C11.8756 17.8906 12.1241 17.8906 12.3353 17.9961L14.6665 19.1612L16.9975 17.9952C17.2087 17.8896 17.4573 17.8896 17.6685 17.9952L19.25 18.7864V4C19.25 3.86221 19.1378 3.75 19 3.75H5ZM3.25 4C3.25 3.03379 4.03379 2.25 5 2.25H19C19.9662 2.25 20.75 3.03379 20.75 4V19.191C20.75 20.1227 19.7708 20.7227 18.9414 20.3092L18.9405 20.3088L17.333 19.5046L15.2255 20.5588L15.2235 20.5598C14.8724 20.7341 14.4606 20.7341 14.1095 20.5598L14.1077 20.5589L12.0001 19.5055L9.89252 20.5598L9.8905 20.5608C9.5394 20.7351 9.1276 20.7351 8.7765 20.5608L8.77448 20.5598L6.66702 19.5056L5.05955 20.3098L5.05699 20.311C4.22866 20.7215 3.25 20.1229 3.25 19.191V4ZM8 6.25C7.58579 6.25 7.25 6.58579 7.25 7C7.25 7.41421 7.58579 7.75 8 7.75H16C16.4142 7.75 16.75 7.41421 16.75 7C16.75 6.58579 16.4142 6.25 16 6.25H8ZM8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H16.13C16.5442 11.75 16.88 11.4142 16.88 11C16.88 10.5858 16.5442 10.25 16.13 10.25H8ZM8 14.25C7.58579 14.25 7.25 14.5858 7.25 15C7.25 15.4142 7.58579 15.75 8 15.75H13C13.4142 15.75 13.75 15.4142 13.75 15C13.75 14.5858 13.4142 14.25 13 14.25H8Z"
    />
  </IconBase>
);

export default PaymentListIcon;
