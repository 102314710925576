import type { NextPageContext } from 'next';
import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';
import type { AppContextType } from 'next/dist/shared/lib/utils';

import { capitalizeFirstLetter } from '@yoweb/utils/capitalizeFirstLetter';
import { isServerRendered } from '@yoweb/utils/isServerRendered';

export interface NextPageServerContext extends NextPageContext {
  res: Required<NextPageContext>['res'];
  req: Required<NextPageContext>['req'];
}

function readShoeboxStateFromDomElement<State>(shoeboxElementId: string): State {
  const shoebowEl = document.getElementById(shoeboxElementId);

  if (!shoebowEl) {
    throw new Error(`Missing shoebox element: "#${shoeboxElementId}"`);
  }

  if (typeof shoebowEl.textContent !== 'string') {
    throw new Error(`Shoebox element value was never set.`);
  }

  return JSON.parse(shoebowEl.textContent) as State;
}

export function shoeboxFactory<State>(name: string) {
  const requestCache = new WeakMap();
  const capitalizedName = capitalizeFirstLetter(name);
  const ScopedShoeboxContext = createContext<State | undefined>(undefined);
  const shoeboxElementId = `${name}-state`;

  const Element = ({ state, nonce }: { state: State; nonce: string }) => (
    <script
      nonce={nonce}
      id={shoeboxElementId}
      type="application/json"
      dangerouslySetInnerHTML={{
        __html: `${JSON.stringify(state)}`,
      }}
    />
  );

  const useShoebox = (): Readonly<State> => {
    const state = useContext<State | undefined>(ScopedShoeboxContext);

    if (!state) {
      throw new Error(
        `Missing ${capitalizedName} state. Ensure "${capitalizedName}Context.Provider" is setup properly.`,
      );
    }

    return state;
  };

  const Provider = ({ children, value }: { value: State; children: ReactNode }) => (
    <ScopedShoeboxContext.Provider value={value}>{children}</ScopedShoeboxContext.Provider>
  );

  function createShoeboxStateWrapper<T>(callback: (ctx: NextPageServerContext) => T) {
    return (ctx?: AppContextType['ctx']) => {
      if (!isServerRendered || !ctx) {
        // Only accessed on statically rendered pages (404 / 500 / error boundary pages).
        // This is because the error pages do not always render on the server.
        // This is only when back / forward buttons navigate back to a 400 / 500 page.
        // Track: https://github.com/vercel/next.js/discussions/17004
        return readShoeboxStateFromDomElement<T>(shoeboxElementId);
      }

      // Optimization for calling createShoeboxStateWrapper()'s callback
      // multiple times on the same request.  This is a common scenario now that
      // _app and _document both call this method on the server.
      if (ctx?.req && requestCache.has(ctx.req)) {
        return requestCache.get(ctx.req) as T;
      }

      const result = callback(ctx as NextPageServerContext);

      if (ctx?.req) {
        requestCache.set(ctx.req, result);
      }

      return result;
    };
  }

  return {
    useShoebox,
    createShoeboxStateWrapper,
    Provider,
    Element,
  };
}
