import { useEffect } from 'react';

import scroll, { type ScrollHandler } from '@yoweb/utils/scroll';

/**
 * Run scroll handler on next animation frame tick.
 * @param callback - scroll handler with cross-browser scroll position
 */
export function useScrollHandler(callback: ScrollHandler): typeof scroll {
  useEffect(() => scroll.addScrollHandler(callback), [callback]);

  return scroll;
}

export default useScrollHandler;
