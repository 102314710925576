import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const MenuIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 5A.75.75 0 0 1 3 4.25h18a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 5Zm0 7a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75ZM3 18.25a.75.75 0 0 0 0 1.5h18a.75.75 0 0 0 0-1.5H3Z"
      fill="currentColor"
    />
  </IconBase>
);

export default MenuIcon;
