import { useState } from 'react';

type ImmutableArray<T> = {
  list: T[];

  /**
   * Update a single item in the array.
   * @param item - The item we want to replace. This will replace the item, not modify it.
   * @param key - optional override for the comparison key.
   */
  updateItem(item: T, key?: keyof T, addIfNotFound?: boolean): void;

  /**
   * Remove an item from the array.
   *
   * @param item - The item we want to remove.
   * @param key - optional override for the comparison key.
   */

  removeItem(item: Partial<T>, key?: keyof T): void;
  /**
   * Adds an item to the array.
   *
   * @param item - The item we want to add.
   */
  addItem(item: T): void;

  /**
   * Add items to the array.
   *
   * @param items - The items we want to add.
   */
  addItems(items: T[]): void;

  /**
   * Replace items in the array.
   *
   * @param items - The items we want to add.
   */
  replace(items: T[]): void;
};

/**
 * Immutable Array hook for displaying a list of data and rerender upon item change.
 *
 * @param defaultValue - the initial state of the array.
 * @param defaultKey - the key we base comparisons on, e.g. "id".
 */
export const useImmutableArray = <T>(defaultValue: T[], defaultKey: keyof T): ImmutableArray<T> => {
  const [list, setList] = useState(defaultValue);

  const updateItem = (item: T, key = defaultKey, addIfNotFound = false) => {
    const index = list.findIndex((x) => x[key] === item[key]);

    if (index === -1 && !addIfNotFound) {
      return;
    } else if (index === -1 && addIfNotFound) {
      setList((list) => [...list, item]);
      return;
    }

    setList((list) => [...list.slice(0, index), item, ...list.slice(index + 1)]);
  };

  const removeItem = (item: T, key = defaultKey) => {
    setList((list) => list.filter((x) => x[key] !== item[key]));
  };

  const addItem = (item: T) => {
    setList((list) => [...list, item]);
  };

  const addItems = (items: T[]) => {
    setList((list) => [...list, ...items]);
  };

  return {
    list,
    replace: setList,
    updateItem,
    removeItem,
    addItem,
    addItems,
  };
};

export default useImmutableArray;
