import { useRouter } from 'next/router';
import { useExternalConfig } from '@store/contexts/ExternalConfig';
import { useBraze } from '@yoweb/ui/hooks/useBraze';
import { useUser } from '@store/hooks/useUser';
export var useStoreBraze = function useStoreBraze() {
  var _useExternalConfig = useExternalConfig(),
      env = _useExternalConfig.env,
      _useExternalConfig$br = _useExternalConfig.braze,
      apiKey = _useExternalConfig$br.apiKey,
      baseUrl = _useExternalConfig$br.baseUrl;

  var router = useRouter();

  var _useUser = useUser(),
      profile = _useUser.profile; // Note: we disable braze in our CI environment but if you want to enable it
  // in order to write a Braze test using a mocked response pass in ?forceBraze=true


  var enabled = env !== 'test' || router.query.forceBraze === 'true';
  useBraze(enabled, apiKey, baseUrl, profile === null || profile === void 0 ? void 0 : profile.id);
};