import type * as clientSdk from '@sentry/nextjs/types/client';

import { beforeBreadcrumb } from '@yoweb/utils/sentry';

export const createClientSentryConfig = (
  tracesSampleRate = 0,
  tracePropagationTargets = [],
): clientSdk.BrowserOptions => {
  // Safe to use NEXT_PUBLIC_* here because the value is the same between all realms
  const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
  const release = process.env.NEXT_PUBLIC_APP_VERSION;
  const enabled = !!(process.env.NEXT_PUBLIC_SENTRY_DISABLED !== 'true' && dsn);

  const config: clientSdk.BrowserOptions = {
    dsn,
    enabled,
    release,
    maxBreadcrumbs: 15,
    tracesSampleRate,
    tracePropagationTargets,
    // Accesses the value set on every page request which passes this runtime
    // server state to the client. See: _document.tsx
    environment:
      document.querySelector('meta[name="environment"]')?.getAttribute('content') ?? 'development',
    beforeBreadcrumb,
  };

  return config;
};
