/**
 * Checks if string is a valid number with a valid unit value (vw, vh, vmin, vmax, %)
 * vw:	Relative to 1% of the width of the viewport*
 * vh:	Relative to 1% of the height of the viewport*
 * vmin:	Relative to 1% of viewport's* smaller dimension
 * vmax:	Relative to 1% of viewport's* larger dimension
 * %: Relative to the parent element
 */
export const isUnitValue = (value: string): boolean =>
  /^[-]?([0-9]*[.])?[0-9]+(%|vw|vh|vmin|vmax)$/g.test(value);
