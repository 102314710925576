import { forwardRef, type Ref } from 'react';
import styled, { css, type FontWeights } from 'styled-components';

import { pxToRem } from '@yoweb/ui/styles/utils';
import { th } from '@yoweb/ui/styles/utils/theme';
import { Interactive } from '@yoweb/ui/components/typography';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';
import { buttonBase, type ButtonBaseProps } from '@yoweb/ui/components/buttons/Button';

export type ButtonSize = 'sm' | 'md';

type ButtonNavProps = ButtonBaseProps & {
  text?: string;
  isActive?: boolean;
  weight?: 'default' | 'bold';
} & WithSize<ButtonSize> &
  DataTestId;

export const ButtonNav = forwardRef(
  (
    {
      children,
      isActive = false,
      isDisabled = false,
      text = '',
      size = 'md',
      weight = 'bold',
      ...props
    }: ButtonNavProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const fontWeight: keyof FontWeights = weight === 'bold' ? 'bold' : 'semibold';
    return (
      // @ts-ignore TODO improve typing
      <StyledButton size={size} disabled={isDisabled} ref={ref} isActive={isActive} {...props}>
        <Interactive size="md" weight={fontWeight} as="span">
          {text || children}
        </Interactive>
      </StyledButton>
    );
  },
);

ButtonNav.displayName = 'ButtonNav';

const getSize = createSizeMixin<ButtonSize, ButtonNavProps>(({ size = 'md' }, tagFn) => {
  switch (size) {
    case 'md':
      return tagFn`
        height: ${th.getSpace('normal4')};
        padding: ${th.getSpace('small4')} ${pxToRem(20)};
      `;
    case 'sm':
      return tagFn`
        height: ${th.getSpace('normal3')};
        padding: ${th.getSpace('small2')} ${th.getSpace('normal1')};
      `;
  }
});

const StyledButton = styled.a<ButtonNavProps>`
  ${buttonBase};
  ${getSize};

  box-sizing: border-box;
  border-radius: ${th.getRadii('large')};
  border: none;
  justify-self: start;
  white-space: nowrap;

  ${({ isActive }) =>
    isActive
      ? css`
          color: ${th.getColor('base900')};
        `
      : css`
          color: ${th.getColor('base500')};
        `}

  &:hover {
    background-color: ${th.getColor('baseA050')};
  }

  &:disabled {
    color: ${th.getColor('base200')};
  }

  &:focus-visible {
    outline: none;

    &::after {
      position: absolute;
      content: '';
      inset: -1px;
      border-radius: ${th.getRadii('full')};
      border: 2px solid;
      border-color: ${th.getColor('baseA700')};
    }
  }
`;
