import styled from 'styled-components';

export const LineClamp = styled.span<{ lines: number }>`
  && {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: ${({ lines }) => lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
