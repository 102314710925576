import styled from 'styled-components';
import { motion } from 'framer-motion';

import { getZIndex } from '@yoweb/ui/styles/utils/theme';

const ToastContainer = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: ${getZIndex('toast')};
`;

export const TopMiddleToastContainer = styled(ToastContainer)`
  && {
    top: 0;
  }
`;

export const BottomRightToastContainer = styled(ToastContainer)`
  && {
    bottom: 0;
    display: flex;
    align-items: end;
  }
`;
