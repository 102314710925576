import type { Config, Mixpanel, RequestOptions } from 'mixpanel-browser';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

import { isServerRendered } from '@yoweb/utils/isServerRendered';
import { captureException } from '../sentry';
import { QUERY_DEBUG_MIXPANEL } from '@yoweb/utils/constants/queryParams';
import { COOKIE_MIXPANEL_ENABLED, COOKIE_MIXPANEL_UUID } from '@yoweb/utils/constants/cookies';
import {
  ExternalEventToEventName,
  calculateCookieDomain,
  isBot,
} from '@yoweb/utils/analytics/mixpanel-helpers';
import type { WrappedExternalEvent } from '@yoweb/types/external-events';

export type MixpanelBrowserConfig = {
  token: string | undefined;
  cookieDomain: string | undefined;
  proxyUrl: string | undefined;
  requireCookie: boolean | undefined;
  batchFlushInterval?: number;
};

let cookieRequiredToRun = false;
let isInitializing = false;
let maybeMixpanel: Mixpanel | null = null;
let queue: (() => void)[] = [];

// Note: do not use this outside of unit testing
export const __reset = () => {
  queue = [];
  isInitializing = false;
  cookieRequiredToRun = false;
  maybeMixpanel = null;
};

const loaded: Config['loaded'] = (mixpanel) => {
  maybeMixpanel = mixpanel;

  if (isBot(navigator.userAgent)) {
    mixpanel.register({ $ignore: true });
  }

  const trackingId = parseCookies(null)?.[COOKIE_MIXPANEL_UUID];

  if (trackingId) {
    mixpanel.register({ $device_id: trackingId, distinct_id: trackingId });
  } else {
    // Note: ensure `parseDistinctId(req, res)` is called server side before the mixpanel client is initialized!
    // This is currently happening as a side-effect of feature flagging on the server but if this warning is reached then
    // that behavior changed and requires calling `parseDistinctId` explicitly.
    // eslint-disable-next-line no-console
    console.warn(
      'Mixpanel tracking ID was not setup, this is unexpected and will result in missed events.',
    );
  }

  mixpanel.register({
    'App Version': process.env.NEXT_PUBLIC_APP_VERSION,
    version: 'v2',
    platform: 'Web',
  });

  const promises = queue.map((fn) => fn());

  void Promise.all(promises).finally(() => {
    isInitializing = false;
    queue = [];
  });
};

export async function init(config: MixpanelBrowserConfig) {
  if (!config.token) {
    throw new Error('Missing mixpanel token');
  }

  if (isServerRendered || isInitializing || maybeMixpanel) {
    return;
  }

  cookieRequiredToRun = config.requireCookie ?? false;
  isInitializing = true;

  const debug =
    new URLSearchParams(location.search).get(QUERY_DEBUG_MIXPANEL)?.toLowerCase() === 'true';
  // a side-effect of adding the debug flag is that we set a 'mixpanel-enabled' cookie. This ensures
  // that events will fire in an environment where they are off by default.
  if (cookieRequiredToRun && debug) {
    setCookie(null, COOKIE_MIXPANEL_ENABLED, 'true', {
      domain: calculateCookieDomain(config.cookieDomain, location.host),
      httpOnly: false,
      path: '/',
      sameSite: 'lax',
      secure: true,
    });
  }

  (await import('mixpanel-browser')).init(config.token, {
    debug, // 'true' means events will be output in browser console log
    persistence: 'localStorage',
    api_host: config.proxyUrl,
    batch_flush_interval_ms: debug ? 0 : config.batchFlushInterval,
    // "Do Not Track (DNT) signals, our systems currently do not respond to DNT signals from browsers visiting our websites"
    // Source: https://www.yohana.com/privacy-policy
    ignore_dnt: true,
    loaded,
  });
}

// Will only execute a wrapped mixpanel function if mixpanel is "enabled" AND the client has been initialized.
// If the client has not yet been initialized, it will queue up the event to be dequeued after loading.
function guardEventing<T extends (...args: Parameters<T>) => ReturnType<T>>(callback: T) {
  return (...args: Parameters<T>) => {
    if (!isEnabled()) {
      return;
    }

    if (!maybeMixpanel) {
      queue.push(() => callback(...args));
      return;
    }

    return callback(...args);
  };
}

export const loginEvent = guardEventing((userId: string) => {
  try {
    maybeMixpanel?.identify(userId);
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch (error) {
    captureException(error);
  }
});

export const logoutEvent = guardEventing((domain: string | undefined) => {
  try {
    // Note: if you change this then change packages/utils/lib/server/analytics/mixpanel.ts
    destroyCookie(null, COOKIE_MIXPANEL_UUID, {
      // if we are not on the expected hostname then use `undefined` for domain - otherwise cookie won't be deleted
      // this is possible locally and for yolabs.io traffic which our testing infrastructure connects to
      domain: calculateCookieDomain(domain, location.hostname),
      path: '/',
    });

    maybeMixpanel?.reset();
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch (error) {
    captureException(error);
  }
});

// Calling timeEvent requires calling trackEvent() with the same event name when you want to publish the event (duration)
export const timeEvent = guardEventing((internalEventName: keyof WrappedExternalEvent) => {
  try {
    maybeMixpanel?.time_event(ExternalEventToEventName[internalEventName]);
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch (error) {
    captureException(error);
  }
});

/**
 * Record an event to mixpanel.
 * Note that external events are for us and internal events are strictly
 * for sycamore usage.
 */
export const trackEvent = guardEventing(
  (
    externalEventPayload: WrappedExternalEvent | keyof WrappedExternalEvent,
    optionsOrCallback?: RequestOptions,
  ) => {
    try {
      if (typeof externalEventPayload === 'string') {
        maybeMixpanel?.track(
          ExternalEventToEventName[externalEventPayload],
          undefined,
          optionsOrCallback,
        );
      } else {
        const internalEventName = Object.keys(externalEventPayload).filter(
          (e) => externalEventPayload[e as keyof WrappedExternalEvent],
        )[0] as keyof WrappedExternalEvent;
        maybeMixpanel?.track(
          ExternalEventToEventName[internalEventName],
          externalEventPayload[internalEventName],
          optionsOrCallback,
        );
      }
      // Intentionally swallowing exceptions, so that mixpanel failures don't
      // impact the normal app flow
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error) {
      captureException(error);
    }
  },
);

function isEnabled(): boolean {
  if (cookieRequiredToRun) {
    return parseCookies()?.[COOKIE_MIXPANEL_ENABLED] !== undefined;
  }

  return true;
}
