import { useEffect, useState } from 'react';
import type { Transition } from 'framer-motion';

import theme from '@yoweb/ui/styles/theme';

export type CollapsibleProps = {
  id: string;
  isCollapsed?: boolean;
  onToggled?: (isCollapsed: boolean) => void;
  transitions?: Transition;
};

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

const defaultTransitions = {
  duration: theme.transitionDuration.short / 1000,
  ease: theme.transitionPoints.easeInOut,
};

/** Hook to create WAI-ARIA compliant React components that collapse and expand content. */
export const useCollapsible = ({ id, isCollapsed, onToggled, transitions }: CollapsibleProps) => {
  const [isClosed, setIsClosed] = useState(isCollapsed === undefined ? true : isCollapsed);

  const toggle = () => {
    setIsClosed((prevState) => !prevState);

    if (typeof onToggled === 'function') {
      onToggled(isClosed);
    }
  };

  useEffect(() => {
    if (isCollapsed !== undefined) {
      setIsClosed(isCollapsed);
    }
  }, [isCollapsed]);

  return {
    toggle,
    isClosed,
    controlProps: {
      onClick: toggle,
      'aria-expanded': !isClosed,
      'aria-controls': id,
    },
    contentProps: {
      id,
      'aria-hidden': isClosed,
      variants,
      transition: transitions || defaultTransitions,
      initial: 'collapsed',
      animate: 'open',
      exit: 'collapsed',
    },
  };
};
