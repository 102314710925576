import { ApiError } from '@yoweb/utils/common';
import { defaultHeaders } from '@yoweb/utils/defaultHeaders';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createApiError = (data: any, res: Response) =>
  new ApiError(
    data?.message ?? `Unexpected server error (${res.status} ${res.url})`,
    res.status,
    res.statusText,
    res.body,
  );

export const fetchJson = async <T>(
  url: RequestInfo,
  { headers, ...configRest }: RequestInit = {},
): Promise<T> => {
  const res = await fetch(url, { headers: { ...defaultHeaders, ...headers }, ...configRest });
  let data;

  try {
    data = await res.json();
  } catch {
    // returns data as null
  }

  if (!res.ok) {
    throw createApiError(data, res);
  }

  return data;
};
