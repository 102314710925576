import styled, { css } from 'styled-components';
import type * as CSS from 'csstype';
import type { ElementType, ForwardedRef, ReactNode } from 'react';

import { Box, type BoxProps } from '@yoweb/ui/components/Box';
import { getSize, getSpace } from '@yoweb/ui/styles/utils/theme';
import { gridBase, media, pxToRem } from '@yoweb/ui/styles/utils';

type ContainerProps = {
  as?: ElementType;

  /** center the container content. */
  centered?: boolean;

  className?: string;

  /** center the container content. */
  textAlign?: CSS.Property.TextAlign;

  /** children elements. */
  children?: ReactNode;

  /** DOM id */
  id?: string;

  /** Fill tablet width */
  fillTabletWidth?: boolean;

  /** Override max-width */
  maxWidth?: number;

  narrow?: boolean;

  /** Background color */
  backgroundColor?: BoxProps['backgroundColor'];

  /** Add design system grid styles to container  */
  withGrid?: boolean;

  /** Make container take up all viewport width on mobile, default true */
  withMobileMargin?: boolean;

  ref?: ForwardedRef<HTMLDivElement>;
};

/**
 * Container with limited width on large screens, and full on small screens.
 */
export const Container = ({ as, backgroundColor, ...rest }: ContainerProps) => (
  <Box display="flex" justifyContent="center" width="100%" backgroundColor={backgroundColor}>
    <StyledContainer forwardedAs={as} {...rest} />
  </Box>
);

const StyledContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 ${({ withMobileMargin = true }) => (withMobileMargin ? getSpace('normal2') : '0')};
  position: relative;
  width: 100%;

  ${media.md`
    margin: 0 ${getSpace('normal2')};
  `}

  ${({ withGrid }) =>
    withGrid &&
    css`
      ${gridBase};
    `}

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ maxWidth, fillTabletWidth, narrow }) => {
    if (narrow) {
      return css`
        max-width: ${getSize('containerWidthNarrow')};
      `;
    }

    if (maxWidth) {
      return css`
        max-width: ${pxToRem(maxWidth)};
      `;
    }

    if (fillTabletWidth) {
      return css`
        max-width: ${getSize('containerWidth')};
      `;
    }

    return css`
      max-width: ${getSize('containerWidthTablet')};

      ${media.lg`
            max-width: ${getSize('containerWidth')};
          `}
    `;
  }};

  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

export default Container;
