import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const CreditCardsIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 24 24" {...props}>
    <path
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
      d="M5 5.75C4.30921 5.75 3.75 6.30921 3.75 7V14C3.75 14.6908 4.30921 15.25 5 15.25H5.25V12V10C5.25 8.48079 6.48079 7.25 8 7.25H17.25V7C17.25 6.30921 16.6908 5.75 16 5.75H5ZM5 16.75H5.25V17C5.25 18.5192 6.48079 19.75 8 19.75H19C20.5192 19.75 21.75 18.5192 21.75 17V12V10C21.75 8.48079 20.5192 7.25 19 7.25H18.75V7C18.75 5.48079 17.5192 4.25 16 4.25H5C3.48079 4.25 2.25 5.48079 2.25 7V14C2.25 15.5192 3.48079 16.75 5 16.75ZM8 8.75H18H19C19.6908 8.75 20.25 9.30921 20.25 10V11.25H6.75V10C6.75 9.30921 7.30921 8.75 8 8.75ZM20.25 12.75V17C20.25 17.6908 19.6908 18.25 19 18.25H8C7.30921 18.25 6.75 17.6908 6.75 17V16V12.75H20.25ZM9 15.25C8.58579 15.25 8.25 15.5858 8.25 16C8.25 16.4142 8.58579 16.75 9 16.75H12C12.4142 16.75 12.75 16.4142 12.75 16C12.75 15.5858 12.4142 15.25 12 15.25H9Z"
    />
  </IconBase>
);

export default CreditCardsIcon;
