import type {
  Colors,
  FontSizes,
  DefaultTheme,
  FontFamily,
  LineHeights,
  LetterSpacing,
  FontWeights,
  Space,
  ZIndices,
  Radii,
  Sizes,
  Shadows,
  IconSizes,
  Duration,
  TransitionPoints,
} from 'styled-components';

type Props = {
  theme: DefaultTheme;
};

// Individual property selectors.
export const getColor =
  (color: keyof Colors) =>
  (props: Props): string =>
    props?.theme?.colors?.[color];

export const getFontSize =
  (fontSize: keyof FontSizes) =>
  (props: Props): string =>
    props?.theme?.fontSizes?.[fontSize];

export const getLineHeight =
  (lineHeight: keyof LineHeights) =>
  (props: Props): number | string =>
    props?.theme?.lineHeights?.[lineHeight];

export const getFontFamily =
  (family: keyof FontFamily) =>
  (props: Props): string =>
    props.theme.fontFamily[family];

export const getLetterSpacing =
  (space: keyof LetterSpacing) =>
  (props: Props): string =>
    props.theme.letterSpacing[space];

export const getFontWeight =
  (weight: keyof FontWeights) =>
  (props: Props): number =>
    props.theme.fontWeights[weight];

export const getSpace =
  (spaceKey: keyof Space) =>
  (props: Props): string | undefined =>
    props.theme.space[spaceKey];

export const getZIndex =
  (zIndexKey: keyof ZIndices) =>
  (props: Props): number =>
    props.theme.zIndices[zIndexKey];

export const getRadii =
  (radiiKey: keyof Radii) =>
  (props: Props): string =>
    props.theme.radii[radiiKey];

export const getSize =
  (sizeKey: keyof Sizes) =>
  (props: Props): string =>
    props.theme.sizes[sizeKey];

export const getShadow =
  (shadowKey: keyof Shadows) =>
  (props: Props): string =>
    props.theme.shadows[shadowKey];

export const getIconSize =
  (sizeKey: keyof IconSizes) =>
  (props: Props): string =>
    props.theme.iconSizes[sizeKey];

export const getDuration =
  (durationKey: keyof Duration) =>
  (props: Props): number =>
    props.theme.transitionDuration[durationKey];

export const getTransition =
  (transitionPointsKey: keyof TransitionPoints) =>
  (props: Props): string =>
    `cubic-bezier(${props.theme.transitionPoints[transitionPointsKey].join(',')})`;

/** Single helper to access all other helpers via a simple interface. */
export const th = {
  getColor,
  getFontSize,
  getLineHeight,
  getFontFamily,
  getLetterSpacing,
  getFontWeight,
  getSpace,
  getZIndex,
  getRadii,
  getSize,
  getShadow,
  getIconSize,
  getDuration,
  getTransition,
};
