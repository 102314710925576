import { motion, AnimatePresence } from 'framer-motion';
import styled, { css } from 'styled-components';
import type { ReactNode } from 'react';
import type * as CSS from 'csstype';

import { th } from '@yoweb/ui/styles/utils/theme';
import { media, type ScreenSize } from '@yoweb/ui/styles/utils';
import { CaretDownIcon } from '@yoweb/ui/components/Icon/CaretDownIcon';
import {
  offsetMixin,
  type Offset,
  alignItemsMixin,
  type FlexProps,
} from '@yoweb/ui/styles/utils/mixins.utils';
import { useCollapsible } from '@yoweb/ui/hooks/useCollapsible';

type Mode = 'dark' | 'light';

export type CollapsibleProps = {
  id: string;
  /** header element that will expand content on click */
  header?: ReactNode;
  /** content to display when expanded */
  children: ReactNode;
  /** controls whether the component is collapsed */
  isCollapsed?: boolean;
  /** event that fires once the component has collapsed */
  onToggled?(isCollapsed: boolean): void;
  /** shows the caret next to the header in a muted color */
  muted?: boolean;
  /** controls caret placement */
  compact?: boolean;
  /** dark/light mode */
  mode?: Mode;
  /** whether the caret should be to the left of the header */
  leftAligned?: boolean;
  /** Aria label for the toggle control */
  ariaLabel?: string;
  /* auto resizes the caret to 24 on desktop, 18 on tablet and below */
  responsiveCaret?: boolean;
  /* responsive align-items layout configuration that effects the position of the caret icon */
  alignCaret?: CSS.Property.AlignItems | Partial<Record<ScreenSize, CSS.Property.AlignItems>>;
} & DataTestId &
  Offset;

/**
 * This is a WAI-ARIA compliant React component that controls how panels/accordions/etc collapse and expand.
 */
export function Collapsible({
  id,
  children,
  header,
  isCollapsed,
  onToggled,
  mode = 'light',
  muted = false,
  compact = false,
  leftAligned = false,
  responsiveCaret = false,
  alignCaret,
  ariaLabel,
  offset,
  ...rest
}: CollapsibleProps) {
  const { isClosed, controlProps, contentProps } = useCollapsible({ id, isCollapsed, onToggled });

  return (
    <div {...rest}>
      {header && (
        <Toggle
          data-testid="collapsible-toggle-button"
          as="button"
          {...controlProps}
          muted={muted}
          mode={mode}
          compact={compact}
          aria-label={ariaLabel}
          type="button"
          alignItems={alignCaret}
        >
          {!leftAligned && header}
          <CaretContainer muted={muted} compact={compact} leftAligned={leftAligned}>
            {responsiveCaret ? (
              <>
                <DesktopCaret>
                  <Caret open={!isClosed} muted={muted} mode={mode} size={24} />
                </DesktopCaret>
                <MobileCaret>
                  <Caret open={!isClosed} muted={muted} mode={mode} size={18} />
                </MobileCaret>
              </>
            ) : (
              <Caret open={!isClosed} muted={muted} mode={mode} size={18} />
            )}
          </CaretContainer>
          {leftAligned && header}
        </Toggle>
      )}
      <Content offset={offset}>
        <AnimatePresence initial={false}>
          {!isClosed && (
            <motion.div {...contentProps} key="content">
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </Content>
    </div>
  );
}

type ToggleProps = {
  mode: Mode;
  compact: boolean;
  muted: boolean;
} & Pick<FlexProps, 'alignItems'>;

const Toggle = styled.div<ToggleProps>`
  align-items: ${({ muted, compact }) => (compact || muted ? 'flex-end' : 'center')};
  appearance: none;
  background: transparent;
  border: none;
  color: currentcolor;
  cursor: pointer;
  display: flex;
  justify-content: ${({ muted, compact }) => (compact || muted ? 'flex-start' : 'space-between')};
  padding: 0;
  text-align: unset;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus {
    color: ${({ mode }) => (mode === 'light' ? th.getColor('base900') : th.getColor('base100'))};
    outline: none;
  }

  ${alignItemsMixin}
`;

const MobileCaret = styled.div`
  display: block;
  ${media.lg`
    display: none;
  `}
`;

const DesktopCaret = styled.div`
  display: none;
  ${media.lg`
    display: block;
  `}
`;

const CaretContainer = styled.div<{ compact: boolean; muted: boolean; leftAligned: boolean }>`
  display: inline-block;
  height: 0.5rem;
  flex-shrink: 0;
  margin-left: ${({ leftAligned }) => (leftAligned ? 0 : th.getSpace('normal1'))};
  margin-right: ${({ leftAligned }) => (leftAligned === false ? 0 : th.getSpace('normal1'))};
  position: relative;
  width: 1rem;
  margin-top: ${({ muted, compact }) => (compact || muted ? 0 : th.getSpace('normal2'))};
`;

const Caret = styled(CaretDownIcon)<{ open: boolean; muted: boolean; mode: Mode }>`
  && {
    display: block;
    position: absolute;
    bottom: 0;
    transform-origin: center;
    transform: translateY(-2px) rotate(0deg);
    right: 0;
    transition: transform ${th.getDuration('shorter')}ms ${th.getTransition('easeInOut')};

    ${({ open }) =>
      open &&
      css`
        transform: translateY(-2px) rotate(180deg);
        transition: transform ${th.getDuration('shorter')}ms ${th.getTransition('easeInOut')};
      `}

    ${({ muted, mode }) =>
      muted &&
      css`
        color: ${mode === 'light' ? th.getColor('base700') : th.getColor('base400')};
      `}
  }
`;

const Content = styled.div<Offset>`
  overflow-y: hidden;
  ${offsetMixin}
`;
