import styled from 'styled-components';
import type * as CSS from 'csstype';

import { createMixin, type CreateMixinType } from '@yoweb/ui/styles/utils/mixins';
import type { ScreenSize } from '@yoweb/ui/styles/utils';

type DisplayProp = CreateMixinType<'display', CSS.Property.Display>;
type StyledDisplayProp = CreateMixinType<'$display', CSS.Property.Display>;

type ResponsiveProps = DisplayProp & WithChildren & DataTestId;

export const displayMixin = createMixin('$display')<CSS.Property.Display, StyledDisplayProp>(
  ({ $display = 'flex' }, css) => css`
    display: ${$display};
  `,
);

export const ResponsiveDisplay = ({ display, ...props }: ResponsiveProps) => (
  <StyledResponsive $display={display} {...props} />
);

const StyledResponsive = styled.div<StyledDisplayProp>`
  display: none;

  ${displayMixin}
`;

type RenderOnProps = {
  display?: CSS.Property.Display | Partial<Record<ScreenSize, CSS.Property.Display>> | undefined;
} & WithChildren;

export const RenderOn = {
  Tablet: (props: WithChildren) => <ResponsiveDisplay display={{ md: 'block' }} {...props} />,
  Desktop: (props: WithChildren) => <ResponsiveDisplay display={{ lg: 'block' }} {...props} />,
  MobileOnly: (props: WithChildren) => (
    <ResponsiveDisplay display={{ _: 'block', md: 'none' }} {...props} />
  ),
  TabletOnly: (props: WithChildren) => (
    <ResponsiveDisplay display={{ md: 'block', lg: 'none' }} {...props} />
  ),
  MobileAndTablet: ({ display, ...props }: RenderOnProps) => (
    <ResponsiveDisplay display={display ?? { _: 'block', lg: 'none' }} {...props} />
  ),
  MobileAndDesktop: (props: WithChildren) => (
    <ResponsiveDisplay display={{ _: 'block', md: 'none', lg: 'block' }} {...props} />
  ),
  TabletAndDesktop: (props: WithChildren) => (
    <ResponsiveDisplay display={{ sm: 'none', md: 'block' }} {...props} />
  ),
};
