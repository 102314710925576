import { forwardRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import type { ReactNode, ButtonHTMLAttributes, MouseEvent, Ref } from 'react';
import type { DefaultTheme, Space, FontWeights } from 'styled-components';

import { pxToRem } from '@yoweb/ui/styles/utils';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';
import { getColor, getSpace, getRadii, getDuration } from '@yoweb/ui/styles/utils/theme';
import LoadingDots from '@yoweb/ui/components/LoadingDots';
import { Interactive } from '@yoweb/ui/components/typography';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';
export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonMode = 'dark' | 'light' | null;

type CommonButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode;
  onClick?: (e: MouseEvent) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
};

export type ButtonBaseProps =
  | ({
      as: 'a';
      href?: string;
      type?: never;
      target?: string;
    } & CommonButtonProps)
  | ({
      as?: string;
      href?: never;
      type?: 'submit' | 'button' | 'reset';
      target?: never;
    } & CommonButtonProps);

export type ButtonVariantProps = {
  mode?: ButtonMode;
  variant?: ButtonVariant;
  radius?: keyof DefaultTheme['radii'];
  isActive?: boolean;
};

type Props = ButtonBaseProps &
  ButtonVariantProps & {
    gap?: keyof Space;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    text?: string;
    weight?: 'default' | 'bold';
  } & WithSize<ButtonSize>;

export type ButtonProps = DataTestId & Props;

export const Button = forwardRef(
  (
    {
      children,
      gap = 'small3',
      iconLeft,
      iconRight,
      isDisabled = false,
      isLoading = false,
      isFullWidth = false,
      onClick,
      radius = 'full',
      size = 'md',
      text = '',
      variant = 'primary',
      mode = 'light',
      weight = 'default',
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const theme = useTheme();

    const fontWeight: keyof FontWeights = weight === 'bold' ? 'bold' : 'semibold';

    const internalIconSize =
      size === 'sm' ? theme.iconSizes['compact'] : theme.iconSizes['default'];

    return (
      // @ts-ignore TODO improve typing
      <StyledButton
        disabled={isDisabled || isLoading}
        onClick={(e: MouseEvent) => {
          const isClickable = !isDisabled && !isLoading;
          if (typeof onClick === 'function' && isClickable) {
            onClick(e);
          }
        }}
        {...{
          isFullWidth,
          mode,
          variant,
          size,
          ref,
          radius,
          ...props,
          ...(props.as !== 'a' && { type: props.type ?? 'button' }),
        }}
      >
        <ButtonChildren isLoading={isLoading} gap={gap}>
          {iconLeft && <IconSizer size={internalIconSize}>{iconLeft}</IconSizer>}
          {text || children ? (
            <Interactive
              size={size}
              as="span"
              weight={fontWeight ? fontWeight : undefined}
              whiteSpace="pre"
            >
              {text || children}
            </Interactive>
          ) : null}

          {iconRight && <IconSizer size={internalIconSize}>{iconRight}</IconSizer>}
        </ButtonChildren>

        {isLoading && (
          <LoadingCover radius={radius} variant={variant} mode={mode}>
            <LoadingDots />
          </LoadingCover>
        )}
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';

const IconSizer = styled.div<{ size: string }>`
  display: grid;
  place-content: center;
  font-size: ${({ size }) => size};
`;

const ButtonChildren = styled.span<{ isLoading?: boolean; gap: keyof Space }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ gap }) => (gap ? getSpace(gap) : getSpace('small3'))};
  align-items: center;
  justify-content: center;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`;

const LoadingCover = styled.div<{
  radius: keyof DefaultTheme['radii'];
  variant?: ButtonVariant;
  mode?: ButtonMode;
}>`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ radius }) => getRadii(radius)};

  ${({ variant, mode }) => {
    switch (variant) {
      case 'primary':
        return css`
          background-color: ${getColor('base900')};
          color: ${getColor('base000')};
          ${mode === 'dark' &&
          css`
            background-color: ${getColor('base000')};
            color: ${getColor('base900')};
          `}
        `;
      case 'secondary':
      case 'tertiary':
        return css`
          background-color: transparent;
          color: ${getColor('baseA700')};
          ${mode === 'dark' &&
          css`
            color: ${getColor('base100')};
          `}
        `;
    }
  }}
`;

const buttonSizeMixin = createSizeMixin<ButtonSize, ButtonProps>(({ size = 'md' }, tagFn) => {
  switch (size) {
    case 'lg':
      return tagFn`
        height: ${pxToRem(48)};
        padding: ${pxToRem(12)} ${pxToRem(24)};
    `;
    case 'md':
      return tagFn`
        height: ${pxToRem(40)};
        padding:${pxToRem(8)} ${pxToRem(20)};
      `;
    case 'sm':
      return tagFn`
        height: ${pxToRem(32)};
        padding:${pxToRem(8)} ${pxToRem(20)};
      `;
  }
});

const focusStateBase = css<ButtonVariantProps>`
  ${({ radius, variant }) => css`
    outline: none;

    ::after {
      position: absolute;
      content: '';
      inset: -1px;
      border-radius: ${radius && getRadii(radius)};
      border: 2px solid;

      ${variant === 'primary' &&
      css`
        inset: -3px;
      `}
    }
  `}
`;

export const buttonBase = css<ButtonBaseProps>`
  position: relative;
  display: grid;
  place-content: center;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}
`;

export const buttonVariants = css<ButtonVariantProps>`
  ${({ variant, mode, isActive }: ButtonVariantProps) => {
    switch (variant) {
      case 'primary':
        return css`
          border: none;
          background-color: ${getColor('base900')};
          color: ${getColor('base000')};

          &:hover {
            background: ${getColor('base1000')};
          }

          &:disabled {
            color: ${getColor('base400')};
            background-color: ${getColor('baseA050')};
            border: 1px solid ${getColor('base400')};
            pointer-events: none;
          }

          &:focus {
            ${focusStateBase}
            &::after {
              border-color: ${getColor('baseA700')};
            }
          }

          ${isActive &&
          css`
            ${focusStateBase}

            ::after {
              border-color: ${getColor('baseA700')};
            }
          `}

          ${mode === 'dark' &&
          css`
            background-color: ${getColor('base000')};
            color: ${getColor('base900')};

            &:hover {
              background: ${getColor('base050')};
            }

            &:disabled {
              color: ${getColor('base900')};
              background-color: ${getColor('base700')};
              border: 1px solid ${getColor('base900')};
              pointer-events: none;
            }

            &:focus {
              &::after {
                border-color: ${getColor('base000')};
              }
            }
          `}
        `;
      case 'secondary':
        return css`
          border: 1px solid;
          border-color: ${getColor('base900')};
          background-color: transparent;
          color: ${getColor('base900')};

          &:hover {
            border-color: ${getColor('base1000')};
            background-color: ${getColor('baseA050')};
            color: ${getColor('base1000')};
          }

          &:disabled {
            color: ${getColor('base400')};
            border-color: ${getColor('base400')};
            pointer-events: none;
          }

          &:focus {
            ${focusStateBase}
            &::after {
              border-color: ${getColor('base900')};
            }
          }

          ${isActive &&
          css`
            ${focusStateBase}

            ::after {
              border-color: ${getColor('base900')};
            }
          `}

          /* Note - Dark mode is undefined in DS for secondary buttons */
          ${mode === 'dark' &&
          css`
            color: ${getColor('base000')};
            border-color: ${getColor('base100')};

            &:hover {
              border-color: ${getColor('base000')};
              color: ${getColor('base000')};
            }

            &:disabled {
              color: ${getColor('base400')};
              border-color: ${getColor('base400')};
              pointer-events: none;
            }

            &:focus {
              &::after {
                border-color: ${getColor('base000')};
              }
            }
          `}
        `;
      case 'tertiary':
        return css`
          border: none;
          background: transparent;
          color: ${getColor('base900')};
          text-decoration: underline;

          &:disabled {
            color: ${getColor('base400')};
            border-color: ${getColor('base400')};
            pointer-events: none;
          }

          &:hover {
            background: ${getColor('baseA050')};
            color: ${getColor('base1000')};
          }

          &:focus {
            ${focusStateBase}
            &::after {
              border-color: ${getColor('base900')};
            }
          }

          ${isActive &&
          css`
            ${focusStateBase}

            ::after {
              border-color: ${getColor('base900')};
            }
          `}

          border-color: ${getColor('base900')};
          ${mode === 'dark' &&
          css`
            color: ${getColor('base000')};
            border-color: ${getColor('base000')};

            &:disabled {
              color: ${getColor('base700')};
              border-color: ${getColor('base700')};
              pointer-events: none;
            }

            &:hover {
              background: ${getColor('base1000')};
              color: ${getColor('base000')};
            }

            &:focus {
              &::after {
                border-color: ${getColor('base000')};
              }
            }
          `}
        `;
    }
  }}
`;

const StyledButton = styled.button<ButtonProps>`
  ${buttonSizeMixin};
  ${buttonBase};
  ${buttonVariants};

  border-radius: ${({ radius }) => radius && getRadii(radius)};
  transition: all ${getDuration('interaction')}ms ease-out;
`;
