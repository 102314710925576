import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export function CloseIconToast(props: IconProps) {
  return (
    <IconBase {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5303 2.53033C14.8232 2.23744 14.8232 1.76256 14.5303 1.46967C14.2374 1.17678 13.7626 1.17678 13.4697 1.46967L8 6.93934L2.53033 1.46967C2.23744 1.17678 1.76256 1.17678 1.46967 1.46967C1.17678 1.76256 1.17678 2.23744 1.46967 2.53033L6.93934 8L1.46967 13.4697C1.17678 13.7626 1.17678 14.2374 1.46967 14.5303C1.76256 14.8232 2.23744 14.8232 2.53033 14.5303L8 9.06066L13.4697 14.5303C13.7626 14.8232 14.2374 14.8232 14.5303 14.5303C14.8232 14.2374 14.8232 13.7626 14.5303 13.4697L9.06066 8L14.5303 2.53033Z"
        fill="currentColor"
      />
    </IconBase>
  );
}

export default CloseIconToast;
