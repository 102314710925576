import { useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import type { ImageProps } from 'next/legacy/image';

type ImageWithFallbackProps = Omit<ImageProps, 'src'> & {
  fallbackSrc: string;
  src?: string;
  width: string | number;
  height: string | number;
  priority?: boolean;
};

export const ImageWithFallback = ({
  src,
  fallbackSrc,
  alt,
  width,
  height,
  unoptimized,
  objectFit,
  priority,
}: ImageWithFallbackProps) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      src={imgSrc || fallbackSrc}
      width={width}
      height={height}
      alt={alt}
      objectFit={objectFit}
      unoptimized={unoptimized}
      onError={() => setImgSrc(fallbackSrc)}
      priority={priority}
    />
  );
};

export default ImageWithFallback;
