export { DayOfWeek } from 'yo-services/google/type/dayofweek_pb';

export enum ProposalType {
  PROPOSAL_TYPE_UNSPECIFIED = 0,
  PROPOSAL_TYPE_RESEARCH_CONTENT = 1,
  PROPOSAL_TYPE_SINGLE_CONTENT = 2,
  PROPOSAL_TYPE_MULTIPLE_CONTENT = 3,
}

export enum BundleEntryType {
  BUNDLE_ENTRY_TYPE_UNSPECIFIED = 0,
  BUNDLE_ENTRY_TYPE_RESEARCH_CONTENT = 1,
  BUNDLE_ENTRY_TYPE_SINGLE_CONTENT = 2,
}

export enum ProposalStatus {
  PROPOSAL_STATUS_UNSPECIFIED = 0,
  PROPOSAL_STATUS_PUBLISHED = 1,
  PROPOSAL_STATUS_CONFIRMED = 2,
  PROPOSAL_STATUS_REJECTED = 3,
  PROPOSAL_STATUS_CANCELLED = 4,
  PROPOSAL_STATUS_COMPLETED = 5,
}

export enum FieldType {
  FIELD_TYPE_UNSPECIFIED = 0,
  FIELD_TYPE_TEXT = 1,
  FIELD_TYPE_URL = 2,
  FIELD_TYPE_PHONE = 3,
  FIELD_TYPE_EMAIL = 4,
  FIELD_TYPE_JOB_DATE = 5,
}

export enum TopLevelOptionStatus {
  TOP_LEVEL_OPTION_STATUS_UNSPECIFIED = 0,
  TOP_LEVEL_OPTION_STATUS_PUBLISHED = 1,
  TOP_LEVEL_OPTION_STATUS_SELECTED = 2,
  TOP_LEVEL_OPTION_STATUS_REJECTED = 3,
}

export enum BundleEntryStatus {
  BUNDLE_ENTRY_STATUS_UNSPECIFIED = 0,
  BUNDLE_ENTRY_STATUS_ACTIVE = 1,
  BUNDLE_ENTRY_STATUS_REMOVED = 2,
}

export enum BundleEntryOptionStatus {
  BUNDLE_ENTRY_OPTION_STATUS_UNSPECIFIED = 0,
  BUNDLE_ENTRY_OPTION_STATUS_SELECTED = 1,
  BUNDLE_ENTRY_OPTION_STATUS_UNSELECTED = 2,
}

export enum ProposalOptionLabel {
  PROPOSAL_OPTION_LABEL_UNSPECIFIED = 0,
  PROPOSAL_OPTION_LABEL_RECOMMENDED = 1,
}

export enum ChannelType {
  CHANNEL_UNSPECIFIED = 0,
  CHANNEL_PUSH_NOTIFICATION = 1,
  CHANNEL_EMAIL = 2,
  CHANNEL_SMS = 3,
  CHANNEL_SMS_MARKETING = 4,
}

export enum SupportTopic {
  EXISTING_MEMBERSHIP = 'existing-membership',
  MEMBERSHIP_WAITLIST = 'membership-waitlist',
  YO_APP = 'yo-app',
  BILLING_PURCHASES_ACCOUNT = 'billing-purchases-account',
  PARTNER_AFFILIATE = 'partner-affiliate',
  PRESS = 'press',
  URGENT = 'urgent',
}

export enum EmployersNumEmployees {
  LESS_THAN_ONE_HUNDRED = 'under-100',
  UP_TO_FIVE_HUNDRED = '100-499',
  UP_TO_ONE_THOUSAND = '500-999',
  UP_TO_FIVE_THOUSAND = '1000-4999',
  MORE_THAN_FIVE_THOUSAND = '5000+',
}

export enum EmployersYourRole {
  HR = 'hr',
  FOUNDER_CEO = 'ceo',
  CHRO_CPO = 'chro-cpo',
  HEAD_OF_DEPARTMENT = 'head-of-department',
  PEOPLE_OPERATIONS = 'po',
  TOTAL_REWARDS = 'total',
  EMPLOYER_BRAND = 'brand',
  MARKETING = 'marketing',
  ADMIN_FACILITIES = 'admin-facilities',
  OTHER = 'other',
  EXECUTIVE = 'executive',
  OFFICER_MANAGER_BUSSINESS_CLASS = 'officer-manager-bussiness-class',
  MANAGER_DEPUTY_MANAGER_CLASS = 'manager-deputy-manager-class',
  SECTION_MANAGER_SECTION_MANAGER_CLASS = 'section-manager-section-manager-class',
  SUPERVISOR_GNEREAL_EMPLOYER_CLASS = 'supervisor-general-employee-class',
}

// yo-services/yolabs/services/storefrontend/membership_cancel_option_pb.d.ts
export enum MembershipCancelOption {
  MEMBERSHIP_CANCEL_OPTION_UNSPECIFIED = 0,
  MEMBERSHIP_CANCEL_OPTION_NOT_HELP_ENOUGH = 1,
  MEMBERSHIP_CANCEL_OPTION_NEED_MORE_FUNCTION = 2,
  MEMBERSHIP_CANCEL_OPTION_PRICE_TOO_EXPENSIVE = 3,
  MEMBERSHIP_CANCEL_OPTION_TAKING_TOO_LONG = 4,
  MEMBERSHIP_CANCEL_OPTION_QUALITY_NOT_HAPPY = 5,
  MEMBERSHIP_CANCEL_OPTION_NO_NEED_NOW = 6,
  MEMBERSHIP_CANCEL_OPTION_OTHER = 7,
  MEMBERSHIP_CANCEL_FEEDBACK_QUALITY_AND_TIMELINE_NOT_HAPPY = 8,
}

// yo-services/yolabs/services/storefrontend/member_entitlement_state_pb.d.ts
export enum MemberEntitlementState {
  MEMBER_ENTITLEMENT_STATE_UNSPECIFIED = 0,
  MEMBER_ENTITLEMENT_STATE_PREVIEW = 1,
  MEMBER_ENTITLEMENT_STATE_ACTIVE = 2,
  MEMBER_ENTITLEMENT_STATE_PAST_DUE = 4,
  MEMBER_ENTITLEMENT_STATE_CANCELED = 3,
}

// yo-services/yolabs/services/storefrontend/service_region_pb.d.ts
export enum AddressType {
  ADDRESS_TYPE_UNSPECIFIED = 0,
  ADDRESS_TYPE_SERVICE = 1,
  ADDRESS_TYPE_BILLING = 2,
}

// yo-services/yolabs/services/storefrontend/storefrontend_pb.d.ts
export enum UserRegistrationType {
  USER_REGISTRATION_TYPE_UNSPECIFIED = 0,
  USER_REGISTRATION_TYPE_AUTH0 = 1,
  USER_REGISTRATION_TYPE_APPLE = 2,
  USER_REGISTRATION_TYPE_FACEBOOK = 3,
  USER_REGISTRATION_TYPE_GOOGLE = 4,
}

export enum EmployersIndustry {
  AGRICULTURE = 'agriculture',
  APPAREL = 'apparel',
  BANKING = 'banking',
  BIOTECHNOLOGY = 'biotechnology',
  CHEMICALS = 'chemicals',
  COMMUNICATIONS = 'communications',
  CONSTRUCTION = 'construction',
  CONSULTING = 'consulting',
  EDUCATION = 'education',
  ELECTRONICS = 'electronics',
  ENERGY = 'energy',
  ENGINEERING = 'engineering',
  ENTERTAINMENT = 'entertainment',
  ENVIRONMENTAL = 'environmental',
  FINANCE = 'finance',
  FOOD_AND_BEVERAGE = 'food-and-beverage',
  GOVERNMENT = 'government',
  HEALTHCARE = 'healthcare',
  HOSPITALITY = 'hospitality',
  INSURANCE = 'insurance',
  MACHINERY = 'machinery',
  MANUFACTURING = 'manufacturing',
  MEDIA = 'media',
  NOT_FOR_PROFIT = 'not-for-profit',
  OTHER = 'other',
  RECREATION = 'recreation',
  RETAIL = 'retail',
  SHIPPING = 'shipping',
  TECHNOLOGY = 'technology',
  TELECOMMUNICATIONS = 'telecommunications',
  TRANSPORTATION = 'transportation',
  UTILITIES = 'utilities',
  HOME_SERVICES = 'home-services',
  FAMILY_SERVICES = 'family-services',
  WELL_BEING_SERVICES = 'well-being-services',
  EXPERIENCES = 'experiences',
}

export enum HowDidYouHearAboutUs {
  SEARCH = 'search',
  SOCIAL_MEDIA = 'social-media',
  LINKEDIN = 'linkedin',
  MEDIA_NEWS = 'media-news',
  YOHANA_MEMBER = 'yohana-member',
  EVENT = 'event',
  WORD_OF_MOUTH = 'word-of-mouth',
  OTHER = 'other',
}
