import styled from 'styled-components';

import {
  type FlexProps,
  wrapMixin,
  growMixin,
  gapMixin,
  rowGapMixin,
  columnGapMixin,
  alignItemsMixin,
  directionMixin,
  displayMixin,
  justifyMixin,
  flexMixin,
  alignSelfMixin,
  orderMixin,
} from '@yoweb/ui/styles/utils/mixins.utils';

/**
 * A general purposes Flex component.
 */
export const Flex = styled.div<FlexProps>`
  ${alignItemsMixin}
  ${alignSelfMixin}
  ${columnGapMixin}
  ${directionMixin}
  ${displayMixin}
  ${flexMixin}
  ${gapMixin}
  ${growMixin}
  ${justifyMixin}
  ${rowGapMixin}
  ${wrapMixin}
  ${orderMixin}
`;
