import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const InstagramIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M4.49689 1.75C2.97921 1.75 1.75 2.97977 1.75 4.49689V11.5031C1.75 13.0208 2.97977 14.25 4.49689 14.25H11.5031C13.0209 14.25 14.25 13.0201 14.25 11.5039V4.49689C14.25 2.9791 13.0201 1.75 11.5039 1.75H4.49689ZM4.49689 0.25H11.5039C13.8485 0.25 15.75 2.15068 15.75 4.49689V11.5039C15.75 13.8485 13.8493 15.75 11.5031 15.75H4.49689C2.15156 15.75 0.25 13.8494 0.25 11.5031V4.49689C0.25 2.15156 2.15057 0.25 4.49689 0.25ZM11.7485 3.25C11.1974 3.25296 10.75 3.70037 10.75 4.25148C10.75 4.80259 11.2004 5.25 11.7515 5.25C12.3026 5.25 12.75 4.80259 12.75 4.25148C12.7529 3.69741 12.3026 3.25 11.7485 3.25ZM5.87868 5.87868C6.67756 5.0798 7.81489 4.82567 8.8291 5.11627C9.30226 5.25185 9.74862 5.50598 10.1213 5.87868C10.494 6.25138 10.7482 6.69774 10.8837 7.1709C11.1743 8.18511 10.9202 9.32244 10.1213 10.1213C8.94975 11.2929 7.05025 11.2929 5.87868 10.1213C4.70711 8.94975 4.70711 7.05025 5.87868 5.87868ZM9.06066 6.93934C9.64645 7.52513 9.64645 8.47487 9.06066 9.06066C8.47487 9.64645 7.52513 9.64645 6.93934 9.06066C6.35355 8.47487 6.35355 7.52513 6.93934 6.93934C7.52513 6.35355 8.47487 6.35355 9.06066 6.93934Z"
    />
  </IconBase>
);

export default InstagramIcon;
