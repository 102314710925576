import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const NoteJpIcon = (props: IconProps) => (
  <IconBase width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M7.05005 17.2941V7.00003C7.05005 7.00003 10.461 7 13.4324 7C16.4038 7 17.55 8.36633 17.55 11.1176V17.2941"
      stroke="currentColor"
      strokeWidth="1.52727"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </IconBase>
);

export default NoteJpIcon;
