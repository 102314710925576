import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const OpenNewWindowIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 21.5h14c1.52 0 2.75-1.23 2.75-2.75v-4c0-.41-.34-.75-.75-.75s-.75.34-.75.75v4c0 .69-.56 1.25-1.25 1.25h-14c-.69 0-1.25-.56-1.25-1.25v-14c0-.69.56-1.25 1.25-1.25h4c.41 0 .75-.34.75-.75S9.16 2 8.75 2h-4C3.23 2 2 3.23 2 4.75v14c0 1.52 1.23 2.75 2.75 2.75Zm6.47-9.22c.15.15.34.22.53.22s.38-.07.53-.22L20 4.56v5.19c0 .42.33.73.74.73s.75-.34.75-.75v-7a.75.75 0 0 0-.18-.48c-.03-.03-.07-.07-.11-.1a.779.779 0 0 0-.4-.15h-7.05a.749.749 0 1 0 0 1.5h5.19l-7.72 7.72c-.29.29-.29.77 0 1.06Z"
      fill="currentColor"
    />
  </IconBase>
);

export default OpenNewWindowIcon;
