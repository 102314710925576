import { useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import type { IconSizes } from 'styled-components';

import { media, rootFontScalar, remToPx, breakpoints } from '@yoweb/ui/styles/utils';
import { Link } from '@yoweb/ui/components/Link';
import { YohanaLogo } from '@yoweb/ui/components/Icon/YohanaLogo';
import { getDuration, getColor } from '@yoweb/ui/styles/utils/theme';

const DESKTOP_AVATAR_SIZE_KEY: keyof IconSizes = 'display';
const MOBILE_AVATAR_SIZE_KEY: keyof IconSizes = 'default';

type LogoAnchorProps = { href: string; isOpen?: boolean };

export const LogoAnchor = ({ href, isOpen = false }: LogoAnchorProps) => {
  const theme = useTheme();

  const DESKTOP_AVATAR_SIZE = useMemo(
    () => remToPx(theme.iconSizes[DESKTOP_AVATAR_SIZE_KEY]),
    [theme.iconSizes],
  );
  const MOBILE_AVATAR_SIZE = useMemo(
    () => remToPx(theme.iconSizes[MOBILE_AVATAR_SIZE_KEY]),
    [theme.iconSizes],
  );

  return (
    <Link href={href} passHref>
      <Anchor isOpen={isOpen} data-testid="header-logo">
        <MobileView>
          <YohanaLogo width={71.25} height={MOBILE_AVATAR_SIZE} />
        </MobileView>
        <DesktopView>
          <YohanaLogo width={95} height={DESKTOP_AVATAR_SIZE} />
        </DesktopView>
      </Anchor>
    </Link>
  );
};

const Anchor = styled.span<{ isOpen: boolean }>`
  color: ${getColor('base900')};
  position: relative;
  z-index: 1;
  display: flex;

  @media (max-width: ${(breakpoints.lg - 1) / rootFontScalar}em) {
    position: relative;
    transition: color ${getDuration('interaction')};
    transition-duration: ${getDuration('interaction')}ms;
    transition-delay: ${getDuration('instant')}ms;

    ${({ isOpen }: { isOpen: boolean }) =>
      isOpen &&
      css`
        color: ${getColor('base000')};
        transition-delay: ${(props) => getDuration('visual')(props) / 2}ms;
      `}
  }
`;

const MobileView = styled.div`
  ${media.md`
  display: none;
`}
`;

const DesktopView = styled.div`
  display: none;
  ${media.md`
  display: initial;
`}
`;
