import styled from 'styled-components';
import type { FontSizes, LineHeights } from 'styled-components';

import { th } from '@yoweb/ui/styles/utils/theme';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';
import type { TextAlignProps } from '@yoweb/ui/styles/utils/mixins.utils';
import type {
  TextColorProps,
  TextWeightProps,
  TextTransformProps,
  TextWhiteSpaceProps,
} from './textBase';
import { textBase } from './textBase';

export type InteractiveSize = 'sm' | 'md' | 'lg' | 'xl';
export type InteractiveProps = WithSize<InteractiveSize> &
  TextColorProps &
  TextWeightProps &
  TextTransformProps &
  TextAlignProps &
  TextWhiteSpaceProps;

/** Map interactive size to theme key. */
const sizeMapping: Record<InteractiveSize, keyof FontSizes> = {
  sm: 'interactiveSm',
  md: 'interactiveMd',
  lg: 'interactiveLg',
  xl: 'interactiveXl',
};

/** Map label lineHeights to theme key. */
const lineHeightMapping: Record<InteractiveSize, keyof LineHeights> = {
  sm: 'interactiveSm',
  md: 'interactiveMd',
  lg: 'interactiveLg',
  xl: 'interactiveXl',
};

/**
 * font-size + line-height + letter-spacing mixin.
 * Control all with a single prop.
 */
const sizeMixin = createSizeMixin<InteractiveSize, InteractiveProps>(
  ({ size = 'lg' as InteractiveSize }, tagFn) =>
    tagFn`
    font-size: ${th.getFontSize(sizeMapping[size])};
    line-height: ${th.getLineHeight(lineHeightMapping[size])};
    letter-spacing: ${th.getLetterSpacing(sizeMapping[size])};
  `,
);

/**
 * Interactive text component with multiple props to modify.
 */
export const Interactive = styled.span<InteractiveProps>`
  font-weight: ${th.getFontWeight('semibold')};
  ${textBase};
  ${sizeMixin};
`;

Interactive.defaultProps = {
  size: 'lg',
};
