import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const CaretRightIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.226 2.219a.75.75 0 0 1 1.06.002l8.995 9.027a.75.75 0 0 1-.002 1.06l-9 8.973A.75.75 0 1 1 7.22 20.22l8.47-8.444L7.223 3.28a.75.75 0 0 1 .002-1.06Z"
      fill="CurrentColor"
    />
  </IconBase>
);
