import { useContext } from 'react';
import { UserContext } from '@store/contexts/UserContext';
/**
 * Get the currently authorized user's information.
 */

export function useUser() {
  var user = useContext(UserContext);

  if (!user) {
    throw new Error('UserContext.Provider not initialized correctly, missing initial user state');
  }

  return user;
}