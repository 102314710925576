import _slicedToArray from "/home/runner/work/web/web/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/home/runner/work/web/web/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { setCookie, parseCookies } from 'nookies';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { isDefined } from '@yoweb/utils/isDefined';
import { objRemoveEmpty } from '@yoweb/utils/objRemoveEmpty';
import { collectQueryParameters, createCookieOptions } from '@store/lib/persistedQueryParameters';
/**
 * Hook to persist query params to cookies and retrieve them
 * Uses key as cookie name
 * If multiple query parameters should be save to the same cookie use paramKeys
 */

export var usePersistedQueryParameters = function usePersistedQueryParameters(persistedQueryParams) {
  var _useRouter = useRouter(),
      pathname = _useRouter.pathname;

  useEffect(function () {
    if (!persistedQueryParams) {
      return;
    } // collect all persisted params from query string


    var params = persistedQueryParams.reduce(function (acc, curr) {
      var _curr$ignoreOnPages;

      if ((_curr$ignoreOnPages = curr.ignoreOnPages) !== null && _curr$ignoreOnPages !== void 0 && _curr$ignoreOnPages.includes(pathname)) {
        return acc;
      }

      var paramsToCollect = curr.paramKeys ? curr.paramKeys : curr.key;
      var value = collectQueryParameters(paramsToCollect);
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, curr.key, value));
    }, {}); // Remove all properties that don't have values

    var noEmptyParams = objRemoveEmpty(params, true);
    var isEmpty = !Object.values(noEmptyParams).some(function (param) {
      return param !== '';
    }); // Create cookies for parameters

    if (!isEmpty) {
      Object.entries(noEmptyParams).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        var config = persistedQueryParams.find(function (persistedParam) {
          return persistedParam.key === key;
        });
        setCookie(null, key, JSON.stringify(value), createCookieOptions(config));
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  var getPersistedParameter = function getPersistedParameter(key) {
    var paramsCookie = parseCookies(null)[key];
    return isDefined(paramsCookie) ? JSON.parse(paramsCookie) : null;
  };

  return {
    getPersistedParameter: getPersistedParameter
  };
};