const isStorageSupported = (storageType: 'localStorage' | 'sessionStorage') => {
  try {
    window[storageType].setItem('test', 'test');
    window[storageType].removeItem('test');

    return true;
  } catch (e) {
    return false;
  }
};

export const isSessionStorageSupported = () => isStorageSupported('sessionStorage');

export const isLocalStorageSupported = () => isStorageSupported('localStorage');
