import styled from 'styled-components';
import type { FontSizes } from 'styled-components';

import { th } from '@yoweb/ui/styles/utils/theme';
import { textBase } from './textBase';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';
import type { TextBaseProps } from './textBase';

export type TextSizeProps = WithSize<TextSize>;

export type TextProps = TextBaseProps & TextSizeProps;

type TextSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const sizeMapping: Record<TextSize, keyof FontSizes> = {
  xs: 'bodyXs',
  sm: 'bodySm',
  md: 'bodyMd',
  lg: 'bodyLg',
  xl: 'bodyXl',
};

/**
 * font-size + line-height + letter-spacing mixin.
 * Control all with a single prop.
 */
export const textSizeBodyMixin = createSizeMixin<TextSize, TextProps>(
  ({ size = 'lg' as TextSize }, tagFn) =>
    tagFn`
    font-size: ${th.getFontSize(sizeMapping[size])};
    line-height: ${th.getLineHeight(sizeMapping[size])};
    letter-spacing: ${th.getLetterSpacing(sizeMapping[size])};
  `,
);

/**
 * Body text component.
 */
export const Text = styled.p<TextProps>`
  ${textBase};
  ${textSizeBodyMixin};
`;

Text.defaultProps = {
  size: 'lg',
};
