import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export function CloseIcon(props: IconProps) {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.28 4.78a.75.75 0 0 0-1.06-1.06L12 10.94 4.78 3.72a.75.75 0 0 0-1.06 1.06L10.94 12l-7.22 7.22a.75.75 0 1 0 1.06 1.06L12 13.06l7.22 7.22a.75.75 0 1 0 1.06-1.06L13.06 12l7.22-7.22Z"
        fill="currentColor"
      />
    </IconBase>
  );
}

export default CloseIcon;
