/**
 * Checks if `value` is object-like. A value is object-like if it's not `null`
 * and has a `typeof` result of "object".
 *
 * @example isObject({}) // => true
 * @example isObject([1, 2, 3]) // => true
 * @example isObject({ name: 'John Smith' }) // => true
 * @example isObject(Function) // => false
 * @example isObject(null) // => false
 */
export function isObjectLike(value: unknown): boolean {
  return typeof value === 'object' && value !== null;
}
