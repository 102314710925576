import countryToStatesMap from '@yoweb/utils/constants/countryToStatesMap';
import type { TFunction, ParseKeys } from '@yoweb/next-i18next';

const DEFAULT_COUNTRY = 'US';

/**
 * Get the country for a locale.
 * Ex. The country of "en-US" is "US".
 */
export const getCountryForLocale = (locale: string) =>
  (locale.split('-')[1]?.toUpperCase() as SupportedCountry) ?? DEFAULT_COUNTRY;

/**
 * Get the list of states, prepared for display to the user, for a country.
 */
export const getStatesForCountry = (country: SupportedCountry, t: TFunction<'states'>) =>
  countryToStatesMap[country].map((isoCode) => ({
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    label: t(isoCode as ParseKeys<'states'>) as string,
    value: isoCode,
  }));

/**
 * Some locales, like Japanese, prefer to orient their addresses around postal codes instead of streets.
 * Return whether the provided string is such a locale.
 */
export const isLocalePostalCodeDominant = (lcLocale: string) => lcLocale === 'ja-jp';

/**
 * Get the formatted description of a Google Places result based on locale.
 */
export const formatGooglePlacesDescription = (description: string, lcLocale: string) => {
  if (lcLocale === 'ja-jp') {
    // strip out "Japan" from the description
    return description.replace('日本、', '');
  }

  return description;
};

/**
 * Get the formatted address based on (expected to be lower-cased) locale.
 */
export const formatStreetFromAddress = (address: AddressDetails, lcLocale: string) => {
  const { streetNumber, route, district } = address;

  if (lcLocale === 'ja-jp') {
    // assuming that we don't have full info about Japanese user's address so putting the bare minimum
    return `${district?.longName ?? ''}`;
  }

  return streetNumber
    ? `${streetNumber.longName} ${route?.longName ?? ''}`.trim()
    : route?.longName;
};

/**
 * Get the formatted address based on (expected to be lower-cased) locale.
 */
export const formatCityFromAddress = (address: AddressDetails, lcLocale: string) => {
  const { subState, city, ward } = address;

  if (lcLocale === 'ja-jp') {
    // JP city should be (administrative_area_level_2)+(locality)+(sublocality_level_1)
    return `${subState?.longName ?? ''}${city?.longName ?? ''}${ward?.longName ?? ''}`;
  }

  return city?.longName;
};
