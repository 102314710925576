export const SOCIAL_FACEBOOK = 'https://www.facebook.com/joinyohana/?business_id=311775480580109';
export const SOCIAL_TWITTER = 'https://mobile.twitter.com/join_yohana';
export const SOCIAL_INSTAGRAM = 'https://www.instagram.com/joinyohana';
export const SOCIAL_LINKEDIN = 'https://www.linkedin.com/company/joinyohana';
export const SOCIAL_LINKEDIN_JOBS = 'https://www.linkedin.com/company/joinyohana/jobs/';

export const SOCIAL_FACEBOOK_SHARE = 'https://www.facebook.com/sharer/sharer.php';
export const SOCIAL_TWITTER_SHARE = 'https://mobile.twitter.com/intent/tweet';
export const SOCIAL_LINKEDIN_SHARE = 'https://www.linkedin.com/shareArticle?mini=true';

//JP Urls
export const SOCIAL_TWITTER_JP = 'https://twitter.com/yohana_japan';
export const SOCIAL_INSTAGRAM_JP = 'https://www.instagram.com/yohanajapan';
export const SOCIAL_LINKEDIN_JP = 'https://www.linkedin.com/company/yohanajapan';
export const SOCIAL_NOTE_JP = 'https://magazine.yohana.jp';
