import type { ServiceError, status as GrpcStatusCodes } from '@grpc/grpc-js';
import type { StripeError } from '@stripe/stripe-js';
import type { StatusCodes } from 'http-status-codes';

const isNonNullObject = (obj: unknown): obj is Record<string, unknown> =>
  typeof obj === 'object' && obj !== null;

export const isError = (error: unknown): error is Error => {
  if (error instanceof Error) {
    return true;
  }

  return isNonNullObject(error) && 'name' in error && 'message' in error;
};

export const isCustomError = (error: unknown): error is CustomError =>
  isNonNullObject(error) && 'code' in error;

export const isStripeClientError = (error: unknown): error is StripeError =>
  isNonNullObject(error) && 'type' in error;

export const isGrpcError = (error: unknown): error is ServiceError =>
  isNonNullObject(error) && 'code' in error;

export const getGrpcErrorCode = (error: unknown): GrpcStatusCodes | undefined => {
  if (isGrpcError(error)) {
    return error.code;
  }
};

export const getErrorStatusCode = (error: unknown): StatusCodes | undefined => {
  if (isNonNullObject(error) && typeof error.statusCode === 'number') {
    return error.statusCode;
  }
};

export const getErrorMessage = (error: unknown): string | undefined => {
  if (isNonNullObject(error) && typeof error.message === 'string') {
    return error.message;
  }
};

const generateErrorMessage = (error: unknown): string | undefined => {
  const message = getErrorMessage(error);

  if (typeof message === 'string') {
    return message;
  }

  try {
    return JSON.stringify(error);
  } catch (ex) {
    return undefined;
  }
};

export const toErrorObject = (error: unknown): Error => {
  if (isError(error)) {
    return error;
  }

  return new Error(generateErrorMessage(error));
};

export const getFieldInvalidArgument = (error: string | undefined): string => {
  if (!error) return 'Unknown Field';
  const match = error.match(/invalid (.+?) number/i);
  return match ? match[1] : 'Unknown Field';
};
