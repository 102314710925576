// Checks if value is JS string type
export const isString = (value: unknown, allowEmpty = false): value is string => {
  const isStr = typeof value === 'string' || value instanceof String;

  if (allowEmpty) {
    return isStr;
  }

  return isStr && value !== '';
};

export function assertIsString(value: unknown, msg?: string): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error(msg ?? `Exception a string but received: "${value}"`);
  }
}
