import { css, type ThemedCssFunction } from 'styled-components';
import { transparentize } from 'polished';

import { th } from '@yoweb/ui/styles/utils/theme';

export type ScreenSize = '_' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Breakpoints = {
  [size in ScreenSize]: number;
};

// Define smallest size for each breakpoint.
const breakpoints: Breakpoints = {
  _: 0,
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
};

const rootFontScalar = 16;

type MediaQueryType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [size in ScreenSize]: ThemedCssFunction<any>;
};

// Iterate through the sizes and create a media template
const media = Object.keys(breakpoints).reduce<MediaQueryType>((acc, label) => {
  const minWidth = breakpoints[label as ScreenSize];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (acc as any)[label] = (...args: any[]) => css`
    @media (min-width: ${minWidth / rootFontScalar}em) {
      ${css.call<any, any, any>(undefined, ...args)}
    }
  `;
  return acc;
}, {} as MediaQueryType);

type ShadowDepth = 1 | 2;
/**
 * Add a box shadow at various depths to an element.
 * @param depth - Amount of spread the shadow has.
 */
const boxShadow = (depth: ShadowDepth = 1): ReturnType<typeof css> => css`
  box-shadow: 0 0 ${({ theme }) => (depth === 1 ? theme.space.normal2 : theme.space.medium3)};
  ${({ theme }) => transparentize(0.8, theme.colors.base700)};
`;

/**
 * Add a box shadow similar to material ui elevation
 * @param value - Amount of spread the shadow has.
 */
const boxShadowElevation = (value: number): ReturnType<typeof css> => {
  const fromTop = Math.floor(value / 3);
  const spread = Math.round(value * 1.2);

  return css`
    box-shadow: 0 ${pxToRem(fromTop)} ${pxToRem(spread)};
    ${({ theme }) => transparentize(0.8, theme.colors.base900)};
  `;
};

/**
 * Converts an absolute px value to the same base value as rem.
 * @param pxValue - target px value to convert.
 */
const pxToRem = (pxValue: number): string => `${pxValue / rootFontScalar}rem`;

const remToPx = (remValue: string): number => parseFloat(remValue.slice(0, -3)) * rootFontScalar;

/**
 * Add the base grid to any component.
 * Need to make sure to include all breakpoint data in each component using this util.
 */
const gridBase = css`
  display: grid;
  grid-column-gap: ${th.getSize('gridGap')};
  grid-template-columns: repeat(4, 1fr);

  ${media.md`
    grid-template-columns: repeat(8, 1fr);
  `}
  ${media.lg`
    grid-template-columns: repeat(12, 1fr);
  `}
`;

export type { Breakpoints };

export {
  rootFontScalar,
  breakpoints,
  boxShadow,
  boxShadowElevation,
  gridBase,
  media,
  pxToRem,
  remToPx,
};
