import { type RefObject, useCallback, useEffect, useRef } from 'react';

import { rafPromise } from '@yoweb/utils/browser';
import { runMocks } from '@yoweb/utils/common';
import { useScrollHandler } from '@yoweb/ui/hooks/useScrollHandler';

export const useScrollReveal = (
  animationDuration: number,
  offset = false,
): RefObject<HTMLDivElement> | null => {
  const debounceRef = useRef<number>();
  const animationRef = useRef<number>();
  const elementRef = useRef<HTMLDivElement>(null);

  const { current: cache } = useRef({
    headerHeight: 0,
    headerTop: 0,
    headerOffsetTop: 0,
  });

  const revealHeader = async (scrollY: number) => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    clearTimeout(animationRef.current);
    element.style.setProperty('transition', null);

    await rafPromise();
    const ty = Math.max(
      cache.headerOffsetTop,
      Math.min(cache.headerHeight, scrollY - cache.headerTop),
    );
    element.style.setProperty('transform', `translate3d(0, ${-ty}px, 0)`);

    await rafPromise();
    element.style.setProperty('transition', `transform ${animationDuration}ms`);
    element.style.setProperty('transform', `translate3d(0, 0px, 0)`);

    animationRef.current = window.setTimeout(() => {
      element.style.setProperty('transition', null);
      element.style.setProperty('transform', null);
    }, animationDuration);
  };

  const hideHeader = async (scrollY: number) => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    clearTimeout(animationRef.current);
    element.style.setProperty('transition', null);
    element.style.setProperty('transform', null);
    await rafPromise();

    const ty = cache.headerTop + cache.headerHeight - scrollY;
    element.style.setProperty('transition', `transform ${animationDuration}ms`);
    element.style.setProperty('transform', `translate3d(0, ${-ty}px, 0)`);

    animationRef.current = window.setTimeout(() => {
      element.style.setProperty('transition', null);
      element.style.setProperty('transform', null);
      element.style.setProperty('top', `0px`);
    }, animationDuration);
  };

  useScrollHandler(({ current }) => {
    const element = elementRef.current;
    // Skip scroll hide/reveal logic in tests.
    if (runMocks || !element) {
      return;
    }

    clearTimeout(debounceRef.current as number);

    switch (current.direction) {
      case 'up':
        if (current.y <= cache.headerOffsetTop) {
          element.style.setProperty('top', '0');
          element.style.setProperty('position', 'absolute');

          resizeHandler();
          return;
        }

        if (current.directionChanged) {
          void revealHeader(current.y);
          element.style.setProperty('position', 'fixed');
          element.style.setProperty('top', '0');

          cache.headerTop = 0;
        }

        break;
      case 'down':
        if (current.y <= cache.headerOffsetTop + cache.headerHeight) {
          return;
        }

        if (current.directionChanged) {
          cache.headerTop = current.y;
        }
        element.style.setProperty('top', `${cache.headerTop - cache.headerOffsetTop}px`);
        element.style.setProperty('position', 'absolute');

        debounceRef.current = window.setTimeout(() => {
          if (
            current.y <= cache.headerHeight ||
            current.y < cache.headerTop + cache.headerHeight / 2
          ) {
            return;
          }
          void hideHeader(current.y);
        }, 1000);
        break;
    }
  });

  const resizeHandler = useCallback(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    const clientRect = element.getBoundingClientRect();

    if (clientRect) {
      const parentBoundingRect = element.parentElement?.getBoundingClientRect();

      cache.headerHeight = clientRect.height;
      cache.headerTop = clientRect.top;
      cache.headerOffsetTop = (parentBoundingRect && parentBoundingRect.top + window.scrollY) || 0;
    }
  }, [cache]);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    resizeHandler();

    window.addEventListener('resize', resizeHandler, { passive: true });

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [elementRef, cache, resizeHandler]);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    resizeHandler();
  }, [offset, resizeHandler]);

  return elementRef;
};

export default useScrollReveal;
