import { PAGE_ABOUT_US, PAGE_CONTACT_US, PAGE_FAQ, PAGE_INSPIRATION, PAGE_PRESS, PAGE_CAREERS, PAGE_EMPLOYERS, PAGE_GIFT } from '@store/constants/routes';
var OurCompanySection = {
  title: 'footer.our-company',
  links: [{
    title: 'footer.about-us',
    href: PAGE_ABOUT_US
  }, {
    title: 'footer.our-inspiration',
    href: PAGE_INSPIRATION
  }, {
    title: 'footer.press',
    href: PAGE_PRESS
  }, {
    title: 'footer.careers',
    href: PAGE_CAREERS
  }, {
    title: 'footer.contact',
    href: PAGE_CONTACT_US
  }]
};
var SubscriptionSection = {
  title: 'footer.subscription',
  links: [{
    title: 'footer.faqs',
    href: PAGE_FAQ
  }, {
    title: 'footer.gifting',
    href: PAGE_GIFT
  }]
};
var ForEmployers = {
  title: 'footer.for-employers-title',
  links: [{
    title: 'footer.corporate-wellness',
    href: PAGE_EMPLOYERS
  }]
};
export var FOOTER_LINK_SECTIONS_V2 = [OurCompanySection, SubscriptionSection, ForEmployers];