import type { ReactNode } from 'react';
import type { Space } from 'styled-components';

import { Grid } from '@yoweb/ui/components/Grid';
import { ButtonIcon } from '@yoweb/ui/components/buttons';
import { FacebookIcon } from '@yoweb/ui/components/Icon/FacebookIcon';
import { InstagramIcon } from '@yoweb/ui/components/Icon/InstagramIcon';
import { LinkedInIcon } from '@yoweb/ui/components/Icon/LinkedInIcon';
import { TwitterIcon } from '@yoweb/ui/components/Icon/TwitterIcon';
import {
  SOCIAL_FACEBOOK,
  SOCIAL_INSTAGRAM,
  SOCIAL_LINKEDIN,
  SOCIAL_TWITTER_JP,
  SOCIAL_LINKEDIN_JP,
  SOCIAL_INSTAGRAM_JP,
  SOCIAL_NOTE_JP,
} from '@yoweb/ui/constants/externalUrls';
import { useIntlMeta } from '@yoweb/ui/hooks/useIntlMeta';
import type { ButtonIconMode, ButtonIconSize } from '@yoweb/ui/components/buttons/ButtonIcon';
import { NoteJpIcon } from '@yoweb/ui/components/Icon';

type SocialLinkProps = {
  icon: ReactNode;
  href: string;
  size?: ButtonIconSize;
  ariaLabel: string;
  mode?: ButtonIconMode;
} & DataTestId;
type SocialProps = {
  size?: ButtonIconSize;
  gap?: keyof Space;
};

export const SocialLink = ({
  icon,
  href,
  size = 'sm',
  ariaLabel,
  mode = 'dark',
}: SocialLinkProps) => (
  <a
    data-testid={`${ariaLabel}-link`}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={ariaLabel}
  >
    <ButtonIcon size={size} icon={icon} mode={mode} as="span" />
  </a>
);

export const Social = ({ size, gap }: SocialProps) => {
  const { lcLocale } = useIntlMeta();

  const isJapanLocale = lcLocale === 'ja-jp';

  return (
    <Grid flow="column" justify="start" gap={gap} data-testid="social-links-grid">
      <SocialLink
        size={size}
        icon={<InstagramIcon />}
        href={isJapanLocale ? SOCIAL_INSTAGRAM_JP : SOCIAL_INSTAGRAM}
        ariaLabel="Instagram"
      />
      {!isJapanLocale && (
        <SocialLink
          size={size}
          icon={<FacebookIcon />}
          href={SOCIAL_FACEBOOK}
          ariaLabel="Facebook"
        />
      )}
      {isJapanLocale && (
        <SocialLink
          size={size}
          icon={<TwitterIcon />}
          href={SOCIAL_TWITTER_JP}
          ariaLabel="Twitter"
        />
      )}
      <SocialLink
        size={size}
        icon={<LinkedInIcon />}
        href={isJapanLocale ? SOCIAL_LINKEDIN_JP : SOCIAL_LINKEDIN}
        ariaLabel="LinkedIn"
      />
      {isJapanLocale && (
        <SocialLink size={size} icon={<NoteJpIcon />} href={SOCIAL_NOTE_JP} ariaLabel="NoteJapan" />
      )}
    </Grid>
  );
};
