import {
  forwardRef,
  type ForwardRefRenderFunction,
  type ElementType,
  type DOMAttributes,
} from 'react';
import styled, { type Space, type Colors, type Radii, type Shadows } from 'styled-components';
import type * as CSS from 'csstype';

import { pxToRem } from '@yoweb/ui/styles/utils';
import { createMixin, type CreateMixinType } from '@yoweb/ui/styles/utils/mixins';
import { getColor, getRadii, getShadow, getSpace } from '@yoweb/ui/styles/utils/theme';
import { isNumber } from '@yoweb/utils/isNumber';
import { isDefined } from '@yoweb/utils/isDefined';
import {
  alignContentMixin,
  alignItemsMixin,
  alignSelfMixin,
  columnGapMixin,
  flexMixin,
  gapMixin,
  growMixin,
  insetMixin,
  justifyItemsMixin,
  justifySelfMixin,
  overflowMixin,
  rowGapMixin,
  setBoxValue,
  textAlignMixin,
  visibilityMixin,
  wrapMixin,
  zIndexMixin,
} from '@yoweb/ui/styles/utils/mixins.utils';
import type {
  BoxValue,
  FlexProps,
  InsetProps,
  OverflowProps,
  TextAlignProps,
  VisibilityProps,
  ZIndexProps,
} from '@yoweb/ui/styles/utils/mixins.utils';

const boxKeysArr = [
  'm', // margin:
  'mt', // margin-top:
  'mb', // margin-bottom:
  'ml', // margin-left:
  'mr', // margin-right:
  'my', // margin-top: and margin-bottom
  'mx', // margin-left: and margin-right:
  'p', // padding:
  'pt', // padding-top:
  'pb', // padding-bottom:
  'pl', // padding-left:
  'pr', // padding-right:
  'py', // padding-top: and padding-bottom:
  'px', // padding-left: and padding-right:
  'h', // height
  'height', // height
  'minHeight', // min-height
  'maxHeight', // max-height
  'w', // width
  'width', // width
  'maxWidth', // max-width
  'minWidth', // min-width
  'top', // top:
  'bottom', // bottom:
  'left', // left:
  'right', // right:
] as const;

type CssBoxKeys = (typeof boxKeysArr)[number];

type CssColorKeys =
  | 'backgroundColor' // background-color:
  | 'fontColor'; // color:

export type BoxKeysProps = CreateMixinType<CssBoxKeys, BoxValue>;
type ColorKeysProps = CreateMixinType<CssColorKeys, keyof Colors>;
type RadiiKeysProps = CreateMixinType<'radii', keyof Radii>;
type ShadowsKeysProps = CreateMixinType<'shadow', keyof Shadows>;
type PositionProps = CreateMixinType<'position', CSS.Property.Position>;
type BorderKeysProps = {
  borderColor?: keyof Colors;
  borderWidth?: number;
};

export type BoxProps = BoxKeysProps &
  ColorKeysProps &
  RadiiKeysProps &
  ShadowsKeysProps &
  PositionProps &
  BorderKeysProps &
  ZIndexProps &
  VisibilityProps &
  OverflowProps &
  InsetProps &
  TextAlignProps &
  FlexProps &
  DOMAttributes<any> & {
    id?: string;
    className?: string;
    as?: ElementType;
    tabIndex?: number;
  };

/**
 * Box wrapper component to handle box-sizing (margin and padding), colors (background-color and color) and border-radius (radii)
 * Please see types above for available keys
 * @example
 * This box will have margin-top = normal3 on small devices and medium2 on large devices.
 * It will have a base000 background-color and border-radius = large.
 *
 * <Box mt={{ _: 'normal3', lg: 'medium2' }} bgColor="base000" radii="large">{'Content'}</Box>
 */
const BoxComponent: ForwardRefRenderFunction<HTMLDivElement, BoxProps> = (
  { children, ...rest },
  ref,
) => (
  <BoxContainer {...rest} ref={ref}>
    {children}
  </BoxContainer>
);

BoxComponent.displayName = 'Box';

const boxMixins = boxKeysArr.map((boxKey) =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  createMixin(boxKey)<BoxValue, BoxProps>((props, css) => {
    switch (boxKey) {
      case 'm':
        return isDefined(props.m)
          ? css`
              && {
                margin: ${setBoxValue(props.m)};
              }
            `
          : css``;

      case 'mt':
        return isDefined(props.mt)
          ? css`
              && {
                margin-top: ${setBoxValue(props.mt)};
              }
            `
          : css``;

      case 'mb':
        return isDefined(props.mb)
          ? css`
              && {
                margin-bottom: ${setBoxValue(props.mb)};
              }
            `
          : css``;

      case 'ml':
        return isDefined(props.ml)
          ? css`
              && {
                margin-left: ${setBoxValue(props.ml)};
              }
            `
          : css``;

      case 'mr':
        return isDefined(props.mr)
          ? css`
              && {
                margin-right: ${setBoxValue(props.mr)};
              }
            `
          : css``;

      case 'my':
        return isDefined(props.my)
          ? css`
              && {
                margin-top: ${setBoxValue(props.my)};
                margin-bottom: ${setBoxValue(props.my)};
              }
            `
          : css``;

      case 'mx':
        return isDefined(props.mx)
          ? css`
              && {
                margin-left: ${setBoxValue(props.mx)};
                margin-right: ${setBoxValue(props.mx)};
              }
            `
          : css``;

      case 'p':
        return isDefined(props.p)
          ? css`
              padding: ${setBoxValue(props.p)};
            `
          : css``;

      case 'pt':
        return isDefined(props.pt)
          ? css`
              && {
                padding-top: ${setBoxValue(props.pt)};
              }
            `
          : css``;

      case 'pb':
        return isDefined(props.pb)
          ? css`
              && {
                padding-bottom: ${setBoxValue(props.pb)};
              }
            `
          : css``;

      case 'pl':
        return isDefined(props.pl)
          ? css`
              && {
                padding-left: ${setBoxValue(props.pl)};
              }
            `
          : css``;

      case 'pr':
        return isDefined(props.pr)
          ? css`
              && {
                padding-right: ${setBoxValue(props.pr)};
              }
            `
          : css``;

      case 'py':
        return isDefined(props.py)
          ? css`
              && {
                padding-top: ${setBoxValue(props.py)};
                padding-bottom: ${setBoxValue(props.py)};
              }
            `
          : css``;

      case 'px':
        return isDefined(props.px)
          ? css`
              && {
                padding-left: ${setBoxValue(props.px)};
                padding-right: ${setBoxValue(props.px)};
              }
            `
          : css``;

      case 'h':
      case 'height': {
        const height = props.h || props.height;

        return isDefined(height)
          ? css`
              height: ${setBoxValue(height)};
            `
          : css``;
      }
      case 'minHeight':
        return isDefined(props.minHeight)
          ? css`
              min-height: ${setBoxValue(props.minHeight)};
            `
          : css``;
      case 'maxHeight':
        return isDefined(props.maxHeight)
          ? css`
              max-height: ${setBoxValue(props.maxHeight)};
            `
          : css``;

      case 'w':
      case 'width': {
        const width = props.w || props.width;

        return isDefined(width)
          ? css`
              width: ${setBoxValue(width)};
            `
          : css``;
      }
      case 'maxWidth':
        return isDefined(props.maxWidth)
          ? css`
              max-width: ${isNumber(props.maxWidth)
                ? pxToRem(props.maxWidth)
                : getSpace(props.maxWidth as keyof Space)};
            `
          : css``;

      case 'minWidth':
        return isDefined(props.minWidth)
          ? css`
              min-width: ${isNumber(props.minWidth)
                ? pxToRem(props.minWidth)
                : getSpace(props.minWidth as keyof Space)};
            `
          : css``;

      case 'top':
        return isDefined(props.top)
          ? css`
              top: ${setBoxValue(props.top)};
            `
          : css``;

      case 'bottom':
        return isDefined(props.bottom)
          ? css`
              bottom: ${setBoxValue(props.bottom)};
            `
          : css``;

      case 'left':
        return isDefined(props.left)
          ? css`
              left: ${setBoxValue(props.left)};
            `
          : css``;

      case 'right':
        return isDefined(props.right)
          ? css`
              right: ${setBoxValue(props.right)};
            `
          : css``;
      default:
        return css``;
    }
  }),
);

const colorKeysArr: CssColorKeys[] = ['backgroundColor', 'fontColor'];

const colorMixins = colorKeysArr.map((colorKey) =>
  createMixin(colorKey)<keyof Colors, BoxProps>((props, css) => {
    switch (colorKey) {
      case 'backgroundColor':
        return props.backgroundColor
          ? css`
              background-color: ${getColor(props.backgroundColor)};
            `
          : css``;

      case 'fontColor':
        return props.fontColor
          ? css`
              color: ${getColor(props.fontColor)};
            `
          : css``;

      default:
        return css``;
    }
  }),
);

const radiiMixin = createMixin('radii')<keyof Radii, BoxProps>(({ radii }, css) =>
  radii
    ? css`
        border-radius: ${getRadii(radii)};
      `
    : css``,
);

const shadowsMixin = createMixin('shadow')<keyof Shadows, BoxProps>(({ shadow }, css) =>
  shadow
    ? css`
        box-shadow: ${getShadow(shadow)};
      `
    : css``,
);

export const positionMixin = createMixin('position')<CSS.Property.Position, BoxProps>(
  ({ position }, css) =>
    position
      ? css`
          position: ${position};
        `
      : css``,
);

const borderMixin = createMixin('borderColor')<keyof Colors, BoxProps>(({ borderColor }, css) =>
  borderColor
    ? css`
        border-color: ${getColor(borderColor)};
      `
    : css``,
);

const borderSizeMixin = createMixin('borderWidth')<BoxValue, BoxProps>(({ borderWidth }, css) =>
  borderWidth
    ? css`
        border-width: ${isNumber(borderWidth) ? pxToRem(borderWidth) : borderWidth};
        border-style: solid;
      `
    : css``,
);

export const displayMixin = createMixin('display')<CSS.Property.Display, BoxProps>(
  ({ display }, css) =>
    display
      ? css`
          display: ${display};
        `
      : css``,
);

export const justifyMixin = createMixin('justifyContent')<CSS.Property.JustifyContent, BoxProps>(
  ({ justifyContent }, css) =>
    justifyContent
      ? css`
          justify-content: ${justifyContent};
        `
      : css``,
);

export const directionMixin = createMixin('flexDirection')<CSS.Property.FlexDirection, BoxProps>(
  ({ flexDirection }, css) =>
    flexDirection
      ? css`
          flex-direction: ${flexDirection};
        `
      : css``,
);

export const BoxContainer = styled.div<BoxProps>`
  ${alignContentMixin}
  ${alignItemsMixin}
  ${alignSelfMixin}
  ${boxMixins}
  ${colorMixins}
  ${borderMixin}
  ${borderSizeMixin}
  ${columnGapMixin}
  ${directionMixin}
  ${displayMixin}
  ${flexMixin}
  ${gapMixin}
  ${growMixin}
  ${insetMixin}
  ${justifyItemsMixin}
  ${justifyMixin}
  ${justifySelfMixin}
  ${overflowMixin}
  ${positionMixin}
  ${radiiMixin}
  ${rowGapMixin}
  ${shadowsMixin}
  ${textAlignMixin}
  ${visibilityMixin}
  ${wrapMixin}
  ${zIndexMixin}
`;

export const Box = forwardRef(BoxComponent);
