import { useEffect, useRef } from 'react';

import { useIntlMeta } from '@yoweb/ui/hooks/useIntlMeta';

export const importBraze = () => import('./braze-exports');

enum BrazeState {
  INITIAL,
  INITIALIZING,
  INITIALIZED,
}

export const useBraze = (enabled: boolean, apiKey?: string, baseUrl?: string, userId?: string) => {
  const { language } = useIntlMeta();
  const loaderState = useRef(BrazeState.INITIAL);

  useEffect(() => {
    if (!enabled || !apiKey || !baseUrl) {
      return;
    }

    void importBraze().then(
      ({ initialize, changeUser, automaticallyShowInAppMessages, openSession }) => {
        // Note: this guards against Braze being initialized many times.
        // Braze client is a singleton and client-side only.
        if (loaderState.current === BrazeState.INITIAL) {
          loaderState.current = BrazeState.INITIALIZING;

          const htmlEl = document.querySelector('html');
          const contentSecurityNonce = htmlEl?.dataset?.nonce ?? undefined;
          const isInitialized = initialize(apiKey, {
            allowUserSuppliedJavascript: true,
            appVersion: process.env.NEXT_PUBLIC_APP_VERSION,
            baseUrl,
            contentSecurityNonce,
            doNotLoadFontAwesome: true,
            localization: language,
          });

          if (!isInitialized) {
            loaderState.current = BrazeState.INITIAL;
            return;
          }

          loaderState.current = BrazeState.INITIALIZED;
          automaticallyShowInAppMessages();
        }

        if (loaderState.current === BrazeState.INITIALIZED) {
          // Note: changeUser can be invoked any time after initialization and many times.
          if (typeof userId === 'string') {
            changeUser(userId);
          }

          openSession();
        }
      },
    );
  }, [apiKey, baseUrl, enabled, language, userId]);
};
