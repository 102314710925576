/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export var onClickPrivacyChoices = function onClickPrivacyChoices() {
  var _window$Osano;

  if ((_window$Osano = window.Osano) !== null && _window$Osano !== void 0 && _window$Osano.cm) {
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }
};
export default onClickPrivacyChoices;