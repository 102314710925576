import { useMemo } from 'react';

import theme, { themeLocaleOverrides } from '@yoweb/ui/styles/theme';

/**
 * Used in the _app Component to build the theme object which get passed to the ThemeProvider.
 */
const useLocaleTheme = (locale?: string) =>
  useMemo(() => {
    if (!locale) return theme;

    const localeLower = locale.toLowerCase() as 'ja-jp' | 'en-us';
    if (!(localeLower in themeLocaleOverrides)) return theme;

    const overrides = themeLocaleOverrides[localeLower];
    return { ...theme, ...overrides };
  }, [locale]);

export { useLocaleTheme };
export default useLocaleTheme;
