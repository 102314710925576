import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const LinkedInIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 16 16" fill="none" {...props}>
    <path
      clipRule="evenodd"
      d="M4.5 1h7.003A3.497 3.497 0 0 1 15 4.497v7.007A3.497 3.497 0 0 1 11.503 15H4.497A3.497 3.497 0 0 1 1 11.503V4.5A3.5 3.5 0 0 1 4.5 1v0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.982 7.299v4.2M7.781 11.5V9.05c0-.967.783-1.75 1.75-1.75v0c.967 0 1.75.783 1.75 1.75v2.45M4.98 4.763a.175.175 0 1 0 .002.35.175.175 0 0 0-.001-.35"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconBase>
);

export default LinkedInIcon;
