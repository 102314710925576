import { StatusCodes } from 'http-status-codes';
import type { LinkProps } from 'next/link';

export class ApiError extends Error {
  status: number;
  statusText?: string;
  body?: unknown;

  constructor(
    message: string,
    status = StatusCodes.INTERNAL_SERVER_ERROR,
    statusText?: string,
    body?: unknown,
  ) {
    super(message);

    // Ensures that the callstack is maintained from where it was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }
}

/**
 * Clamp a value between two values.
 * @return min <= x <= max
 */
export const clamp = (min: number, max: number, x: number): number =>
  Math.max(min, Math.min(x, max));

export const runMocks = process.env.NEXT_PUBLIC_API_MOCKS === 'true';

export const isLinkInternal = (url: LinkProps['href'], baseUrl?: string): boolean => {
  if (typeof url !== 'string') {
    return true;
  }

  return Boolean(
    url &&
      !(
        (baseUrl && !url.includes(baseUrl) && url.startsWith('http')) ||
        url.startsWith('tel') ||
        url.startsWith('mailto')
      ),
  );
};

export const shouldLinkOpenInNewTab = (href: string): boolean =>
  Boolean(href && !href.startsWith('tel'));

export const fetcher = async <T>(url: string, nocache = false): Promise<T> => {
  const res = await fetch(
    url,
    nocache
      ? {
          headers: {
            'Cache-Control': 'no-cache',
          },
        }
      : undefined,
  );

  if (!res.ok) {
    const error = new Error(
      `An error occurred while fetching data. Status: ${res.status} URL: "${res.url}"`,
    );

    // attach extra info to the error object.
    try {
      error.info = await res.json();
    } catch (e) {
      // TODO: handle error
    }

    error.status = res.status;

    throw error;
  }

  return res.json();
};
