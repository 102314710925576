import { useContext } from 'react';

import { getLanguage } from '@yoweb/utils/intl/locale';
import { isLocalePostalCodeDominant, getCountryForLocale } from '@yoweb/utils/intl/address';
import { localeHasFullWidthChars } from '@yoweb/utils/intl/charWidth';
import { IntlMetaContext, type IntlMetaContextValue } from '@yoweb/next-i18next/IntlProvider';
import { useTranslation } from '@yoweb/next-i18next';

export type UseIntlMetaValue = IntlMetaContextValue & {
  locale: string;
  lcLocale: string;
  language: string;
  country: SupportedCountry;
  localeHasFullWidthChars: boolean;
  localeIsPostalCodeDominant: boolean;
};

export function useIntlMeta(): UseIntlMetaValue {
  const meta = useContext(IntlMetaContext);
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const lcLocale = locale.toLowerCase();

  if (!meta) {
    throw new Error('IntlMetaContext.Provider not initialized with an initial value.');
  }

  return {
    ...meta,
    locale,
    lcLocale,
    language: getLanguage(lcLocale),
    country: getCountryForLocale(lcLocale),
    localeHasFullWidthChars: localeHasFullWidthChars(lcLocale),
    localeIsPostalCodeDominant: isLocalePostalCodeDominant(lcLocale),
  };
}
