import _defineProperty from "/home/runner/work/web/web/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { isString } from '@yoweb/utils/isString';
import { getQueryParam } from '@yoweb/utils/queryParam';
export var DEFAULT_STICKY_COOKIE_MAX_AGE = 24 * 60 * 60; // 24 hours

export var defaultStickyQueryParamConfiguration = [{
  key: 'referralCode',
  maxAge: 60 * 60 * 24 * 7
}, // one week
{
  key: 'utm_params',
  paramKeys: ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term', 'utm_content']
}];
export var createCookieOptions = function createCookieOptions(config) {
  var _config$maxAge;

  return {
    maxAge: (_config$maxAge = config === null || config === void 0 ? void 0 : config.maxAge) !== null && _config$maxAge !== void 0 ? _config$maxAge : DEFAULT_STICKY_COOKIE_MAX_AGE,
    path: '/',
    sameSite: 'lax',
    secure: true
  };
}; // Loops over query param keys and returns the values either as string or object

export var collectQueryParameters = function collectQueryParameters(params, query) {
  if (isString(params)) {
    return getQueryParam(params, query);
  }

  return params.reduce(function (collected, param) {
    var value = getQueryParam(param, query);

    if (collected) {
      return value ? _objectSpread(_objectSpread({}, collected), {}, _defineProperty({}, param, value)) : collected;
    }

    return value ? _defineProperty({}, param, value) : collected;
  }, null);
};