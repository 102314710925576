import { Element, type JSXFunctionSerializer } from '@prismicio/react';
import styled from 'styled-components';

import {
  getSpace,
  getFontSize,
  getColor,
  getFontWeight,
  getLineHeight,
} from '@yoweb/ui/styles/utils/theme';
import { Link } from '@yoweb/ui/components/Link';
import { Text } from '@yoweb/ui/components/typography';

const Ul = styled.ul<{ $isEmbedded?: boolean }>`
  margin-left: ${getSpace('normal4')};
  list-style: disc;
  margin-bottom: ${getSpace('normal1')};
  color: ${({ $isEmbedded }) => ($isEmbedded ? getColor('base900') : getColor('base700'))};

  li {
    margin-top: ${getSpace('normal1')};
    font-size: ${({ $isEmbedded }) =>
      $isEmbedded ? getFontSize('bodyLg') : getFontSize('bodyXl')};
    line-height: ${getLineHeight('bodySm')};
  }
`;

const Ol = styled.ol<{ $isEmbedded?: boolean }>`
  margin-left: ${getSpace('normal4')};
  list-style: numeric;
  color: ${({ $isEmbedded }) => ($isEmbedded ? getColor('base900') : getColor('base700'))};
  margin-bottom: ${getSpace('normal1')};

  li {
    margin-top: ${getSpace('normal1')};
    font-size: ${({ $isEmbedded }) =>
      $isEmbedded ? getFontSize('bodyLg') : getFontSize('bodyXl')};
  }
`;

const Em = styled.em`
  font-style: italic;
`;
const Strong = styled.strong`
  font-weight: ${getFontWeight('bold')};
`;

/**
 * Determine how RichText from Prismic gets rendered.
 * Used on the FAQ pages specifically.
 */
export const htmlSerializerFaqPage = (internalURLs: string[]): JSXFunctionSerializer =>
  function jsxSerializer(_type, node, _text, children, _key) {
    switch (node.type) {
      case Element.paragraph:
        return (
          <Text variant="muted" size="xl" key={_key}>
            {node.text.length ? children : <span>&nbsp;</span>}
          </Text>
        );
      case Element.hyperlink: {
        // Open FAQ links in same tab, all other links in new tab
        const url = node?.data?.url as string;
        const shouldOpenSameTab = Boolean(
          url && internalURLs.find((urlStr) => url?.includes(urlStr)),
        );
        return node?.data?.url ? (
          <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab={!shouldOpenSameTab}>
            {children}
          </Link>
        ) : null;
      }
      case Element.list:
        return <Ul key={_key}>{children}</Ul>;
      case Element.oList:
        return <Ol key={_key}>{children}</Ol>;
      case Element.em:
        return <Em key={_key}>{children}</Em>;
      case Element.strong:
        return <Strong key={_key}>{children}</Strong>;
      // Return null to stick with the default behavior for all other elements
      default:
        return null;
    }
  };

/**
 * Determine how RichText from Prismic gets rendered.
 * Used for short FAQ sections throughout the website.
 */
export const htmlSerializerEmbed: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Text size="lg" key={_key}>
          {children.length ? children : <span>&nbsp;</span>}
        </Text>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline>
          {children}
        </Link>
      ) : null;
    case Element.list:
      return (
        <Ul key={_key} $isEmbedded>
          {children}
        </Ul>
      );
    case Element.oList:
      return (
        <Ol key={_key} $isEmbedded>
          {children}
        </Ol>
      );
    case Element.em:
      return <Em key={_key}>{children}</Em>;
    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;
    // Return null to stick with the default behavior for all other elements
    default:
      return null;
  }
};
