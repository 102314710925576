import styled, { type Colors, css, useTheme } from 'styled-components';
import type { ReactNode, MouseEvent } from 'react';

import {
  buttonBase,
  buttonVariants,
  type ButtonBaseProps,
  type ButtonVariantProps,
} from './Button';
import { getSpace, getRadii, getShadow, th, getDuration } from '@yoweb/ui/styles/utils/theme';
import { pxToRem } from '@yoweb/ui/styles/utils';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';

export type ButtonIconVariant = 'primary' | 'tertiary';
export type ButtonIconSize = 'sm' | 'md' | 'lg';
export type ButtonIconMode = 'dark' | 'light';

type Props = ButtonBaseProps &
  ButtonVariantProps & {
    className?: string;
    icon: ReactNode;
    isFloating?: boolean;
    bgColor?: keyof Colors;
  } & WithSize<ButtonIconSize>;

export type ButtonIconProps = DataTestId & Props;

export const ButtonIcon = ({
  className,
  icon,
  isDisabled = false,
  isFloating = true,
  isLoading = false,
  mode = 'light',
  onClick,
  size = 'lg',
  variant = 'primary',
  ...props
}: ButtonIconProps) => {
  const theme = useTheme();

  const internalIconSize = size === 'sm' ? theme.iconSizes['compact'] : theme.iconSizes['default'];

  const handleClick = (e: MouseEvent) => {
    const isClickable = !isDisabled && !isLoading;

    if (typeof onClick === 'function' && isClickable) {
      onClick(e);
    }
  };

  return (
    // @ts-ignore TODO improve typing
    <StyledButton
      className={className}
      disabled={isDisabled || isLoading}
      isFloating={isFloating}
      mode={mode}
      onClick={handleClick}
      size={size}
      variant={variant}
      {...props}
    >
      <IconSizer size={internalIconSize}>{icon}</IconSizer>
    </StyledButton>
  );
};

const IconSizer = styled.div<{ size: string }>`
  place-content: center center;
  display: grid;
  font-size: ${({ size }) => size};
`;

const buttonIconSizeMixin = createSizeMixin<ButtonIconSize, ButtonIconProps>(
  ({ size = 'md' }, tagFn) => {
    switch (size) {
      case 'lg':
        return tagFn`
          height: ${getSpace('medium1')};
          width: ${getSpace('medium1')};
      `;
      case 'md':
        return tagFn`
          height: ${getSpace('normal4')};
          width: ${getSpace('normal4')};
      `;
      case 'sm':
        return tagFn`
          height: ${getSpace('normal3')};
          width: ${getSpace('normal3')};
      `;
    }
  },
);

const StyledButton = styled.button<ButtonIconProps>`
  ${buttonBase};
  ${buttonVariants};
  ${buttonIconSizeMixin};

  position: relative;
  border-radius: ${getRadii('full')};
  transition: background-color ${getDuration('interaction')}ms ease-out;
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${th.getColor(bgColor)};

      :hover {
        background-color: ${th.getColor(bgColor)};
      }
    `};

  ${({ isFloating }) =>
    isFloating &&
    css`
      box-shadow: ${getShadow('secondary')};

      :disabled {
        box-shadow: none;
      }
    `}

  ${({ size }) =>
    size === 'sm' &&
    css`
      &::after {
        content: '';
        inset: -${pxToRem(6)} -${pxToRem(6)} -${pxToRem(6)} -${pxToRem(6)};
        position: absolute;
      }
    `}
`;
