import { createContext, type ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';

import clients from './clients';
import type { I18nextPageResponse } from './types';

export type IntlProviderProps = {
  children?: ReactNode;
  timeZone: string;
  value: I18nextPageResponse['i18nextPageResponse']['config'];
};

export type IntlMetaContextValue = {
  timeZone: string;
};

export const IntlMetaContext = createContext<IntlMetaContextValue | undefined>(undefined);

export function IntlProvider({ children, value: config, timeZone }: IntlProviderProps) {
  const { i18n } = clients(config);

  if (!i18n || !config) {
    throw new Error('i18n client unable to be created or restored');
  }

  return (
    <IntlMetaContext.Provider value={{ timeZone }}>
      <I18nextProvider i18n={i18n} key={i18n.language}>
        {children}
      </I18nextProvider>
    </IntlMetaContext.Provider>
  );
}
