import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const CaretUpIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.989 6.75a.75.75 0 0 1 .53.22l9.011 9a.75.75 0 0 1-1.06 1.06l-8.48-8.47-8.46 8.47a.75.75 0 1 1-1.06-1.06l8.988-9a.75.75 0 0 1 .53-.22Z"
      fill="currentColor"
    />
  </IconBase>
);

export default CaretUpIcon;
