import { isServerRendered } from '@yoweb/utils/isServerRendered';

type ScrollPosition = {
  y: number;
  direction: 'up' | 'down' | 'static';
  directionChanged: boolean;
};

type ScrollState = {
  current: ScrollPosition;
  previous: ScrollPosition;
};

export type ScrollHandler = (state: ScrollState) => void;

class Scroll {
  private handlers: ScrollHandler[];
  private scrollY: ScrollPosition = {
    y: 0,
    direction: 'static',
    directionChanged: false,
  };

  constructor() {
    this.handlers = [];

    if (isServerRendered) {
      return;
    }

    this.render = this.render.bind(this);
  }

  private render() {
    const y = window.scrollY;
    const direction = y > this.scrollY.y ? 'down' : 'up';
    const directionChanged = direction !== this.scrollY.direction;

    const current: ScrollPosition = {
      y,
      direction,
      directionChanged,
    };

    const state: ScrollState = {
      current,
      previous: this.scrollY,
    };

    for (let index = 0; index < this.handlers.length; index++) {
      this.handlers[index](state);
    }

    this.scrollY = current;
  }

  init() {
    if (isServerRendered) {
      return;
    }

    this.render();
    window.addEventListener('scroll', this.render, { passive: true });

    return this.deInit;
  }

  deInit(): void {
    if (isServerRendered) {
      return;
    }

    window.removeEventListener('scroll', this.render);
  }

  addScrollHandler(handler: ScrollHandler) {
    if (isServerRendered) {
      return;
    }

    this.handlers.push(handler);

    return () => this.removeScrollHandler(handler);
  }

  removeScrollHandler(handlerToRemove: ScrollHandler): void {
    if (isServerRendered) {
      return;
    }

    this.handlers = this.handlers.filter((handler) => handler !== handlerToRemove);
  }

  toTop(offset = 0) {
    if (isServerRendered) {
      return;
    }

    window.scrollTo({ top: offset, behavior: 'smooth' });
  }
}

export default new Scroll();
